import { render, staticRenderFns } from "./LiteracyConfigScore.vue?vue&type=template&id=249a1813&scoped=true"
import script from "./LiteracyConfigScore.vue?vue&type=script&lang=js"
export * from "./LiteracyConfigScore.vue?vue&type=script&lang=js"
import style0 from "./LiteracyConfigScore.vue?vue&type=style&index=0&id=249a1813&prod&scoped=scoped&lang=less"
import style1 from "./LiteracyConfigScore.vue?vue&type=style&index=1&id=249a1813&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "249a1813",
  null
  
)

export default component.exports