<!--
  学生管理-请假信息
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          <ul class="nav nav-pills navTabsCont">
            <li class="nav-item" v-for="(p,index) in titles" :key="index" role="button">
              <span  @click="onChangeTab(p.type)" :class="p.type== currentType?'nav-link active':'nav-link'">
                {{p.name}}
              </span>
            </li>
          </ul>
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
<!--          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学期</span>
            </div>
            <div class="selBodyBox ">
              <el-select v-model="searchForm.semesterId" placeholder="请选择学期" filterable class="width_100Pie">
                <el-option v-for="(item, index) in searchForm.optSemester" :key="index" :label="item.name"
                  :value="item.semesterId">
                </el-option>
              </el-select>
            </div>
          </el-col> -->
          <el-col :span="6" class="mb-3">
              <div class="selTitleBox">
                <span class="spTitle">请假时间</span>
              </div>
              <div class="selBodyBox">
                <el-date-picker v-model="searchForm.StartDate" type="date"   class="width_100Pie"  value-format="yyyy-MM-dd">
                </el-date-picker>
              </div>
            </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">请假类型</span>
            </div>
            <div class="selBodyBox ">
              <el-select v-model="searchForm.typeId" placeholder="请选择请假类型" filterable class="width_100Pie" clearable>
                <el-option v-for="(item, index) in searchForm.optType" :key="index" :label="item.Text" :value="item.Value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">年级</span>
            </div>
            <div class="selBodyBox ">
              <el-select v-model="searchForm.collegeId" placeholder="请选择年级" filterable class="width_100Pie" @change="onChangeCollege" clearable>
                <el-option v-for="(item, index) in tpGrade" :key="index" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">班级</span>
            </div>
            <div class="selBodyBox ">
              <el-select v-model="searchForm.classId" placeholder="请选择班级" filterable class="width_100Pie" clearable>
                <el-option v-for="(item, index) in searchForm.optClass" :key="index" :label="item.alias" :value="item.classId">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6" class="mb-3">
            <div class="selTitleBox">
              <span class="spTitle">学生</span>
            </div>
            <div class="selBodyBox">
              <el-input v-model="searchForm.student" placeholder="请输入学生姓名/学号"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="card-footer text-center">
        <el-button type="primary" class="mr-2" @click="onSearch()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-search"></use>
          </svg>
          查询
        </el-button>
        <el-button class="ml-2" @click="onReset()">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#fax-redo-alt"></use>
          </svg>
          重置
        </el-button>
      </div>
    </el-card>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title">
          请假信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item mr-2" v-if="isPower.leavemgt_class_audit || isPower.leavemgt_audit || isPower.leavemgt_studentoffice_audit">
              <el-button @click="onBatchAudit()" style="background-color:#3c8dbc;color: white;">批量审核</el-button>
            </li>
            <li class="nav-item" v-if="isPower.leavemgt_class_create">
              <el-button type="success" icon="el-icon-circle-plus-outline" @click="onCreate()">代学生请假</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemData != null && itemData.length != 0">
        <el-table ref="multipleTable" :data="itemData" tooltip-effect="dark" style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" :selectable="selectable"></el-table-column>
          <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
          <!-- <el-table-column prop="college" label="年级" min-width="140" :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column prop="className" label="班级" min-width="140" :show-overflow-tooltip="true"></el-table-column>
          <!-- <el-table-column prop="studentNo" label="学号" min-width="120" :show-overflow-tooltip="true"></el-table-column> -->
          <el-table-column prop="studentName" label="姓名" min-width="120" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="categoryName" label="请假分类" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="typeName" label="请假类型" min-width="100" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="days" label="请假时长(天)" min-width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="请假时间" min-width="320">
            <template slot-scope="scope">
              <span v-if="scope.row.category == 2 || scope.row.category == 3 || scope.row.category == 5">
                  {{ scope.row.startDate | dateformatDay }}
                  至
                  {{ scope.row.endDate | dateformatDay }}
              </span>
              <span v-else>
                {{ scope.row.startDate | dateformatMinute }}
                至
                {{ scope.row.endDate | dateformatMinute }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="出校时间" min-width="320">
            <template slot-scope="scope">
              <span v-if='!regNull(scope.row.outDate)'>
                  {{ scope.row.outDate | dateformatMinute }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="进校时间" min-width="320">
            <template slot-scope="scope">
              <span v-if='!regNull(scope.row.inDate)'>
                  {{ scope.row.inDate | dateformatMinute }}
              </span>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column label="请假状态" min-width="140" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span class="color-warning" v-if="scope.row.status == 1 || scope.row.status == 4">{{ scope.row.statusName
              }}</span>
              <span class="color-danger" v-else-if="scope.row.status == -3 || scope.row.status == -2">{{
                scope.row.statusName }}</span>
              <span v-else>{{ scope.row.statusName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="100" fixed="right">
            <template slot-scope="scope">
              <operationBtn :btnName="'审核'" v-if="onGetStatus(scope.row)" @click.native="onDetail(scope.row.leaveId)">
              </operationBtn>
              <operationBtn :btnName="'查看'" v-else-if="isPower.leavemgt_default"
                @click.native="onDetail(scope.row.leaveId)"></operationBtn>
            </template>
          </el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <el-dialog title="批量审核" :visible.sync="dialogVisible" width="30%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="审核原因" prop="reason">
          <el-input type="textarea" :rows="4" placeholder="请输入审核原因" v-model="ruleForm.reason"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" icon="el-icon-circle-close" @click="onAudit(false)">审核不通过
        </el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onAudit(true)">审核通过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '@/assets/css/Admin.css';
import comPage from '@/components/Page.vue';
import operationBtn from '@/components/operationBtn.vue';
// import search from '@/components/search.vue';

export default {
  name: '',
  components: {
    comPage,
    operationBtn,
    // search
  },
  data() {
    return {
      fullscreenLoading: false,
      dialogVisible: false, //批量审核弹窗
      multipleSelection: [], //列表选中数据
      random: 0, //刷新组件
      page: null, //分页查询接口返回的结果
      itemData: [], //列表数据
      pageBtnList: null, //分页左侧按钮集合
      paging: { //分页数据
        pageLength: 0, // 总共页数
        Size: 10, // 当前请求数量
        Index: 1 //当前在第几页
      },
      SearchList: null, //搜索集合
      isPower: {
        leavemgt_audit: false,
        leavemgt_class_audit: false,
        leavemgt_default: false,
        leavemgt_class_create: false,
        leavemgt_studentoffice_audit: false,
      },
      titles:[],//tabs
      currentType:1,
      tpGrade:[],
      searchForm: {
        // 学期
        optSemester: [],
        semesterId: null,
        // 请假类型
        optType: [],
        typeId: null,
        // 学生
        student: null,
        //年级
        collegeId:null,
        StartDate:dateFormat(new Date(), '-', 'YYYY-MM-DD'),
        // 班级
        classId: null,
        optClass: [],
        // 请假时间
        dateRange: [],

      },
      allClass:[],//所有班级
      url: null, //当前页面的路径
      // 判断是否为班主任
      isTeacher: false,
      myInfo: null,
      ruleForm: {
        reason: null,
      },
      isShowErr: null,
      rules: {
        reason: [{
          validator: (rule, value, callback) => {
            let that = this;
            if (that.isShowErr === false) {
              if (!value) {
                callback(new Error("请输入审核失败原因"));
              } else {
                callback();
              }
            } else {
              callback();
            }
          },
          trigger: 'change'
        }]
      },
      // 教师信息
      TeacherInfo: {},
    };
  },
  created() {
    let that = this;
    isPower(that, 'leavemgt_audit', 'leavemgt_audit');
    isPower(that, 'leavemgt_class_audit', 'leavemgt_class_audit');
    isPower(that, 'leavemgt_default', 'leavemgt_default');
    isPower(that, 'leavemgt_class_create', 'leavemgt_class_create');
    isPower(that, 'leavemgt_studentoffice_audit', 'leavemgt_studentoffice_audit');
    that.myInfo = onGetNowUser(that);

    that.titles = [{
          name:'普通请假',
          type:1,
        },{
          name:'课间操请假',
          type:2,
        },{
          name:'午休请假',
          type:3,
        },{
          name:'进寝室请假',
          type:4,
        },{
          name:'临时通校请假',
          type:5,
        }];
    // 学期
    ajaxGet(that, "/api/admin/Semester", null, function (resSemester) {
      that.searchForm.optSemester = resSemester;
      if (!regNullArray(resSemester)) {
        let tpSemester = resSemester.find(item => item.isCurrent == true);
        if (regNull(that.searchForm.semesterId)) {
          that.searchForm.semesterId = regNull(tpSemester) ? resSemester[0].semesterId : tpSemester.semesterId;
        }
      }
      // 请假类型
      ajaxGet(that, '/api/admin/askforleave/type', null, function (resType) {
        that.searchForm.optType = resType;
          ajaxGet(that, '/api/admin/sysuser/userfilter', null, function (resClass) {
            that.searchForm.optClass = resClass;
            that.allClass = resClass;
            // 年级
            that.tpGrade = that.onArrUnique(resClass.map(item => ({
              value: item.collegeId,
              label: item.college,
            })));
            if (that.myInfo.isTeacher) {
              // 班主任要有默认班级
              that.searchForm.classId = regNull(that.searchForm.classId) ? that.myInfo.manageClass[0].classId : that.searchForm.classId;
            }
            that.searchFormReset = JSON.parse(JSON.stringify(that.searchForm));
            pagingPage(that);
          });
      });
    });
  },
  methods: {
    onChangeTab(e){
      let that = this;
      that.currentType = e;
      that.paging.Index = 1;
      pagingPage(that);
    },
    /**
     * @description 重置
     */
    onReset() {
      let that = this;
      that.searchForm = JSON.parse(JSON.stringify(that.searchFormReset));
      pagingPage(that);
    },
    onChangeCollege(){
      let that = this;
      that.searchForm.optClass = [];
      if(regNull(that.searchForm.collegeId)){
        that.searchForm.optClass = that.allClass;
      }else{
        for(var it of that.allClass){
          if(it.collegeId == that.searchForm.collegeId){
            that.searchForm.optClass.push(it);
          }
        }
      }
    },
    //去除数组内的重复
    onArrUnique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.value) && res.set(arr.value, 1));
    },
    selectable(row) {
      let that = this;
      // 班主任审核权限
      if (row.status == 1) {
        if (that.isPower.leavemgt_class_audit) {
          return true;
        } else {
          return false;
        }
      }
      // 系部审核权限
      if (row.status == 4) {
        if (that.isPower.leavemgt_audit) {
          return true;
        } else {
          return false;
        }
      }
      // 学生处审核权限
      if (row.status == 16) {
        if (that.isPower.leavemgt_studentoffice_audit) {
          return true;
        } else {
          return false;
        }
      }
    },
    onAudit(val) {
      let that = this;
      let data = [];
      that.isShowErr = val;
      setTimeout(() => {
        that.isShowErr = null;
      }, 100);
      that.multipleSelection.forEach(ele => {
        var status = null;
        // 待审核，需要班主任审核
        if (ele.status == 1) {
          if (val) { // 审核通过
            status = 2;
          } else { // 审核不通过
            status = -2;
          }
        }
        // 待审核，需要年级主任
        else if (ele.status == 4) {
          if (val) { // 审核通过
            status = 8;
          } else { // 审核不通过
            status = -3;
          }
          //分管校长
        } else if (ele.status == 16) {
          // 判断
          if (val) {
            status = 32;
          } else {
            // 审核不通过
            status = -4;
          }
        }
        data.push({
          leaveId: ele.leaveId,
          status: status,
          reason: that.ruleForm.reason
        })
      });
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          ajaxPut(that, "/api/admin/askforleave/batchapprove", data, (res) => {
            pagingPage(that);
            that.dialogVisible = false;
          })
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            var el = isError[0].querySelector('input') || isError[0].querySelector('textarea');
            if (el) {
              el.focus();
            }
          }, 100)
          return false;
        }
      });

    },
    // 批量审核事件
    onBatchAudit() {
      let that = this;
      if (that.multipleSelection.length == 0) {
        that.$message({
          message: '请选择要审核的数据',
          type: 'warning'
        });
        return;
      }
      that.ruleForm.reason = null;
      that.dialogVisible = true;
      that.$nextTick(() => {
        that.$refs.ruleForm.clearValidate();
      });
    },
    handleSelectionChange(val) {
      let that = this;
      if (val && val.length > 0) {
        that.multipleSelection = val.map(item => {
          return {
            leaveId: item.leaveId,
            status: item.status
          }
        });
      } else {
        that.multipleSelection = [];
      }
    },
    /**
     * @description 班主任代理学生请假
     */
    onCreate() {
      let that = this;
      that.$router.push({
        path: "/Admin/LeaveMGT/Create",
        query: {
          url: that.$router.history.current.fullPath,
          category:that.currentType,
        }
      });
    },
    /**
     * @description 根据角色状态显示
     * @param {Object} objItem
     */
    onGetStatus(objItem) {
      let that = this;
      // 当状态为班主任审核时
      if (objItem.status == 1 && that.isPower.leavemgt_class_audit) {
        if (!regNullArray(that.myInfo.manageClass)) {
          return !regNull(that.myInfo.manageClass.find(item => item.classId == objItem.classId));
        }
        return false;
      }
      // 当状态为系部审核时
      else if (objItem.status == 4 && that.isPower.leavemgt_audit) {
        return true;
      }
      // 当状态为学生处审核时
      else if (objItem.status == 16 && that.isPower.leavemgt_studentoffice_audit) {
        return true;
      }
      return false;
    },
    /**
     * @description 列表序号索引
     * @param {Object} index
     */
    indexMethod(index) {
      return (this.paging.Index - 1) * this.paging.Size + index + 1;
    },
    onDetail(Id) {
      let that = this;
      that.$router.push({
        path: "/Admin/LeaveMGT/Default/" + Id,
        query: {
          url: that.$router.history.current.fullPath
        }
      });
    },
    pageBtnClick(index) {
      //分页组件左侧的按钮事件，按顺序进行判断
    },
    /**
     * @description 搜索
     */
    onSearch() {
      let that = this;
      that.paging.Index = 1;
      pagingPage(that);
    },
    pagingClick(type, val) {
      let that = this;
      if (type == 1) {
        //更改每页条数触发的事件
        that.paging.Size = val;
        that.paging.Index = 1;
      } else {
        //更改当前页时触发的事件
        that.paging.Index = val;
      }
      pagingPage(that);
    },

  },
  watch: {
    $route() {
      let that = this;
      pagingPage(that);
    }
  }
};
function pagingPage(that) {



  that.fullscreenLoading = true;
  let start = new Date(that.searchForm.StartDate).setHours(0, 0, 0, 0);
  let end = new Date(that.searchForm.StartDate).setHours(23, 59, 59, 999);
  let tpParams = {
    PageNumer: that.paging.Index,
    PageSize: that.paging.Size,
    // SemesterId: that.searchForm.semesterId,
    CollegeId:that.searchForm.collegeId,
    ClassId: that.searchForm.classId,
    Student: that.searchForm.student,
    Type: that.searchForm.typeId,
		StartDate:timeTransform(start),
		endDate : timeTransform(end),
    category:that.currentType,
  };

  ajaxGet(that,'/api/admin/askforleave/search' , tpParams, function (resData) {
    that.fullscreenLoading = false;
    that.page = resData;
    that.itemData = resData.items;
  });

}
</script>

<style scoped="scoped">
  .selTitleBox {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .selTitleBox .spTitle {
    color: #303133;
  }
  .navTabsCont .nav-link {
    font-size: 12px;
  }

</style>
