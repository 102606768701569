<template>
  <el-skeleton class="SubWebPage" :loading="fullscreenLoading" animated variant="p">

    <div v-if="isWebShow">
      <!-- 班主任 -->
      <div v-if="isClassTeacher">
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              快速查询学生信息
            </h3>
          </div>
          <div class="card-body pt-5 pb-5 pl-5 pr-5"
            v-if="isPower.students_index || isPower.punishs_index || isPower.moralitylog_index || isPower.selfreport_index || isPower.growtharchives_index || isPower.growth_index || isPower.leavemgt_class_create || isPower.academicrecord_create || isPower.academicrecord_import">
            <el-autocomplete class="input-with-select width_100Pie" v-model="selStudent"
              :fetch-suggestions="querySearchStudent" placeholder="请输入学生姓名" :trigger-on-focus="false"
              @select="onSearchStudent" value-key="value" @keyup.enter.native="onEnterSearchStudent"
              v-if="isPower.students_index || isPower.punishs_index || isPower.moralitylog_index || isPower.selfreport_index || isPower.growtharchives_index || isPower.growth_index">
              <el-select v-model="selStudentType" slot="prepend" placeholder="请选择" style="width: 120px;">
                <el-option label="基本信息" value="1" v-if="isPower.students_index"></el-option>
                <el-option label="处分信息" value="2" v-if="isPower.punishs_index"></el-option>
                <el-option label="行为规范" value="3" v-if="isPower.moralitylog_index"></el-option>
                <el-option label="获奖上报" value="4" v-if="isPower.selfreport_index"></el-option>
                <el-option label="学生档案" value="5" v-if="isPower.growtharchives_index"></el-option>
                <el-option label="综合评价" value="6" v-if="isPower.growth_index"></el-option>
              </el-select>
            </el-autocomplete>
            <el-row :gutter="20" style="margin-top: 20px;">
              <!-- 代学生请假 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12" v-if="isPower.leavemgt_class_create">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">代学生请假</h3>
                  </div>
                  <div class="card-body p-0" @click="onCreateLeave">
                    <div class="bodyIconBox VerticalMid">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-import-box-alt"></use>
                      </svg>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <!-- 录入学生成绩 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12" v-if="isPower.academicrecord_create">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">录入学生成绩</h3>
                  </div>
                  <div class="card-body p-0" @click="onCreateAcademicrecord">
                    <div class="bodyIconBox VerticalMid">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-import-box-alt"></use>
                      </svg>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <!-- 导入学生成绩 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12" v-if="isPower.academicrecord_import">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">导入学生成绩</h3>
                  </div>
                  <div class="card-body p-0" @click="onOpenDialog">
                    <div class="bodyIconBox VerticalMid">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-import-box-alt"></use>
                      </svg>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <!-- 导入学生信息 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12" v-if="isPower.prize_import">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">导入学生奖励</h3>
                  </div>
                  <div class="card-body p-0 importBox">
                    <div class="bodyIconBox VerticalMid">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-import-box-alt"></use>
                      </svg>
                    </div>
                  </div>
                  <comImport @onImportSuccess="onImportSuccess" :title="'奖励信息导入'" :template="'/Templates/奖励导入模版.xlsx'"
                    :path="'/api/admin/prize/import'" ref="conImportFile" :btnIcon="null" :btnName="null"
                    :btnClass="'boxBtnImport'">
                  </comImport>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-empty :image-size="200" description="暂无权限" name="3"></el-empty>
          </div>
        </el-card>

        <!-- 待办事项 -->
        <el-card class="box-card" v-if="(isPower.selfreport_audit || isPower.leavemgt_class_audit)">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              待办事项
            </h3>
          </div>
          <div class="card-body">
            <el-tabs v-model="activeName" type="card">
              <el-tab-pane label="获奖上报" name="first" v-if="isPower.selfreport_audit">
                <el-table :data="itemDataSelfReport" stripe class="elTableData " border>
                  <el-table-column type="index" label="序号" width="80"></el-table-column>
                  <el-table-column prop="title" label="获奖名称" min-width="200"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="student" label="对象" min-width="300"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="date" label="时间" width="200" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                      <operationBtn :btnName="'审核'" @click.native="onAudit(scope.row)"></operationBtn>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="学生请假" name="second" v-if="isPower.leavemgt_class_audit">
                <el-table :data="itemDataAskforleave" stripe class="elTableData " border>
                  <el-table-column type="index" label="序号" width="80"></el-table-column>
                  <el-table-column prop="title" label="获奖名称" min-width="100"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="student" label="对象" min-width="300"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="days" label="时长" width="100" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="startDate" label="开始时间" width="120"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="endDate" label="结束时间" width="120"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                      <operationBtn :btnName="'审核'" @click.native="onAudit(scope.row)"></operationBtn>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="学生奖励" name="three" v-if="isPower.prize_teacher_audit">
                <el-table :data="itemDataPrize" stripe class="elTableData " border>
                  <el-table-column type="index" label="序号" width="80"></el-table-column>
                  <el-table-column prop="title" label="事项名称" min-width="100"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="student" label="对象" min-width="300"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="date" label="时间" width="200" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                      <operationBtn :btnName="'审核'" @click.native="onAudit(scope.row)"></operationBtn>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
      </div>
      <!-- 管理员 -->
      <div v-else>
        <el-card class="box-card">
          <div slot="header" class="card-header">
            <h3 class="card-title titleCont">
              <span class="spIcon"></span>
              工作台
            </h3>
          </div>
          <div class="card-body">
            <el-row :gutter="20"
              v-if="isPower.students_index || isPower.punishs_index || isPower.moralitylogs || isPower.selfreport_index || isPower.growtharchives_index || isPower.growth_index || isPower.score_adviser || isPower.class_prize || isPower.prize_index || isPower.admin_import_student || isPower.admin_import_student || isPower.academicrecord_create || isPower.academicrecord_import || isPower.punish_import || isPower.prize_import">
              <!-- 快速查询学生信息 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12"
                v-if="isPower.students_index || isPower.punishs_index || isPower.moralitylogs || isPower.selfreport_index || isPower.growtharchives_index || isPower.growth_index">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header ">
                    <h3 class="card-title width_100Pie text-center">快速查询学生</h3>
                  </div>
                  <div class="card-body p-0" @click="onOpenBox('student')">
                    <div class="bodyIconBox VerticalMid">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-search-square-o"></use>
                      </svg>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <!-- 快速查询班级信息 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12"
                v-if="isPower.students_index || isPower.score_adviser || isPower.punishs_index || isPower.moralitylogs || isPower.class_prize || isPower.prize_index">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">快速查询班级</h3>
                  </div>
                  <div class="card-body p-0" @click="onOpenBox('class')">
                    <div class="bodyIconBox VerticalMid">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-user-crowd-alt"></use>
                      </svg>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <!-- 录入学生成绩 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12" v-if="isPower.academicrecord_create">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">录入学生成绩</h3>
                  </div>
                  <div class="card-body p-0" @click="onCreateAcademicrecord">
                    <div class="bodyIconBox VerticalMid">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-import-box-alt"></use>
                      </svg>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <!-- 导入学生信息 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12" v-if="isPower.admin_import_student">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">导入学生信息</h3>
                  </div>
                  <div class="card-body p-0 importBox">
                    <div class="bodyIconBox VerticalMid">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-import-box-alt"></use>
                      </svg>
                    </div>
                  </div>
                  <comImport @onImportSuccess="onImportSuccess" :template="'/templates/学生信息导入模版.xlsx'"
                    :path="'/api/admin/student/import'" :title="'批量导入学生信息'" :btnIcon="null" :btnName="null"
                    ref="conImportStudent" :btnClass="'boxBtnImport'">
                  </comImport>
                </el-card>
              </el-col>
              <!-- 导入学生成绩 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12" v-if="isPower.academicrecord_import">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">导入学生成绩</h3>
                  </div>
                  <div class="card-body p-0" @click="onOpenDialog">
                    <div class="bodyIconBox VerticalMid">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-import-box-alt"></use>
                      </svg>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <!-- 导入处分信息 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12" v-if="isPower.punish_import">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">导入处分信息</h3>
                  </div>
                  <div class="card-body p-0 importBox">
                    <div class="bodyIconBox VerticalMid ">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-import-box-alt"></use>
                      </svg>
                    </div>
                  </div>
                  <comImport @onImportSuccess="onImportSuccess" :template="'/Templates/处分导入模版.xlsx'"
                    :path="'/api/admin/punish/import'" :btnIcon="null" :title="'批量导入学生处分信息'" :btnName="null"
                    ref="conImportAdminPunish" :btnClass="'boxBtnImport'">
                  </comImport>
                </el-card>
              </el-col>
              <!-- 导入奖励信息 -->
              <el-col :xl="4" :lg="6" :md="8" :sm="8" :xs="12" v-if="isPower.prize_import">
                <el-card class="box-card turnOnModel">
                  <div slot="header" class="card-header">
                    <h3 class="card-title width_100Pie text-center">导入奖励信息</h3>
                  </div>
                  <div class="card-body p-0 importBox">
                    <div class="bodyIconBox VerticalMid ">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-import-box-alt"></use>
                      </svg>
                    </div>
                  </div>
                  <comImport @onImportSuccess="onImportSuccess" :template="'/Templates/奖励导入模版.xlsx'"
                    :path="'/api/admin/prize/import'" :btnIcon="null" :title="'批量导入学生奖励信息'" :btnName="null"
                    ref="conImportAdminPrize" :btnClass="'boxBtnImport'">
                  </comImport>
                </el-card>
              </el-col>
            </el-row>
            <div v-else>
              <el-empty :image-size="200" description="暂无权限" name="3"></el-empty>
            </div>
          </div>
        </el-card>


        <!-- 待办事项 -->
        <el-card class="box-card" v-if="(isPower.selfreport_audit || isPower.leavemgt_audit)">
          <div slot="header" class="card-header">
            <h3 class="card-title">
              待办事项
            </h3>
          </div>
          <div class="card-body">
            <el-tabs v-model="activeName" type="card">
              <el-tab-pane label="获奖上报" name="first" v-if="isPower.selfreport_audit">
                <el-table :data="itemDataSelfReport" stripe class="elTableData" border>
                  <el-table-column type="index" label="序号" width="80"></el-table-column>
                  <el-table-column prop="title" label="事项名称" min-width="200"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="student" label="对象" width="300"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="date" label="时间" width="320" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column label="操作" width="120" fixed="right">
                    <template slot-scope="scope">
                      <div>
                        <operationBtn :btnName="'审核'" @click.native="onAudit(scope.row)"></operationBtn>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="学生请假" name="second" v-if="isPower.leavemgt_audit">
                <el-table :data="itemDataAskforleave" stripe class="elTableData" border>
                  <el-table-column type="index" label="序号" width="80"></el-table-column>
                  <el-table-column prop="title" label="事项名称" min-width="200"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="student" label="对象" width="300"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="days" label="时长" width="120" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="startDate" label="开始时间" width="120"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="endDate" label="结束时间" width="120"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column label="操作" width="120" fixed="right">
                    <template slot-scope="scope">
                      <operationBtn :btnName="'审核'" @click.native="onAudit(scope.row)"></operationBtn>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="学生奖励" name="three" v-if="isPower.prize_teacher_audit">
                <el-table :data="itemDataPrize" stripe class="elTableData" border>
                  <el-table-column type="index" label="序号" width="80"></el-table-column>
                  <el-table-column prop="title" label="事项名称" min-width="100"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="student" label="对象" min-width="300"
                    :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column prop="date" label="时间" width="200" :show-overflow-tooltip="true"></el-table-column>
                  <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                      <operationBtn :btnName="'审核'" @click.native="onAudit(scope.row)"></operationBtn>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
      </div>
    </div>

    <div v-else>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          当前时间
        </div>
        <div class="card-body">
          <div class="divNowTime">
            {{nowData.time}}
          </div>
          <div class="divNowWeekNum" v-if="!regNull(nowData.semester)">
            {{nowData.semester}}
            <span class="spNum">第 <b class="color-danger">{{nowData.weekNum}}</b> 周</span>
          </div>
        </div>
      </el-card>
    </div>

    <!-- 导入课程成绩 -->
    <el-dialog title="导入课程成绩" :visible.sync="dialogVisible" width="60%" :close-on-click-modal="false"
      v-loading="fullscreenLoading2" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">

      <div class="divdvContBox">
        <div class="divSelect">
          <span class="select-text">学期:</span>
          <el-select v-model="downParams.semesterId" placeholder="请选择学期" class="width_100Pie"
            @change="handleDownSemest">
            <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId">
            </el-option>
          </el-select>
        </div>
        <div class="divSelect">
          <span class="select-text">班级:</span>
          <el-select v-model="downParams.classId" placeholder="请选择班级" class="width_100Pie" @change="handleDownClass">
            <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
            </el-option>
          </el-select>
        </div>
        <div class="divSelect">
          <span class="select-text">课程:</span>
          <el-select v-model="downParams.courseId" placeholder="请选择课程" class="width_100Pie">
            <el-option v-for="item in downCourseOpt" :key="item.courseId" :label="item.name" :value="item.courseId">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="divUploadBox">
        <el-upload ref="import" class="upload-demo" :action="getUploadApi" :limit="1" :before-upload="beforeUpload"
          :file-list="fileList" :auto-upload="false" :http-request="uploadFile">
          <el-button size="small" type="primary">点击上传文件</el-button>
        </el-upload>
        <div class="divTipFont">只允许上传一个xls或xlsx格式的文件，且不超过{{tipSize}}M</div>
      </div>

      <!--弹出框内容-->
      <div slot="footer" class="dialog-footer">
        <div style="float:left;font-size:13px;line-height: 40px;">
          <el-button type="text" @click="handleDownTemplate">下载模版</el-button>
        </div>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitUpload()">确 定</el-button>
      </div>
    </el-dialog>

    <!--导入错误提示框-->
    <el-dialog :title="dialogTitleErr" :visible.sync="dialogVisibleErr" width="60%" :close-on-click-modal="false"
      :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-table :data="itemDataErr" stripe class="elTableData" border>
        <el-table-column label="行号" class="elColumn" width="160">
          <template slot-scope="scope">
            第 {{(scope.row.index)}} 行
          </template>
        </el-table-column>
        <el-table-column prop="reason" label="错误原因" class="elColumn"></el-table-column>
      </el-table>
    </el-dialog>



    <!--快速查询学生信息-->
    <el-dialog title="快速查询学生信息" :visible.sync="isShowStudent" width="60%" :before-close="handleClose">
      <div class="card-body">
        <el-autocomplete class="input-with-select width_100Pie" v-model="selStudent"
          :fetch-suggestions="querySearchStudent" placeholder="请输入学生姓名" :trigger-on-focus="false"
          @select="onSearchStudent" value-key="value" @keyup.enter.native="onEnterSearchStudent">
          <el-select v-model="selStudentType" slot="prepend" placeholder="请选择" style="width: 120px;">
            <el-option label="基本信息" value="1" v-if="isPower.students_index"></el-option>
            <el-option label="处分信息" value="2" v-if="isPower.punishs_index"></el-option>
            <el-option label="行为规范" value="3" v-if="isPower.moralitylog_index"></el-option>
            <el-option label="获奖上报" value="4" v-if="isPower.selfreport_index"></el-option>
            <el-option label="学生档案" value="5" v-if="isPower.growtharchives_index"></el-option>
            <el-option label="综合评价" value="6" v-if="isPower.growth_index"></el-option>
          </el-select>
        </el-autocomplete>
      </div>
    </el-dialog>


    <!--快速查询班级信息-->
    <el-dialog title="快速查询班级信息" :visible.sync="isShowClass" width="60%" :before-close="handleClose">
      <div class="card-body">
        <el-autocomplete class="input-with-select width_100Pie" v-model="selClassName"
          :fetch-suggestions="querySearchClass" placeholder="请输入班级名称" :trigger-on-focus="false" @select="onClassInfo"
          value-key="value" @keyup.enter.native="onEnterClassInfo">
          <el-select v-model="selClassType" slot="prepend" placeholder="请选择" style="width: 120px;">
            <el-option label="班级学生" value="1" v-if="isPower.students_index"></el-option>
            <el-option label="班级成绩" value="2" v-if="isPower.score_adviser"></el-option>
            <el-option label="学生处分" value="3" v-if="isPower.punishs_index"></el-option>
            <el-option label="行为规范" value="4" v-if="isPower.moralityclasslog_index"></el-option>
            <el-option label="班级获奖" value="5" v-if="isPower.class_prize"></el-option>
            <el-option label="学生获奖" value="6" v-if="isPower.prize_index"></el-option>
          </el-select>
        </el-autocomplete>
      </div>
    </el-dialog>



  </el-skeleton>
</template>

<script>
  import comImport from '@/components/Import.vue';
  import operationBtn from '@/components/operationBtn.vue';
  export default {
    components: {
      comImport,
      operationBtn
    },
    data() {
      return {
        fullscreenLoading: false,
        fullscreenLoading2: false,
        isClassTeacher: false,
        //colorFlag为灰色或者为蓝色
        //clickFlag是否能够点击
        //boxFlag对号图标是否出现


        isPower: {
          students_index: false,
          growtharchives_index: false,
          growth_index: false,

          punishs_index: false, // 学生处分
          moralitylogs: false, // 行为规范
          selfreport_index: false, // 获奖上报
          moralitylog_index: false, // 行为规范学生个人

          score_adviser: false,
          class_prize: false,
          prize_index: false,

          admin_import_student: false,
          academicrecord_create: false,
          academicrecord_import: false,
          punish_import: false,
          prize_import: false,

          moralityclasslog_index: false,

          leavemgt_class_create: false,

          //审核
          selfreport_audit: false,
          leavemgt_audit: false,
          leavemgt_class_audit: false,
          leavemgt_studentoffice_audit:false,

          prize_teacher_audit: false,
        },

        //**快速查询学生
        isShowStudent: false,
        selStudent: '',

        //**// 快速查询班级信息
        isShowClass: false,
        selClassName: '',

        //**
        optActivityType: [],
        isShowActivity: false,
        selActivity: null,

        // 默认搜索学生方式
        selStudentType: '1',
        selClassType: '1',

        // 学生
        selInfo: null,


        // 导入提示错误
        dialogVisibleErr: false,
        dialogTitleErr: null,
        itemDataErr: [],

        dialogVisible: false,
        downCourseOpt: [], //模版下载课程列表
        downDialogVisible: false, //是否显示模版下载弹窗
        downParams: { //模版下载收集的数据
          semesterId: null,
          classId: null,
          courseId: null
        },
        selectClassId: null,
        optSemester: [],
        optClass: [],
        optCourse: [],
        //上传组件地址
        fileList: [],
        tipSize: webUploadFileSize(),


        itemDataSelfReport: [], // 获奖上报
        itemDataAskforleave: [], // 学生请假
        itemDataPrize: [], //学生奖励
        activeName: 'first',


        isWebShow: true,
        nowData: {
          time: timeTransform(new Date(), 'YYYY-MM-DD HH:mm'),
          weekName: null,
          weekNum: null,
          semester: null,
        },
        TeacherInfo: {}, // 教师信息
      };
    },
    computed: {
      /**
       * @description  设置导入地址
       */
      getUploadApi() {
        return `/api/admin/academicrecord/import/${this.downParams.semesterId}`
      }
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      isPower(that, 'student_index', 'students_index');
      isPower(that, 'students_index', 'students_index');
      isPower(that, 'growtharchives_index', 'growtharchives_index');
      isPower(that, 'growth_index', 'growth_index');
      isPower(that, "punishs_index", "punishs_index"); //学生处分
      isPower(that, "moralitylogs", "moralitylogs"); // 行为规范
      isPower(that, 'selfreport_index', 'selfreport_index'); // 获奖上报
      isPower(that, 'score_adviser', 'score_adviser');
      isPower(that, 'class_prize', 'class_prize');
      isPower(that, 'prize_index', 'prize_index');
      isPower(that, 'admin_import_student', 'admin_import_student');
      isPower(that, 'academicrecord_create', 'academicrecord_create');
      isPower(that, 'academicrecord_import', 'academicrecord_import');
      isPower(that, 'punish_import', 'punish_import');
      isPower(that, 'prize_import', 'prize_import');
      isPower(that, 'leavemgt_class_create', 'leavemgt_class_create');
      isPower(that, 'selfreport_audit', 'selfreport_audit');
      isPower(that, 'leavemgt_class_audit', 'leavemgt_class_audit');
      isPower(that, 'leavemgt_audit', 'leavemgt_audit');
      isPower(that, 'moralitylog_index', 'moralitylog_index');
      isPower(that, 'moralityclasslog_index', 'moralityclasslog_index');
      isPower(that, 'prize_teacher_audit', 'prize_teacher_audit');
      isPower(that,'leavemgt_studentoffice_audit','leavemgt_studentoffice_audit');
      // 获取学期
      ajaxGet(that, "/api/admin/semester", null, function(resSemester) {
        if (!regNullArray(resSemester)) {
          that.optSemester = resSemester;
          let tpSemester = resSemester.find(item => item.isCurrent == true);
          // 判断是否有当前学期，没有则默认显示第一个学期
          if (!regNull(tpSemester)) {
            that.downParams.semesterId = tpSemester.semesterId;
          } else {
            that.downParams.semesterId = resSemester[0].semesterId;
          }
        } else {
          that.optSemester = [];
        }
      });

      // 获取用户的班级范围
      that.optClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;


      // 判断 是班主任，但没有管理员权限
      if (that.TeacherInfo.isTeacher) {
        that.isClassTeacher = true;
        // 获取获奖上报和班级请假审核数据
        GetItemData(that);
      } else {
        if (regNullArray(that.TeacherInfo.allClass)) {
          that.isWebShow = false;
          that.noClassData();
        } else {
          // 获取获奖上报和班级请假审核数据
          GetItemData(that);
        }
      }


    },
    methods: {
      //审核
      onAudit(value) {
        let that = this;
        if (value.type == 1) { //获奖上报
          if (!that.isClassTeacher) {
            that.$router.push({
              path: "/Admin/SelfReport/Audit/" + value.id,
              query: {
                url: that.$router.history.current.fullPath
              }
            });
          } else {
            that.$router.push({
              path: "/Admin/Adviser/SelfReport/Audit/" + value.id,
              query: {
                url: that.$router.history.current.fullPath
              }
            });
          }
        } else if (value.type == 2) { //学生请假
          that.$router.push({
            path: "/Admin/LeaveMGT/Default/" + value.id,
            query: {
              url: that.$router.history.current.fullPath
            }
          });
        } else { //学生奖励
          that.$router.push({
            path: "/Admin/Adviser/StudentPrize/Audit/" + value.id,
            query: {
              url: that.$router.history.current.fullPath
            }
          });
        }
      },
      /**
       * @description 录入学生成绩
       */
      onCreateAcademicrecord() {
        let that = this;
        that.$router.push({
          path: "/Admin/Score/AdviserScoreRecord",
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      /**
       * @description 班主任代理学生请假
       */
      onCreateLeave() {
        let that = this;
        that.$router.push({
          path: "/Admin/LeaveMGT/Create",
          query: {
            url: that.$router.history.current.fullPath,
            category:1,
          }
        });
      },
      /**
       * @description 确认导入按钮事件
       */
      submitUpload() {
        let that = this;
        that.$refs.import.submit();
      },
      /**
       * @description 点击导入学生成绩
       */
      onOpenDialog() { //
        let that = this;
        that.dialogVisible = true
      },
      /**
       * @description 上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
       * @param {Object} file
       */
      beforeUpload(file) {
        let that = this;
        const isXls = file.type === 'application/vnd.ms-excel' || file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isXls) {
          warning(that, '仅支持xls和xlsx格式的文件上传!');
        }
        const isLt10M = file.size / 1024 / 1024 < webUploadFileSize();
        if (!isLt10M) {
          warning(that, '上传文件大小不能超过 ' + webUploadFileSize() + 'M!');
        }
        return isXls && isLt10M;
      },
      /**
       * @description 开始上传文件
       * @param {Object} content
       */
      uploadFile(content) {
        let that = this;
        if (!regNull(that.downParams.semesterId)) {
          // 接收上传文件的后台地址
          var url = `/api/admin/academicrecord/import/${that.downParams.semesterId}`;
          let files = new FormData();
          files.append('file', content.file);

          that.fullscreenLoading2 = true;
          ajaxImport(that, url, files, function(r) {
            that.fullscreenLoading2 = false;
            if (r.success >= 0 || r.success == true) {
              if (r.result == true) {
                var it = {
                  result: r.result, //导入结果
                  total: r.total, //导入记录总数
                  success: r.success, //导入成功记录数
                };
                // that.$refs.import.abort(content.file);
                that.fileList.splice(that.fileList.indexOf(content.file), 1);
                that.dialogVisible = false;
                that.onImportSuccess(it);
              } else {
                var it = {
                  result: r.result, //导入结果
                  total: r.total, //导入记录总数
                  error: r.error, //导入错误原因
                  message: r.message
                };
                that.$refs.import.abort(content.file);
                that.fileList.splice(that.fileList.indexOf(content.file), 1);
                that.dialogVisible = false;
                that.onImportSuccess(it);
              }
            } else {
              var it = {
                result: false,
                message: r.message
              }
              // that.$refs.import.abort(content.file);
              that.fileList.splice(that.fileList.indexOf(content.file), 1);
              that.dialogVisible = false;
              that.onImportSuccess(it);
            }
          });

        } else {
          warning(that, '导入课程成绩，至少需要选择对应的学期');
        }
      },
      /**
       * @description 开始下载模版
       */
      handleDownTemplate() {
        let that = this
        let {
          semesterId,
          classId,
          courseId
        } = that.downParams
        if (!classId) {
          warning(that, "请选择班级")
          return
        }
        if (!courseId) {
          warning(that, "请选择课程")
          return
        }
        that.fullscreenLoading = true
        let url = `/api/admin/academicrecord/exporttemplate/${semesterId}/${classId}/${courseId}`
        ajaxExport(that, url, null, "学生成绩导入模版.xls", function(res) {
          that.downDialogVisible = false
          that.fullscreenLoading = false
        })
      },
      /**
       * @description 下载模版班级选择
       */
      handleDownClass() { //
        let that = this
        that.downParams.courseId = null
        let url = `/api/admin/academicrecord/course/${that.downParams.semesterId}/${that.downParams.classId}`;
        ajaxGet(that, url, null, function(resData) {
          that.downCourseOpt = resData
        });
      },
      /**
       * @description 下载模版学期选择
       * @param {Object} val
       */
      handleDownSemest(objVal) { //
        let that = this
        that.downParams.courseId = null;
        that.downCourseOpt = [];
        if (!regNull(that.downParams.classId)) {
          that.handleDownClass();
        } else {
          ajaxGet(that, `/api/admin/academiccourse/course/${objVal}`, null, function(res) {
            that.downCourseOpt = res;
          });
        }
      },
      /**
       * @description 导出学生信息成功后执行
       * @param {Object} param
       */
      onImportSuccess(param) {
        let that = this;
        if (param.result == true) {} else {
          that.dialogVisibleErr = true;
          that.itemDataErr = param.error;
          that.dialogTitleErr = param.message;
        }
      },
      noClassData() {
        let that = this;
        let currentDate = timeTransform(new Date(), 'YYYY-MM-DD');
        ajaxGet(that, '/api/admin/semester', null, function(resSemester) {
          if (!regNullArray(resSemester)) {
            let tpSemester = resSemester.find(item => item.isCurrent == true);
            if (!regNull(tpSemester)) {
              that.nowData.semester = tpSemester.name;
              ajaxGet(that, '/api/admin/semesterday/search', {
                SemesterId: tpSemester.semesterId
              }, function(resWeek) {
                let nowTime = timeTransform(new Date(), 'YYYY-MM-DD');
                let tpWeek = resWeek.find(item => timeTransform(item.day, 'YYYY-MM-DD') == nowTime);
                if (!regNull(tpWeek)) {
                  that.nowData.weekNum = tpWeek.weekIndex;
                }
              });
            }
          }
        });


        this.timer = setInterval(() => {
          that.nowData.time = timeTransform(new Date(), 'YYYY-MM-DD HH:mm');
        }, 60000);




      },
      /**
       * @description 搜索学生补全
       * @param {Object} queryString
       * @param {Object} cb
       */
      querySearchStudent(queryString, cb) {
        let that = this;
        if (!regNull(queryString)) {
          ajaxGet(that, '/api/admin/student/autocomplete?name=' + queryString, null, function(resData) {
            if (!regNullArray(resData)) {
              let tpData = [];
              resData.forEach(function(item) {
                if (item.name.indexOf(queryString) >= 0) {
                  tpData.push({
                    value: item.name + '（学号：' + item.studentNo + ' 丨 班级：' + item.className + '）',
                    Id: item.studentId,
                    name: item.name
                  });
                }
              });
              cb(tpData);
            }
          });
        }
      },
      /**
       * @description 班级搜索
       * @param {Object} queryString
       * @param {Object} cb
       */
      querySearchClass(queryString, cb) {
        let that = this;
        if (!regNull(queryString)) {
          ajaxGet(that, '/api/admin/class/autocomplete?name=' + queryString, null, function(resData) {
            if (!regNullArray(resData)) {
              let tpData = [];
              resData.forEach(function(item) {
                if (item.name.indexOf(queryString) >= 0) {
                  tpData.push({
                    Id: item.id,
                    value: item.name,
                    name: item.name,
                  });
                }
              });
              cb(tpData);
            }
          });
        }
      },
      /**
       * @description 补全选择学生
       * @param {Object} objVal
       */
      onChangeVal(objVal) {
        let that = this;
        if (!regNull(that.selInfo)) {
          if (that.selInfo.name != objVal) {
            that.selInfo = null;
          }
        }
      },
      /**
       * @description 搜索
       * @param {Object} item
       */
      handleSelect(item) {
        this.selInfo = item;
      },
      /**
       * @description 回车搜索班级
       */
      onEnterClassInfo() {
        let that = this;
        if (regNull(that.selClassName)) {
          warning(that, '请输入班级名称');
          return false;
        }

        // 根据用户学生范围来进行查询
        ajaxGet(that, '/api/admin/sysuser/userfilter', null, function(resData) {
          if (!regNullArray(resData)) {
            let tempClass = resData.find(item => item.name == that.selClassName.trim());
            if (tempClass != undefined) {
              let tempUrl = '';
              switch (setInt(that.selClassType)) {
                case 1: // 查看班级学生
                  tempUrl = '/Admin/Student/Index?ClassId=' + tempClass.classId;
                  break;
                case 2: // 查看班级成绩
                  tempUrl = '/Admin/Score/Course?ClassId=' + tempClass.classId + '&TypeId=1';
                  break;
                case 3: // 查看班级学生处分
                  tempUrl = '/Admin/StudentPunish/Index?ClassId=' + tempClass.classId + '&PageNumer=1';
                  break;
                case 4: // 查看行为规范
                  tempUrl = '/Admin/Morality/Index?ClassId=' + tempClass.classId + '&PageNumer=1&type=1';
                  break;
                case 5: // 查看班级获奖
                  tempUrl = '/Admin/ClassPrize/Index?ClassId=' + tempClass.classId + '&PageNumer=1';
                  break;
                case 6: // 查看班级学生获奖
                  tempUrl = '/Admin/StudentPrize/Index?ClassId=' + tempClass.classId + '&PageNumer=1';
                  break;
              }
              if (!regNull(tempUrl)) {
                that.$router.push({
                  path: tempUrl,
                  query: {
                    url: that.$router.history.current.fullPath
                  }
                });
              }
            } else {
              warning(that, '暂未搜索到班级信息，请输入班级全名');
              return false;
            }
          } else {
            warning(that, '暂未搜索到班级信息，请输入班级全名');
            return false;
          }
        });

      },
      /**
       * @description 快速查询班级信息
       * @param {Object} objVal
       */
      onClassInfo(objVal) {
        let that = this;
        if (regNull(objVal)) {
          warning(that, '请输入班级名称');
          return false;
        }
        let tempUrl = '';
        switch (setInt(that.selClassType)) {
          case 1: // 查看班级学生
            tempUrl = '/Admin/Student/Index?ClassId=' + objVal.Id;
            break;
          case 2: // 查看班级成绩
            tempUrl = '/Admin/Score/Course?ClassId=' + objVal.Id + '&TypeId=1';
            break;
          case 3: // 查看班级学生处分
            tempUrl = '/Admin/StudentPunish/Index?ClassId=' + objVal.Id + '&PageNumer=1';
            break;
          case 4: // 查看行为规范
            tempUrl = '/Admin/Morality/Index?ClassId=' + objVal.Id + '&PageNumer=1&type=1';
            break;
          case 5: // 查看班级获奖
            tempUrl = '/Admin/ClassPrize/Index?ClassId=' + objVal.Id + '&PageNumer=1';
            break;
          case 6: // 查看班级学生获奖
            tempUrl = '/Admin/StudentPrize/Index?ClassId=' + objVal.Id + '&PageNumer=1';
            break;
        }
        if (!regNull(tempUrl)) {
          that.$router.push({
            path: tempUrl,
            query: {
              url: that.$router.history.current.fullPath
            }
          });
        }

      },

      /**
       * @description 回车搜索学生
       */
      onEnterSearchStudent() {
        let that = this;
        if (regNull(that.selStudent)) {
          warning(that, '请输入学生姓名');
          return false;
        }

        let tempData = {
          Name: that.selStudent,
          PageNumer: 1,
          PageSize: 10,
        }
        ajaxGet(that, '/api/admin/student/search', tempData, function(resData) {
          if (!regNullArray(resData.items)) {
            let url = null;
            switch (setInt(that.selStudentType)) {
              case 1: // 查看学生基本信息
                if (resData.items.length > 1) {
                  that.onGoPath(that, "/Admin/Student/Index?Name=" + that.selStudent);
                } else {
                  that.onGoPath(that, "/Admin/Student/Detail/" + resData.items[0].studentId);
                }
                break;
              case 2: // 查看学生处分信息
                // if (that.isClassTeacher) {
                //   that.onGoPath(that, "/Admin/Adviser/StudentPunish/Index?Student=" + that.selStudent +
                //     "&PageNumer=1");
                // } else {
                that.onGoPath(that, "/Admin/StudentPunish/Index?Student=" + that.selStudent + "&PageNumer=1");
                // }
                break;
              case 3: // 查看学生综合积分
                // if (that.isClassTeacher) {
                //   that.onGoPath(that, "/Admin/Adviser/Morality/Index?StudentInfo=" + that.selStudent +
                //     "&PageNumer=1&type=2");
                // } else {
                that.onGoPath(that, "/Admin/Morality/Index?Student=" + that.selStudent + "&PageNumer=1&type=2");
                // }
                break;
              case 4: // 查看学生获奖上报
                // if (that.isPower.selfreport_index == true) {
                that.onGoPath(that, "/Admin/SelfReport/Index?Student=" + that.selStudent + "&PageNumer=1");
                // } else {
                //   that.onGoPath(that, "/Admin/Adviser/SelfReport/Index?Name=" + that.selStudent + "&PageNumer=1");
                // }
                break;
              case 5: // 查看学生档案
                if (resData.items.length > 1) {
                  that.onGoPath(that, "/Admin/GrowthArchives/Index?Name=" + that.selStudent + "&PageNumer=1");
                } else {
                  that.onGoPath(that, "/Admin/GrowthArchives/Details/" + resData.items[0].studentId);
                }
                break;
              case 6: // 查看学生综合评价
                if (resData.items.length > 1) {
                  that.onGoPath(that, "/Admin/Growth/Index?Name=" + that.selStudent + "&PageNumer=1");
                } else {
                  that.onGoPath(that, "/Admin/LiteracyReport/Details/" + resData.items[0].studentId);
                }
                break;
            }
          } else {
            warning(that, '暂未搜索到学生信息，请重新输入学生姓名');
            return false;
          }
        });
      },
      /**
       * @description 快速查询学生信息
       * @param {Object} objType
       */
      onSearchStudent(objVal) {
        let that = this;
        if (regNull(objVal)) {
          warning(that, '请输入学生姓名');
          return false;
        }
        let url = null;
        switch (setInt(that.selStudentType)) {
          case 1: // 查看学生基本信息
            that.onGoPath(that, "/Admin/Student/Detail/" + objVal.Id);
            break;
          case 2: // 查看学生处分信息
            // if (that.isClassTeacher) {
            //   that.onGoPath(that, "/Admin/Adviser/StudentPunish/Index?Student=" + objVal.name + "&PageNumer=1");
            // } else {
            that.onGoPath(that, "/Admin/StudentPunish/Index?Student=" + objVal.name + "&PageNumer=1");
            // }
            break;
          case 3: // 查看学生行为规范
            // if (that.isClassTeacher) {
            //   that.onGoPath(that, "/Admin/Adviser/Morality/Index?StudentInfo=" + objVal.name + "&PageNumer=1&type=2");
            // } else {
            that.$router.push({
              path: "/Admin/Morality/Index?StudentInfo=" + objVal.name + "&PageNumer=1&type=2",
            });
            // }
            break;
          case 4: // 查看学生获奖上报
            // if (that.isClassTeacher) {
            //   that.onGoPath(that, "/Admin/Adviser/SelfReport/Index?Name=" + objVal.name + "&PageNumer=1");
            // } else {
            that.onGoPath(that, "/Admin/SelfReport/Index?Student=" + objVal.name + "&PageNumer=1");
            // }
            break;
          case 5: // 查看学生档案
            that.onGoPath(that, "/Admin/GrowthArchives/Details/" + objVal.Id);
            break;
          case 6: // 查看学生综合评价
            that.onGoPath(that, "/Admin/LiteracyReport/Details/" + objVal.Id);
            break;
        }
      },
      /**
       * @description 跳转
       * @param {Object} that
       * @param {Object} objUrl
       */
      onGoPath(that, objUrl) {
        if (!regNull(objUrl)) {
          that.$router.push({
            path: objUrl,
            query: {
              url: that.$router.history.current.fullPath
            }
          });
        }
      },
      /**
       * @description 找开查
       */
      onOpenBox(objType) {
        let that = this;
        switch (objType) {
          case 'student':
            that.isShowStudent = true;
            break;
          case 'class':
            that.isShowClass = true;
            break;
        }
      },
      /**
       * @description 关闭弹出框
       */
      handleClose() {
        let that = this;
        that.isShowStudent = false;
        that.isShowActivity = false;
        that.isShowClass = false;
      },


    },
  };

  function GetItemData(that) {
    // 获奖上报审核
    if (that.isPower.selfreport_audit) {
      let data = {
        isAdmin: !that.isClassTeacher
      };
      that.itemDataSelfReport = [];
      ajaxGet(that, "/api/admin/selfreport/unaudited", data, function(r) {
        r.forEach(item => {
          that.itemDataSelfReport.push({
            id: item.reportId,
            title: item.name,
            student: item.studentName + '【' + item.studentNo + ' | ' + item.class + '】',
            date: timeTransform(item.recordDate, 'YYYY-MM-DD'),
            type: 1,
            typeStr: '获奖上报',
          })
        })
      })
    }
    // 学生请假审核
    if (that.isPower.leavemgt_audit || that.isPower.leavemgt_class_audit || that.isPower.leavemgt_studentoffice_audit) {
      that.itemDataAskforleave = [];
      let data = {};
      if (that.isPower.leavemgt_class_audit) { //班主任
        data = {
          status: 1
        };
        ajaxGet(that, '/api/admin/askforleave/unaudited', data, function(r) {
          r.forEach(item => {
            that.itemDataAskforleave.push({
              id: item.leaveId,
              title: item.typeName,
              student: item.studentName + '【' + item.studentNo + ' | ' + item.className + '】',
              days: item.days,
              startDate: timeTransform(item.startDate, 'YYYY-MM-DD'),
              endDate: timeTransform(item.endDate, 'YYYY-MM-DD'),
              type: 2,
              typeStr: '学生请假',
            })
          })
        })
      }
      if (that.isPower.leavemgt_audit) { //年级主任
        data = {
          status: 4
        };
        ajaxGet(that, '/api/admin/askforleave/unaudited', data, function(r) {
          r.forEach(item => {
            that.itemDataAskforleave.push({
              id: item.leaveId,
              title: item.typeName,
              student: item.studentName + '【' + item.studentNo + ' | ' + item.className + '】',
              days: item.days,
              startDate: timeTransform(item.startDate, 'YYYY-MM-DD'),
              endDate: timeTransform(item.endDate, 'YYYY-MM-DD'),
              type: 2,
              typeStr: '学生请假',
            })
          })
        })
      }
      if (that.isPower.leavemgt_studentoffice_audit) { //分管校长
        data = {
          status: 16
        };
        ajaxGet(that, '/api/admin/askforleave/unaudited', data, function(r) {
          r.forEach(item => {
            that.itemDataAskforleave.push({
              id: item.leaveId,
              title: item.typeName,
              student: item.studentName + '【' + item.studentNo + ' | ' + item.className + '】',
              days: item.days,
              startDate: timeTransform(item.startDate, 'YYYY-MM-DD'),
              endDate: timeTransform(item.endDate, 'YYYY-MM-DD'),
              type: 2,
              typeStr: '学生请假',
            })
          })
        })
      }

    }

    // 学生奖励
    if (that.isPower.prize_teacher_audit) {
      that.itemDataPrize = [];
      let tpParasm = {
        isAdmin: !that.isClassTeacher
      };
      ajaxGet(that, '/api/admin/prizeapply/unaudited', tpParasm, function(resPrize) {
        if (!regNullArray(resPrize)) {
          resPrize.forEach((item) => {
            that.itemDataPrize.push({
              id: item.applyId,
              title: item.name,
              student: item.studentName + '【' + item.studentNo + ' | ' + item.class + '】',
              date: timeTransform(item.prizeDate, 'YYYY-MM-DD'),
              type: 3,
              typeStr: '学生奖励',
            });
          })

        }
      });
    }
  }
</script>

<style scoped="scoped" lang="less">
  .divNowTime {
    text-align: center;
    width: 100%;
    font-size: 62px;
    font-family: 'OswaldLight';
    letter-spacing: 2px;
    color: #2979ff;
    padding-top: 50px;

  }

  .divNowWeekNum {
    padding-top: 30px;
    font-size: 24px;
    text-align: center;
    width: 100%;
    padding-bottom: 50px;
  }

  .divUploadBox {
    margin-top: 25px;
    padding-left: 15px;
    position: relative;

    .divTipFont {
      position: absolute;
      top: 8px;
      left: 150px;
      font-size: 12px;
    }
  }

  .divdvContBox {
    padding-left: 15px;
    margin-top: -15px;
    display: flex;
  }

  .divdvContBox .divSelect {
    padding-right: 15px;
    width: 70%;
  }

  .turnOnModel {
    border: 2px solid #e4e7ed;
    cursor: pointer;
    position: relative;

    &:hover {
      border-color: #406ad3;
    }

    .bodyIconBox {
      height: 150px;
      width: 100%;
      overflow: hidden;
      font-size: 62px;
      color: #409eff;
      position: relative;
    }

    .importBox {
      margin-bottom: -24px;
    }

    .divCheckBox {
      font-size: 28px;
      color: #67c23a;
      position: absolute;
      z-index: 99;
      bottom: 0px;
      right: 10px;
    }
  }

  .importBox {
    margin-bottom: -24px;
  }

  .unopenedModel .bodyIconBox {
    height: 150px;
    font-size: 62px;
    color: #cecece;
  }

  .unopenedModel .card-title {
    color: #cecece;
  }

  .unopenedModel .divCheckBox {
    display: none;
  }

  .el-table /deep/ .el-table__fixed-right {
    height: 100% !important;
  }
</style>

<style lang="less">
  .tabsHeader {
    .el-tabs__item {
      height: 50px;
      line-height: 50px;
    }

    .el-tabs__header {
      margin: 0;
      margin-bottom: -1px;
    }

    .el-tabs--card>.el-tabs__header .el-tabs__nav {
      border: none;
    }

    .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
      background-color: #FFFFFF;
    }
  }
</style>
