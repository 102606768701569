<!--
  评语短语
-->
<template>
    <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="padding-left: 0px;">
            评语短语
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <search :SearchList="SearchList" ref="SearchList" @onSearch="onSearch" v-if="SearchList!=null"></search>
              </li>
              <li class="nav-item">
                <el-button v-if="isPower.commentphrase_create == true" type="success" icon="el-icon-circle-plus-outline"
                  @click="onCreate()">新增</el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body p-0">
          <el-table :data="itemData" stripe class="elTableData">
            <el-table-column type="index" label="序号" class="elColumn" width="80" :index="indexMethod"></el-table-column>
            <el-table-column prop="addr" label="短语简称" class="elColumn"></el-table-column>
            <el-table-column prop="content" label="短语内容" class="elColumn"></el-table-column>
            <el-table-column prop="priority" label="排序优先级" class="elColumn"></el-table-column>
            <!-- <el-table-column prop="isPublic" label="是否公共短语" class="elColumn">
              <template slot-scope="scope">
                <el-switch active-color="#409EFF" inactive-color="#C0CCDA" v-model="scope.row.enable" disabled>
                </el-switch>
              </template>
            </el-table-column> -->
            <el-table-column label="操作" width="250">
              <template slot-scope="scope">
                <operationBtn v-if="isPower.commentphrase_detail == true" :btnName="'查看'"
                  @click.native="onDetail(scope.row.phraseId)"></operationBtn>
                <operationBtn v-if="isPower.commentphrase_edit == true" :btnName="'编辑'"
                  @click.native="onEdit(scope.row.phraseId)"></operationBtn>
                <operationBtn v-if="isPower.commentphrase_delete == true" :btnName="'删除'"
                  @click.native="onDelete(scope.row.phraseId)"></operationBtn>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </el-card>
  
      <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false"
        :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px">
          <el-row v-if="isDetail!=true">
            <el-col :span="24">
              <el-form-item label="短语简称" prop="addr">
                <el-input v-model="ruleForm.addr" placeholder="请输入短语简称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="短语内容" prop="content">
                <el-input v-model="ruleForm.content" placeholder="请输入短语内容" type="textarea" :rows="4"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="优先级" prop="priority">
                <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24" v-if="!ruleForm.phraseId">
              <el-form-item label="是否公共短语" prop="isPublic">
                <el-switch v-model="ruleForm.isPublic" active-color="#409EFF" inactive-color="#C0CCDA">
                </el-switch>
              </el-form-item>
            </el-col> -->
          </el-row>
          <div class="row" v-else>
            <div class="col-lg-12 colInfo">
              <label>短语简称</label>
              <div class="colFonts">{{ruleForm.addr}}</div>
            </div>
            <div class="col-lg-12 colInfo">
              <label>短语内容</label>
              <div class="colFonts">{{ruleForm.content}}</div>
            </div>
            <div class="col-lg-12 colInfo">
              <label>优先级</label>
              <div class="colFonts">{{ruleForm.priority}}</div>
            </div>
            <!-- <div class="col-lg-12 colInfo">
              <label>是否公共短语</label>
              <div class="colFonts">
                <span class="color-success" v-if="ruleForm.isPublic">是</span>
                <span class="color-gray" v-else>否</span>
              </div>
            </div> -->
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <div v-if="isDetail!=true">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button @click="onReset()">重 置</el-button>
            <el-button type="primary" @click="onSave()">确 定</el-button>
          </div>
          <div v-else>
            <el-button @click="dialogVisible = false">关 闭</el-button>
          </div>
        </span>
      </el-dialog>
  
    </div>
  </template>
  
  <script>
    import '@/assets/css/Admin.css';
    import comPage from '@/components/Page.vue';
    import operationBtn from '@/components/operationBtn.vue';
    import search from '@/components/search.vue';
  
    export default {
      components: {
        comPage,
        operationBtn,
        search
      },
      data() {
        return {
          //加载动画
          fullscreenLoading: false,
          //搜索
          SearchList: null,
          //分页查询接口返回的结果
          page: null,
          //待重置时的表单数据
          ruleFormReset: null,
          //列表数据
          itemData: [],
          //分页左侧按钮集合
          pageBtnList: null,
          //分页数据
          paging: {
            // 总共页数
            pageLength: 0,
            // 每页请求的数据量
            Size: 1,
            //当前在第几页
            Index: 1
          },
          dialogVisible: false,
          dialogTitle: "新增", //弹出框标题
          isDetail: false, //是否是查看弹出框
          ruleForm: {
            addr: null, //短语简称
            content: null, //短语内容
            priority: null, //排序优先级
            isPublic: true
          },
          rules: {
            addr: [
                { required: true, message: '短语简称不能为空', trigger: 'change' }
            ],
            content: [
                { required: true, message: '短语内容不能为空', trigger: 'change' }
            ],
            priority: [{
              validator: (rule, value, callback) => {
                let that = this;
                var reg = /^-?[1-9]\d*$/;
                if (value != 0 && !reg.exec(value)) {
                  callback(new Error("优先级只能输入整数！"));
                } else {
                  callback();
                }
              },
              trigger: 'change'
            }],
          },
          isPower: {
            //增删改查 权限管理
            commentphrase_detail: false,
            commentphrase_create: false,
            commentphrase_edit: false,
            commentphrase_delete: false,
          },
          isTeacher: false, //是否是老师
        };
      },
      methods: {
        /**
         * @description 列表序号索引
         * @param {Object} index
         */
        indexMethod(index) {
          return (this.paging.Index - 1) * this.paging.Size + index + 1;
        },
        onPriorityChange() {
          let that = this;
          var value = setInt(that.ruleForm.priority);
          that.ruleForm.priority = value;
        },
        onSave() //保存（新增和编辑）
        {
          let that = this;
          if (that.ruleForm.priority == null || that.ruleForm.priority == "") {
            that.ruleForm.priority = "0"
          }
          that.$refs["ruleForm"].validate(valid => {
            if (valid) {
              if (that.ruleForm.phraseId == null) {
                that.fullscreenLoading = true;
                if (that.isTeacher) { //老师
                    that.ruleForm.isPublic = false;
                } else { //管理员
                    that.ruleForm.isPublic = true;
                }
                ajaxPost(that, "/api/admin/commentphrase", that.ruleForm, function(r) {
                  that.fullscreenLoading = false;
                  that.dialogVisible = false;
                  pagingPage(that);
                });
              } else {
                that.fullscreenLoading = true;
                ajaxPut(that, "/api/admin/commentphrase/" + that.ruleForm.phraseId, that.ruleForm, function(r) {
                  that.fullscreenLoading = false;
                  that.dialogVisible = false;
                  pagingPage(that);
                });
              }
            } else {
  
              setTimeout(() => {
                var isError = document.getElementsByClassName("is-error");
                isError[0].querySelector('input').focus();
              }, 1)
              return false;
            }
          });
        },
        onCreate() //新增
        {
          let that = this;
          that.dialogTitle = "新增";
          var ruleForm = JSON.parse(JSON.stringify(that.ruleForm));
          for (var i in ruleForm) {
            ruleForm[i] = null;
          }
          that.ruleForm = JSON.parse(JSON.stringify(ruleForm));
          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          that.dialogVisible = true;
          that.isDetail = false;
          try {
            that.$refs["ruleForm"].resetFields();
          } catch {}
        },
        onDetail(Id) //查看
        {
          let that = this;
          that.fullscreenLoading = true;
          that.isDetail = true;
          ajaxGet(that, "/api/admin/commentphrase/" + Id, null, function(r) {
            that.fullscreenLoading = false;
            that.dialogTitle = "查看";
            that.ruleForm = r;
            that.dialogVisible = true;
          });
        },
        onEdit(Id) //编辑
        {
          let that = this;
          that.fullscreenLoading = true;
          that.isDetail = false;
          ajaxGet(that, "/api/admin/commentphrase/" + Id, null, function(r) {
            that.fullscreenLoading = false;
            that.dialogTitle = "编辑";
            that.ruleForm = r;
            that.dialogVisible = true;
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
          });
        },
        onSearch(params) // 搜索
        {
          let _this = this;
          let data = {};
          let searchData = params;
          searchData.forEach(element => {
            if (element.data) {
              data[element.zhi] = element.data;
            }
          });
          data.PageNumer = 1
          routerPath(_this, "/Admin/CommentPhrase/Index", data, null, "PageSize");
        },
        onReset() //重置
        {
          let that = this;
          this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
          try {
            that.$refs["ruleForm"].resetFields();
          } catch {}
        },
        onDelete(Id) //删除
        {
          let that = this;
          confirmDelete(that, null, function(res) {
            if (res == true) {
              that.fullscreenLoading = true;
              ajaxDelete(that, "/api/admin/commentphrase/" + Id, null, function(r) {
                that.fullscreenLoading = false;
                pagingPage(that);
              });
            }
          })
        },
        pageBtnClick(index) {
          //分页组件左侧的按钮事件，按顺序进行判断
        },
        // 分页面按钮
        pagingClick(type, val) {
          let that = this;
          let url = "/Admin/CommentPhrase/Index";
          if (type == 1) { //更改每页条数触发的事件
            routerPath(
              that,
              url,
              "PageSize",
              val,
              "PageNumer",
              "Content",
            );
          } else { //更改当前页时触发的事件
            routerPath(
              that,
              url,
              "PageNumer",
              val,
              "PageSize",
              "Content",
            );
          }
        }
      },
      created() {
        let that = this;
        isPower(that, "commentphrase_detail", "commentphrase_detail");
        isPower(that, "commentphrase_create", "commentphrase_create");
        isPower(that, "commentphrase_edit", "commentphrase_edit");
        isPower(that, "commentphrase_delete", "commentphrase_delete");
        // 获取教师信息
        let tpUserInfo = onGetNowUser(that);
        // 判断是否为班主任
        that.isTeacher = tpUserInfo.isTeacher;
        pagingPage(that);
      },
      watch: {
        $route() {
          let that = this;
          pagingPage(that);
        }
      }
    };
  
    function pagingPage(that) {
      paging(
        that,
        function(r) {
          let apiUrl = "/api/admin/commentphrase/search";
          let data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            Content: r.Content,
            IsPublic: !that.isTeacher
          };
          that.SearchList = [{
            type: "input",
            Name: "内容",
            data: r.Content,
            holder: "请输入内容关键字",
            zhi: "Content"
          }];
          that.paging.Index = r.PageNumer;
          that.paging.Size = r.PageSize;
          that.fullscreenLoading = true;
          ajaxGet(that, apiUrl, data, function(r) {
            that.page = r;
            that.itemData = r.items;
            that.fullscreenLoading = false;
          });
        },
        "Content",
      );
    }
  </script>
  
  <style scoped="scoped">
  
  </style>
  