import * as dd from 'dingtalk-jsapi';
import '@/plugins/method.js';
//
export function getCode(callback) {
  let corpId = 'ding80eb5859c393a6b235c2f4657eb6378f'; //corpId
  // recordLog(this, 4, 'dingding', 'err', '', '', '');
  if (dd.env.platform !== "notInDingTalk") {
    dd.ready(() => {
      //使用SDK 获取免登授权码
      dd.runtime.permission.requestAuthCode({
        corpId: corpId,
        onSuccess: (info) => {
          // 根据钉钉提供的api 获得code后,再次调用这个callback方法
          // 由于是钉钉获取code是异步操作,不知道什么时候执行完毕
          // callback 函数会等他执行完毕后在自己调用自己
          callback(info.code)
        },
        onFail: (err) => {
          localStorage.setItem("errDingDing", JSON.stringify(err));
          callback(null);
        },
      });
    });
  } else {
    // localStorage.setItem("errDingDing", 'JSON.stringify(err)');
    // callback(null);
    callback('notInDingTalk');
  }

  // callback('c626cd5b4dd63aefa33f795e8753825d');
}
