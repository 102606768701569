<!--
  新增家校联系
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :lg="8" :sm="12">
              <el-form-item prop="semesterId" label="学期">
                <el-select v-model="ruleForm.semesterId" filterable placeholder="请选择学期" style="width: 100%;"
                  @change="handSemesterId" :disabled="ruleForm.commentId!=null">
                  <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name"
                    :value="item.semesterId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item prop="classId" label="班级">
                <el-select v-model="ruleForm.classId" filterable placeholder="请选择班级" style="width: 100%;"
                  @change="handClassId" :disabled="ruleForm.commentId!=null">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item label="学生" prop="studentId">
                <el-select v-model="ruleForm.studentId" filterable placeholder="请选择" style="width: 100%;"
                  @change="handStudentId" :disabled="ruleForm.commentId!=null">
                  <el-option v-for="item in optStudent" :key="item.studentId" :label="item.name"
                    :value="item.studentId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="12">
              <el-form-item label="等级" prop="commentsRank">
                <el-select v-model="ruleForm.commentsRank" filterable placeholder="请选择" style="width: 100%;">
                  <el-option v-for="item in optCommentType" :key="item.Value" :label="item.Text" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <el-tabs type="border-card">
        <el-tab-pane label="学生成绩">
          <el-table :data="itemData.academicRecords" stripe class="elTableData">
            <el-table-column prop="courseName" label="课程" class="elColumn"></el-table-column>
            <el-table-column prop="peaceTime" label="平时" class="elColumn"></el-table-column>
            <el-table-column prop="midTerm" label="期中" class="elColumn"></el-table-column>
            <el-table-column prop="final" label="期末" class="elColumn"></el-table-column>
            <el-table-column prop="total" label="总评" class="elColumn"></el-table-column>
            <el-table-column prop="gpa" label="绩点" class="elColumn"></el-table-column>
            <el-table-column prop="credit" label="学分" class="elColumn"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="处分记录">
          <el-table :data="itemData.punishes" stripe class="elTableData">
            <el-table-column prop="punishNo" label="处分号">
              <template slot-scope="scope">
                <span>{{scope.row.punishNo}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="reasonName" label="处分原因">
              <template slot-scope="scope">
                <span>{{scope.row.reasonName}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level" label="处分类型">
              <template slot-scope="scope">
                <span v-if="scope.row.level!=null">{{scope.row.level.name}}</span>
              </template>
            </el-table-column>
            <el-table-column label="处分时间">
              <template slot-scope="scope">
                <span>{{scope.row.punishDate | dateformatDay}}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="获奖情况">
          <el-table :data="itemData.prizes" stripe class="elTableData">
            <el-table-column prop="name" label="奖项">
              <template slot-scope="scope">
                <span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="level" label="奖励级别">
              <template slot-scope="scope">
                <span v-if="scope.row.level!=null">{{scope.row.level.name}}</span>
              </template>
            </el-table-column>
            <el-table-column label="奖励时间">
              <template slot-scope="scope">
                <span>{{scope.row.prizeDate | dateformatDay}}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- <el-tab-pane label="优秀干部">优秀干部</el-tab-pane>
        <el-tab-pane label="各项荣誉">各项荣誉</el-tab-pane> -->
      </el-tabs>

      <el-card class="box-card" style="margin-top: 16px;" v-if="!regNullArray(optCommentphrase.publicPhrase)">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            公共短语
          </h3>
        </div>
        <div class="card-body">
          <el-tooltip class="item" effect="dark" :content="p.content" placement="top"
            v-for="(p, index) in optCommentphrase.publicPhrase" :key="index">
            <el-button @click="onClickAddr(p.content)">{{p.addr}}</el-button>
          </el-tooltip>
        </div>
      </el-card>

      <el-card class="box-card" style="margin-top: 16px;" v-if="!regNullArray(optCommentphrase.personalPhrase)">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            个人短语
          </h3>
        </div>
        <div class="card-body">
          <el-tooltip class="item" effect="dark" :content="p.content" placement="top"
            v-for="(p, index) in optCommentphrase.personalPhrase" :key="index">
            <el-button @click="onClickAddr(p.content)">{{p.addr}}</el-button>
          </el-tooltip>
        </div>
      </el-card>
      <el-card class="box-card" style="margin-top: 16px;">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            评语
          </h3>
        </div>
        <div class="card-body">
          <quill-editor height="300px" v-model="ruleForm.description" ref="text" class="myQuillEditor"
            :options="editorOption" />
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import {
    quillEditor
  } from 'vue-quill-editor';
  import * as Quill from 'quill' //引入编辑器
  var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
  var Font = Quill.import('formats/font');
  Font.whitelist = fonts; //将字体加入到白名单
  Quill.register(Font, true);

  export default {
    components: {
      quillEditor
    },
    data() {
      return {
        fullscreenLoading: false,
        optCommentphrase: [],
        editorOption: {
          placeholder: '请在此输入内容',
          modules: {
            toolbar: [
              [{
                'font': fonts
              }], //字体，把上面定义的字体数组放进来
              ['bold', 'italic', 'underline',
                'strike'
              ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{
                'direction': 'rtl'
              }], // 文本方向-----[{'direction': 'rtl'}]
              [{
                size: ['small', false, 'large', 'huge']
              }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{
                header: [1, 2, 3, 4, 5, 6, false]
              }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{
                align: []
              }], // 对齐方式-----[{ align: [] }]
              ['clean'], // 清除文本格式-----['clean']
              // ['image'], // 链接、图片、视频-----['link', 'image', 'video']
            ]
          }
        },
        optClass: [],
        optSemester: [],
        optStudent: [],
        optCommentType: [], //评语类型
        itemData: {},
        itemDataReset: null,
        ruleForm: {
          commentId: null,
          semesterId: null,
          classId: null,
          studentId: null,
          teacherId: null, //班主任
          description: null, //说明
          commentsRank: null, //等级
          strSemesterId: null,
          strStudentId: null,
        },
        ruleFormReset: {}, //重置
        Title: "新增",
        rules: {
          semesterId: [{
            required: true,
            message: '学期不能为空',
            trigger: 'change'
          }, ],
          classId: [{
            required: true,
            message: '班级不能为空',
            trigger: 'change'
          }, ],
          studentId: [{
            required: true,
            message: '学生不能为空',
            trigger: 'change'
          }, ],
          teacherId: [{
            required: true,
            message: '班主任不能为空',
            trigger: 'change'
          }, ],
          commentsRank: [{
            required: true,
            message: '等级不能为空',
            trigger: 'change'
          }, ],
        },

        TeacherInfo: {}, // 教师信息
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      let id = that.$route.params.Id;
      that.itemDataReset = JSON.parse(JSON.stringify(that.itemData));
      that.ruleForm.commentId = id;
      that.fullscreenLoading = true;
      ajaxGet(that, "/api/admin/Semester", null, function(resultSemester) {
        ajaxGet(that, "/api/admin/commentphrase/userphrase", null, function(resCommentphrase) {
          that.optCommentphrase = resCommentphrase;
          ajaxGet(that, "/api/admin/studentcomment/type", null, function(resultCommentType) {
            let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo
              .allClass;
            that.optSemester = resultSemester;
            that.optCommentType = resultCommentType;
            if (!regNullArray(resultClass)) {
              ajaxGet(that, "/api/admin/student/class/" + resultClass[0].classId, null, function(r) {
                that.fullscreenLoading = false;
                that.optClass = resultClass;
                that.optStudent = r;
                if (id != null) {
                  that.Title = '编辑';
                  that.fullscreenLoading = true;
                  ajaxGet(that, "/api/admin/studentcomment/" + id, null, function(res) {
                    ajaxGet(that, "/api/admin/student/class/" + res.classId, null, function(r) {
                      ajaxGet(that, "/api/admin/studentcomment/studentinfo?studentId=" + res
                        .studentId + "&semesterId=" + res.semesterId, null,
                        function(r1) {
                          that.fullscreenLoading = false;
                          that.itemData = r1;
                          that.itemDataReset = JSON.parse(JSON.stringify(that.itemData));
                          that.ruleForm = res;
                          that.ruleForm.commentsRank = res.commentsRankString;
                          that.optStudent = r;
                          that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
                        })
                    })
                  })
                } else {
                  for (var item of resultSemester) {
                    if (item.isCurrent == true) {
                      that.ruleForm.semesterId = item.semesterId;
                      that.ruleForm.strSemesterId = item.semesterId;
                    }
                  }
                  that.ruleForm.classId = resultClass[0].classId;
                  that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
                }
              });
            } else {
              that.fullscreenLoading = false;
            }

          });
        });
      })
    },

    methods: {
      onClickAddr(val) {
        let that = this;
        if (that.ruleForm.description == null) {
          that.ruleForm.description = val;
        } else {
          that.ruleForm.description = that.ruleForm.description + val;
        }

      },
      handSemesterId(val) {
        let that = this;
        that.ruleForm.strSemesterId = val;
        if (that.ruleForm.strStudentId != null && that.ruleForm.strSemesterId != null) {
          that.fullscreenLoading = true;
          ajaxGet(that, "/api/admin/studentcomment/studentinfo?studentId=" + that.ruleForm.strStudentId +
            "&semesterId=" + that.ruleForm.strSemesterId, null,
            function(r) {
              that.fullscreenLoading = false;
              that.itemData = r;
            })
        }
      },
      handStudentId(val) {
        let that = this;
        that.ruleForm.strStudentId = val;

        if (that.ruleForm.strStudentId != null && that.ruleForm.strSemesterId != null) {
          that.fullscreenLoading = true;
          ajaxGet(that, "/api/admin/studentcomment/studentinfo?studentId=" + that.ruleForm.strStudentId +
            "&semesterId=" + that.ruleForm.strSemesterId, null,
            function(r) {
              that.fullscreenLoading = false;
              that.itemData = r;

            })
        }
      },
      handClassId(val) {
        let that = this;
        that.ruleForm.studentId = null;
        that.optStudent = [];
        that.itemData = [];
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/student/class/" + val, null, function(r) {
          that.fullscreenLoading = false;
          that.optStudent = r;
        })
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.itemData = JSON.parse(JSON.stringify(that.itemDataReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() {
        let that = this;
        back(that);
      },
      onSave() {
        let that = this;
        if (that.ruleForm.description != null) {
          that.ruleForm.description = that.ruleForm.description.replace(new RegExp(/( )/g), '&nbsp;');
        }
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.commentId == null) {
              that.fullscreenLoading = true;
              var data = JSON.parse(JSON.stringify(that.ruleForm));
              data.commentsRank = parseInt(data.commentsRank);
              ajaxPost(that, "/api/admin/studentcomment", data, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              that.fullscreenLoading = true;
              var data = JSON.parse(JSON.stringify(that.ruleForm));
              for (var i in that.optStudent) {
                if (data.studentId == that.optStudent[i].name) {
                  data.studentId = that.optStudent[i].studentId;
                }
              }
              for (var i in that.optCommentType) {
                if (data.commentsRank == that.optCommentType[i].Text) {
                  data.commentsRank = that.optCommentType[i].Value;
                }
              }
              data.commentsRank = parseInt(data.commentsRank);
              ajaxPut(that, "/api/admin/studentcomment/" + data.commentId, data, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          }
        });
      }
    },
    watch: {

    }
  };
</script>

<style scoped="scoped">

</style>
<style>
  .ql-toolbar.ql-snow {
    line-height: 20px;
  }

  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }

  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
  }

  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "请输入视频地址:";
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimSun]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimSun]::before {
    content: "宋体";
    font-family: "SimSun";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=SimHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=SimHei]::before {
    content: "黑体";
    font-family: "SimHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Microsoft-YaHei]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Microsoft-YaHei]::before {
    content: "微软雅黑";
    font-family: "Microsoft YaHei";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=KaiTi]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=KaiTi]::before {
    content: "楷体";
    font-family: "KaiTi";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=FangSong]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=FangSong]::before {
    content: "仿宋";
    font-family: "FangSong";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Arial]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Arial]::before {
    content: "Arial";
    font-family: "Arial";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=Times-New-Roman]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=Times-New-Roman]::before {
    content: "Times New Roman";
    font-family: "Times New Roman";
  }

  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=sans-serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=sans-serif]::before {
    content: "sans-serif";
    font-family: "sans-serif";
  }

  .ql-font-SimSun {
    font-family: "SimSun";
  }

  .ql-font-SimHei {
    font-family: "SimHei";
  }

  .ql-font-Microsoft-YaHei {
    font-family: "Microsoft YaHei";
  }

  .ql-font-KaiTi {
    font-family: "KaiTi";
  }

  .ql-font-FangSong {
    font-family: "FangSong";
  }

  .ql-font-Arial {
    font-family: "Arial";
  }

  .ql-font-Times-New-Roman {
    font-family: "Times New Roman";
  }

  .ql-font-sans-serif {
    font-family: "sans-serif";
  }

  .addr {
    margin-right: 5px;
  }

  .addr:hover {
    cursor: pointer;
  }
</style>
