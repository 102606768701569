<!--
  首页
-->
<!--班主任-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading" v-if="homeData!=null">
    <div class="divSubTitleCont">
      <div class="divTabCont">
        <a class="aTabList" :class="{'aTabAct':dateCurrent==1}" @click="onTopTabs(1)"><span>本周</span></a>
        <a class="aTabList" :class="{'aTabAct':dateCurrent==2}" @click="onTopTabs(2)"><span>本月</span></a>
        <a class="aTabList" :class="{'aTabAct':dateCurrent==3}" @click="onTopTabs(3)"><span>本学期</span></a>
        <a class="aTabList" @click="onSchool"><span>全校分析</span></a>
      </div>
      <div class="divSelClass">
        <el-select v-model="selClassValue" placeholder="请选择班级" @change="onChangeClass">
            <el-option
              v-for="item in optSelClass"
              :key="item.classId"
              :label="item.alias"
              :value="item.classId">
            </el-option>
          </el-select>
      </div>
    </div>

    <el-row :gutter="20">
      <el-col :span="24">
        <div class="divBgBox">
          <div class="divLabelItem clearfix">
            <div class="divItemList">
              <div class="divNum color-primary">
                {{homeData.morality}}
                <span class="spNumCont" v-if="homeData.moralityRatio>0">
                  <svg class="icon color-danger" aria-hidden="true">
                    <use xlink:href="#fax-arrow-up"></use>
                  </svg>
                  {{homeData.moralityRatio}}%
                </span>
              </div>
              <div class="divFont">行为规范记录</div>
              <div class="divRightLine"><span></span></div>
            </div>
            <div class="divItemList">
              <div class="divNum color-warning">
                {{homeData.activity}}
                <span class="spNumCont" v-if="homeData.activityRatio>0">
                  <svg class="icon color-success" aria-hidden="true">
                    <use xlink:href="#fax-arrow-down"></use>
                  </svg>
                  {{homeData.activityRatio}}%
                </span>
              </div>
              <div class="divFont">活动数</div>
              <div class="divRightLine"><span></span></div>
            </div>
            <div class="divItemList">
              <div class="divNum color-info">
                {{homeData.prize}}
                <span class="spNumCont" v-if="homeData.prizeRatio>0">
                  <svg class="icon color-success" aria-hidden="true">
                    <use xlink:href="#fax-arrow-down"></use>
                  </svg>
                  {{homeData.prizeRatio}}%
                </span>
              </div>
              <div class="divFont">竞赛获奖</div>
              <div class="divRightLine"><span></span></div>
            </div>
            <div class="divItemList">
              <div class="divNum color-dark-red">
                {{homeData.selfReport}}
                <span class="spNumCont" v-if="homeData.selfReportRatio>0">
                  <svg class="icon color-danger" aria-hidden="true">
                    <use xlink:href="#fax-arrow-up"></use>
                  </svg>
                  {{homeData.selfReportRatio}}%
                </span>
              </div>
              <div class="divFont">获奖上报</div>
              <div class="divRightLine"><span></span></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="24" v-if="LiteracyTotal>0">
        <div class="divItemCont clearfix">
          <div class="divLeft50">
            <div class="divBgBox divLiteracyBox">
              <div class="boxCont">
                <div class="boxTitle">核心综合概况</div>
                <div class="divPieCont clearfix">
                  <div class="picBox picBox50">
                    <div class="divTotalCont">
                      <span class="spNum">{{LiteracyTotal}}</span>
                      <span class="spFont">条记录</span>
                    </div>
                    <comChartPieDiv
                      :ChartData="LiteracyData"
                      :ChartColor="optColor"
                      :ChartHeight="250"
                      :ChartID="'LiteracyPie'"
                      :ChartName="'核心综合概况'"
                      :ChartRadius="pieChartWH"
                      ref="LiteracyPie"
                    />
                  </div>
                  <div class="picInfo">
                    <div class="divInfoItem">
                      <div class="divItemList" v-for="(item,index) in homeData.growthLogSurveys" :key="index">
                        <span class="spIcon" :style="optColor[index] | flColor">
                          <svg class="icon" aria-hidden="true">
                              <use xlink:href="#fax-circle"></use>
                          </svg>
                        </span>
                        <span class="spPct">{{onGetPct(LiteracyTotal,item.quantity)}}%</span>
                        <span class="spLabel">{{item.name}} - </span>
                        <span class="spNum">{{item.quantity}} 条</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="divRight50 clearfix">
            <div class="divLabelList" v-for="(item,index) in homeData.growthLogSurveys" :key="index">
              <div class="divBgBox VerticalMid">
                  <div class="divLable">
                    <span class="spLabel bg-purple">{{item.name}}</span>
                  </div>
                  <div class="divInfo">
                    <div class="divScores">{{item.score>0?'+'+item.score:item.score}}<span class="spFont">分</span></div>
                    <div class="divNumber">{{item.quantity}}条记录</div>
                  </div>
                  <div class="divPct" v-if="item.ringRatio>0">
                    <svg class="icon color-success" aria-hidden="true">
                        <use xlink:href="#fax-arrow-down"></use>
                    </svg>
                    {{item.ringRatio}}%
                  </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="24" v-if="homeData.growthLogTrends!=null && homeData.growthLogTrends.length!=0">
        <div class="divItemCont clearfix" >
          <div class="divBgBox divBoxHeight divDivideBox">
            <div class="boxCont">
              <div class="boxTitle">
                各项情况走势
                <div class="awardsLabelCont borderNone clearfix">
                  <div class="divItemList" v-for="(item,index) in homeData.growthLogTrends.configName" :key="index">
                    <span class="spIcon VerticalMid" :style="optColor[index] | flColor">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#fax-circle"></use>
                      </svg>
                    </span>
                    {{item}}
                  </div>
                </div>
              </div>
              <div class="divChartTrendCont">
                 <comChartLineDiv :ref="'chartLineDiv'" :ChartColor="optColor" :ChartHeight="350" :ChartXTitle="homeData.growthLogTrends.time" :ChartSevName="homeData.growthLogTrends.configName" :ChartData="homeData.growthLogTrends.score" :ChartID="'chartLineDiv'"/>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>


    <div class="divItemCont clearfix">
      <el-row :gutter="20">
        <el-col :span="24" v-for="(item,index) in itemData" :key="index">
          <div class="divBgBox" v-if="item.isData">
            <div class="boxCont">
              <div class="boxTitle">优秀学生排行</div>
              <div class="stuLeaderboardCont clearfix">
                <div class="divLeft" v-for="(arr,index2) in item.configDatas" :key="index2">
                  <div class="divLBTitle">{{arr.configName}}</div>
                  <div class="divLBCont">
                    <div class="divItemList" v-for="(list,index3) in arr.scoreData" :key="index3">
                      <div class="divAvrCont">
                        <comAvatar :src="list.photo" :gender="list.gender" :ref="list.studentNo" />
                      </div>
                      <div class="divInfo">
                        <div class="divName">{{list.studentName}}</div>
                        <div class="divClass">{{list.class}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="divRightLine "></div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>



  </div>
</template>

<script>

  import comChartPieDiv from '@/components/Admin/Echarts/ChartPie_div.vue';
  import comAvatar from '@/components/Avatar.vue';
  import comChartLineDiv from '@/components/Admin/Echarts/ChartLine_Div.vue';


  export default{
    components: {
      comChartPieDiv,
      comAvatar,
      comChartLineDiv
    },
    data(){
      return{
        // 加载动画
        fullscreenLoading: false,
        // 首页加载数据
        homeData: [],
        // 颜色数组
        optColor: ['#7E66F6', '#3191D9', '#4FBD76', '#F9BB4A', '#F50737',
          '#8CB93D', '#3AAF9D', '#4261E4', '#A75CE7', '#6610f2',
          '#e83e8c', '#fd7e14', '#28a745', '#17a2b8'
        ],
        // 时间Tabs
        dateCurrent: 1,
        // 优秀学生列表
        itemData: [],


        LiteracyArr:[],
        LiteracyTotal:0,
        pieChartWH:['90%', '100%'],
        LiteracyData: [
          {
            name: "品德优良",
            value: "27"
          },
        ],

        selClassValue:'',
        optSelClass:[],

        //优秀学生排行
        LeaderboardData:[],
      };
    },
    created() {

      let that=this;
      that.fullscreenLoading = true;
      ajaxGet(that,'/api/admin/sysuser/userfilter',null,function(resData){
        that.fullscreenLoading = false;
        that.optSelClass=resData;
        that.selClassValue=resData[0].classId;
        that.onGetData(that);
      });


    },
    mounted() {
      let that=this;

    },

    methods:{
      /**
       * @description 加载数据
       * @param {Object} that
       */
      onGetData(that) {
        that.fullscreenLoading = true;
        let tempData = {
          DateType: that.dateCurrent,
          ClassId:that.selClassValue
        }
        ajaxGet(that, '/api/admin/home/chartdata', tempData, function(resData) {
          that.homeData = resData;
          that.fullscreenLoading = false;
          // 优秀学生排行榜
          that.itemData = [];
          for (let item of resData.rankDatas) {
            that.itemData.push({
              name: item.name,
              configDatas: item.configDatas,
              // 用于判断是否
              isData: item.configDatas.every(function(list) {
                return list.scoreData > 0
              })
            })
          }

          that.LiteracyData=[];
          that.LiteracyTotal=0;
          for(let item of resData.growthLogSurveys){
            that.LiteracyData.push({
              name:item.name,
              value:item.quantity
            });
            that.LiteracyTotal+=item.quantity;
          }
          // 第一次加载统计图



          setTimeout(function() {
            that.$refs.chartLineDiv.onGetRadar(
            that.homeData.growthLogTrends.score,
            that.homeData.growthLogTrends.configName,
            that.homeData.growthLogTrends.time,
            that.optColor);

            that.$refs.LiteracyPie.onGetRadar(that.LiteracyData);

            return false;
          }, 500);

        });
      },
      onChangeClass(val){
        let that = this;
        that.onGetData(that);
      },
      /**
       * @description 时间Tabs
       * @param {Object} valType
       */
      onTopTabs(valType) {
        let that = this;
        that.dateCurrent = valType;
        //that.onGetData(that);
      },
      onSchool(){
        let that = this;
        that.$router.push({
          path: "/Admin/Home/Index",
        });
      },
      // 百分比
        onGetPct(objTotal,objValue){
          return parseFloat((parseFloat(objValue)/parseInt(objTotal))*100).toFixed(2);
        },


    },
    filters:{

      flColor(obj){
        return 'color:'+obj+';';
      }
    }
  };
</script>





<style scoped="scoped">
  .divSubTitleCont .divSelClass{
    position: absolute;
    top: 0px;
    right: 0;
  }





  .divBgBox{
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2);
    border-radius: 5px;
  }
  .divTrendSearchCont{
    position: absolute;
    top: -0.3125rem;/*-5px*/
    left: 13.75rem;/*220px*/
    background-color: #F2F4F6;
    height: 2.5rem;/*40px*/
    width: 15.625rem;/*250px*/
    border-radius: 0.25rem;/*4px*/
    overflow: hidden;
  }
  .divTrendSearchCont .txtTrendSearch{
    height: 2.5rem;/*40px*/
    width: 100%;
    font-size: 0.875rem;/*14px*/
    padding-left: 0.9375rem;/*15px*/
    padding-right: 3.4375rem;/*55px*/
    background-color: transparent;
    border:none;
  }
  .divTrendSearchCont .abtnTrendSearch{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2.5rem;/*40px*/
    cursor: pointer;
    color: #000000;
    font-size: 1.375rem;/*22px*/
  }


  .divChartTrendCont{
    padding-top: 0.9375rem;/*15px*/
  }

  .divGenderTab .aTab{
    color: #B2B2B2;
    margin-left: 1.875rem;/*30px*/
    padding-bottom: 0.3125rem;/*5px*/
  }
  .divGenderTab .aAct{
    color: #3D7DFF;
    border-bottom: 0.125rem solid #3D7DFF;/*2px*/
  }

  .divTabTrend{
    padding-top: 1.25rem;/*20px*/
  }
  .divTabTrend .aTab:first-child{
    margin-left: 0rem;
  }
  .divTabTrend .aTab{
    font-size: 0.875rem;/*14px*/
  }

  .pieGenderCont{
    float: right;
    width: 50%;
  }
  .genderFontInfo{
    float: left;
    width: 50%;
    padding-left: 15%;
  }
  .genderFontInfo .divGenderList{
    padding-top: 0.9375rem;/*15px*/
    padding-bottom: 0.625rem;/*10px*/
  }
  .genderFontInfo .divGenderList .divPctNum{
    font-family: 'OswaldLight';
    font-size: 3.25rem;/*52px*/
  }
  .genderFontInfo .divGenderList .divGenderInfo{
    font-size: 1.25rem;/*20px*/
    color: #4C4C51;
  }

  .stuLeaderboardCont{
    padding-top: 1.875rem;/*30px*/
    padding-left: 1.875rem;/*30px*/
    padding-right: 1.875rem;/*30px*/
  }
  .stuLeaderboardCont .divLeft{
    float: left;
    width: 20%;
    position: relative;
  }
  .stuLeaderboardCont .divRightLine{
    position: absolute;
    top: 3.75rem;/*60px*/
    right: 0.625rem;/*10px*/
    bottom: 3%;
    border-right:0.0625rem solid #E9E9E9;/*1px*/
  }

  .stuLeaderboardCont .divLeft:last-child{
    padding-left: 4.375rem;/*70px*/
  }
  .stuLeaderboardCont .divLBTitle{
    font-size: 1.375rem;/*22px*/
    font-family: Arial, Helvetica, sans-serif;
    color: #B0AFAF;
    letter-spacing: 0.125rem;/*2px*/
  }
  .stuLeaderboardCont .divLBCont{
    padding-top: 1.25rem;/*20px*/
  }
  .stuLeaderboardCont .divItemList{
    position: relative;
    height: 4.6875rem;/*75px*/
    padding-top: 0.3125rem;/*5px*/
  }
  .stuLeaderboardCont .divItemList .divAvrCont{
    position: absolute;
    top: 0.9375rem;/*15px*/
    bottom: 0;
    left: 0.625rem;/*10px*/
  }
  .stuLeaderboardCont .divItemList .divInfo{
    padding-left: 4.6875rem;/*75px*/
  }
  .stuLeaderboardCont .divItemList .divInfo .divName{
    color: #292929;
    padding-top: 0.75rem;/*12px*/
  }
  .stuLeaderboardCont .divItemList .divInfo .divClass{
    color: #AEAEAE;
    font-size: 0.875rem;/*14px*/
    padding-top: 0.3125rem;/*5px*/
  }
  .stuLeaderboardCont .divItemList .divInfo .spOther{
    font-size: 0.75rem;/*12px*/
    background-color: #FEE9E5;
    color: #EB6D7B;
    padding: 0.125rem 0.3125rem;/*2px 5px*/
  }




  .barAwardsCont{
    padding-top: 1.875rem;/*30px*/
  }
  .awardsLabelCont{
    position: absolute;
    top: -1.25rem;/*-20px*/
    right: 0;
    border-top:0.0625rem solid #F7F7F7 ;/*1px*/
    padding-top: 1.25rem;/*20px*/
  }
  .awardsLabelCont .divItemList{
    float: left;
    padding-right: 1.875rem;/*30px*/
    position: relative;
    padding-left: 1.875rem;/*30px*/
  }
  .awardsLabelCont .divItemList .spIcon{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2rem;/*32px*/
  }

  .awardsLabelCont .divItemList:nth-child(1) .icon{color: #7E66F6; }
  .awardsLabelCont .divItemList:nth-child(2) .icon{color: #E9E9E9;  }
  .divBgBox .boxTitle{
    position: relative;
  }
  .divBgBox .boxTitle .aTab{
    font-size: 1.375rem;/*22px*/
    letter-spacing: 0.0625rem;/*1px*/
    color: #B2B2B2;
    padding-left: 2.5rem;/*40px*/
    padding-bottom: 0.3125rem;/*5px*/
  }
  .divBgBox .boxTitle .aTab:first-child{
    padding-left: 0rem;/*0px*/
  }
  .divBgBox .boxTitle .aAct{
    color: #4C4C51;
    border-bottom: 0.125rem solid #3D7DFF;/*2px*/

  }


  .divTeachLabelCont{
    border-top:0.0625rem solid #F7F7F7;/*1px*/
    padding-top: 1.25rem;/*20px*/
  }
  .divTeachLabelCont .divItemList{
    width: 33%;
    float: left;
    padding-right: 1.875rem;/*30px*/
    position: relative;
    padding-left: 1.875rem;/*30px*/
  }
  .divTeachLabelCont .divItemList .spIcon{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2rem;/*32px*/
  }

  .divTeachLabelCont .divItemList:nth-child(1) .icon{color: #73CF43; }
  .divTeachLabelCont .divItemList:nth-child(2) .icon{color: #C0DBE9;  }
  .divTeachLabelCont .divItemList:nth-child(3) .icon{color: #FF2758;  }

  .headTeacherCont{
    padding-top: 1.875rem;/*30px*/
  }
  .headTeacherCont .teacherList{
    padding-bottom: 1.875rem;/*30px*/
    padding-right: 0.9375rem;/*15px*/
    padding-left: 0.9375rem;/*15px*/
  }
  .headTeacherCont .teacherList .divInfo{
    float: left;
    width: 40%;
  }
  .headTeacherCont .teacherList .divInfo .divAvatar{
    height: 3.125rem;/*50px*/
    width:3.125rem;/*50px*/
    float: left;
  }
  .headTeacherCont .teacherList .divInfo .divName{
    height: 3.125rem;/*50px*/
    line-height: 3.125rem;/*50px*/
    font-size: 1.125rem;/*18px*/
    font-weight: bold;
    padding-left: 1.875rem;/*30px*/
    float: left;
  }
  .headTeacherCont .teacherList .divValue{

    float: left;
    width: 60%;
    padding-top: 1.25rem;/*20px*/
  }
  .headTeacherCont .teacherList  .divValue .progressBar{
     height: 0.625rem;/*10px*/
     border-radius: 0.625rem;/*10px*/
     overflow: hidden;
     width: 90%;
  }
  .headTeacherCont .teacherList .divValue .progressBar .activeBar{
    float: left;
    background-color: #73CF43;
    width: 50%;
    height: 0.625rem;/*10px*/
  }
  .headTeacherCont .teacherList .divValue .progressBar .studentBar{
    float: left;
    height: 0.625rem;/*10px*/
    width: 25%;
    background-color: #C0DBE9;
  }
  .headTeacherCont .teacherList .divValue .progressBar .specialBar{
    float: left;
    height: 0.625rem;/*10px*/
    width: 25%;
    background-color: #FF2758;
  }
  .LeftRadius{
    border-radius: 0.625rem 0rem 0rem 0.625rem;/*10px 0px 0px 10px*/
  }
  .RightRadius{
    border-radius: 0rem 0.625rem 0.625rem 0rem;/*0px 10px 10px 0px*/
  }


  .divQuantityCont{
    padding-top: 1.875rem;/*30px*/
  }
  .divQuantityCont .divItemList{
    position: relative;
    padding-bottom: 1.5625rem;/*25px*/
  }
  .divQuantityCont .divItemList .divItemName{
    color: #4C4C51;
    font-size: 1.125rem;/*18px*/
  }
  .divQuantityCont .divItemList .divSchedule{
    padding-top: 0.625rem;/*10px*/
    width: 100%;
  }
  .divQuantityCont .divItemList .divSchedule .spLine{
    border-radius: 0.625rem;/*10px*/
    display: block;
    height: 0.3125rem;/*5px*/
    width: 100%;
    background-color: #3D7DFF;
  }
  .divQuantityCont .divItemList .divNum{
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    top: 0;
    right: 0;
    color: #606064;
    font-size: 1.25rem;/*20px*/
  }


  .divDivideBox .divLabelCont{
    padding-top: 1.25rem;/*20px*/
    display: flex;
  }
  .divDivideBox .divLabelCont .divItemList{

    padding-right: 1.875rem;/*30px*/
    position: relative;
    padding-left: 1.875rem;/*30px*/
    display: flex;
  }
  .divDivideBox .divLabelCont .divItemList .spIcon{
    margin-right: 0.3125rem;
  }

  .divDivideLabel{
    padding-top: 1.5625rem;/*25px*/
  }
  .divDivideLabel .divLabelList{
    float: left;
    width: 49.9%;
    position: relative;
    margin-bottom: 0.625rem;/*10px*/
    padding-left: 1.875rem;/*30px*/
    color: #939393;
    font-size: 0.875rem;/*14px*/
  }
  .divDivideLabel .divLabelList .spIcon{
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 2rem;/*32px*/
  }
  .divDivideLabel .divLabelList .spPic{
    font-family: Arial, Helvetica, sans-serif;
    color: #4C4C51;
    padding-right: 0.3125rem;/*5px*/
  }

  .divDivideBox .divChartBox{
    padding: 0rem 1.25rem 0rem 0.625rem;/*0px 20px 0px 10px*/
  }

  .divItemCont .divLeft75{
    float: left;
    width: 75.3%;
  }
  .divItemCont .divLeft25{
    float: left;
    width: 24.7%;
  }



  .divItemCont{
    padding-top: 1.25rem;/*20px*/
  }
  .divItemCont .divRight50,
  .divItemCont .divLeft50{
    float: left;
    width: 50%;
  }
  .divItemCont .divLeft50{ padding-right: 0.625rem;/*10px*/}
  .divItemCont .divLeft50:last-child{
		padding-left: 0.625rem; /*10px*/
		padding-right: 0rem;
	}
  .divItemCont .divRight50{
		padding-left: 0.625rem;/*10px*/
	}


  .divItemCont .divRight50 .divLabelList{
    float: left;
    width: 50%;
  }
  .divItemCont .divRight50 .divLabelList:nth-child(1){
    padding-bottom: 0.625rem;/*10px*/
    padding-right: 0.625rem;/*10px*/
  }
  .divItemCont .divRight50 .divLabelList:nth-child(2){
    padding-bottom: 0.625rem;/*10px*/
    padding-left: 0.625rem;/*10px*/
  }
  .divItemCont .divRight50 .divLabelList:nth-child(3){
    padding-top: 0.625rem;/*10px*/
    padding-right: 0.625rem;/*10px*/
  }
  .divItemCont .divRight50 .divLabelList:nth-child(4){
    padding-top: 0.625rem;/*10px*/
    padding-left: 0.625rem;/*10px*/
  }
  .divItemCont .divRight50 .divLabelList .divBgBox{
    height: 14.6875rem;/*235px*/
  }
  .divItemCont .divRight50 .divLabelList .divLable{
    padding-right: 1.875rem;/*30px*/
  }
  .divItemCont .divRight50 .divLabelList .divLable .spLabel{
    font-size: 1.125rem;/*18px*/
    background-color: #363636;
    color: #FFFFFF;
    border-radius: 0.25rem;/*4px*/
    padding: 0.3125rem 0.625rem;/*5px 10px*/
    letter-spacing: 0.0625rem;/*1px*/
  }
  .divItemCont .divRight50 .divLabelList .divInfo{}
  .divItemCont .divRight50 .divLabelList .divInfo .divScores{
    font-family: 'OswaldLight';
    font-size: 3.25rem;/*52px*/
    letter-spacing: 0.125rem;/*2px*/
    color: #606064;
  }
  .divItemCont .divRight50 .divLabelList .divInfo .spFont{
    font-size: 1.125rem;/*18px*/
    padding-left: 0.9375rem;/*15px*/
    color: #A9A9A9;
  }
  .divItemCont .divRight50 .divLabelList .divInfo .divNumber{
    color: #A9A9A9;
    letter-spacing: 0.0625rem;/*1px*/
  }
  .divItemCont .divRight50 .divLabelList .divPct{
    padding-left: 1.875rem;/*30px*/
    font-family: 'OswaldLight';
    font-size: 1.625rem;/*26px*/
    letter-spacing: 0.125rem;/*2px*/
  }

  .divBoxHeight{
		height: 30.625rem;/*490px*/
	}
  .divLiteracyBox{
		height: 30.625rem;/*490px*/
	}
  .divBgBox .boxCont{
    padding: 0.9375rem;/*15px*/
  }
  .divBgBox .boxTitle{
    font-size: 1.375rem;/*22px*/
    letter-spacing: 0.0625rem;/*1px*/
    color: #4C4C51;
  }
  .divBgBox .boxTitle .spSmall{
    color: #B2B2B2;
    padding-left: 1.875rem;/*30px*/
  }
  .divBgBox .boxCont .divPieCont{
    padding-top: 3.75rem;/*60px*/
    padding-bottom: 3.75rem;/*60px*/
  }

  .divBgBox .boxCont .picBox{
    text-align: center;
    position: relative
  }
  .divBgBox .boxCont .picBox50{
    float: left;
    width: 49.9%;
    }

  .divBgBox .boxCont .picBox .divTotalCont{
    position: absolute;
    left: 0;
    right: 0;
    top: 4.375rem;/*70px*/
  }
  .divBgBox .boxCont .picBox .divTotalCont .spNum{
    display: block;
    font-family: 'OswaldLight';
    font-size: 2.875rem;/*46px*/
    letter-spacing: 0.125rem;/*2px*/
  }
  .divBgBox .boxCont .picBox .divTotalCont .spFont{
    display: block;
    color: #939393;
    letter-spacing: 0.0625rem;/*1px*/
  }
  .divBgBox .boxCont .picInfo{
    float: left;
    width: 49.9%;
  }
  .divBgBox .boxCont .picInfo  .divInfoItem{
    padding-top: 1.875rem;/*30px*/
    padding-left: 3.75rem;/*60px*/
  }
  .divBgBox .boxCont .picInfo  .divItemList{
    position: relative;
    padding-top: 0.625rem;/*10px*/
    padding-bottom: 0.625rem;/*10px*/
    padding-left: 1.875rem;/*30px*/
  }
  .divBgBox .boxCont .picInfo  .divItemList .spIcon{
    position: absolute;
    top: 0.625rem;/*10px*/
    left: 0;
  }

  .divBgBox .boxCont .picInfo  .divItemList .spPct{
    color: #4C4C51;
    font-size: 1.125rem;/*18px*/
    font-family: Arial, Helvetica, sans-serif;
  }
  .divBgBox .boxCont .picInfo  .divItemList .spLabel{
    padding-left: 0.625rem;/*10px*/
    color: #939393;
  }
  .divBgBox .boxCont .picInfo  .divItemList .spNum{
    color: #939393;
  }


  .divLabelItem{
    padding: 1.875rem;/*30px*/
  }
  .divLabelItem .divItemList{
    float: left;
    width: 24.9%;
    position: relative;
  }
  .divLabelItem .divItemList .divNum{
    text-align: center;
    font-size: 2.875rem;/*46px*/
    letter-spacing: 0.0625rem;/*1px*/
    font-family: 'OswaldLight';
  }
  .divLabelItem .divItemList .divNum .spNumCont{
    font-family: 'OswaldLight';
    font-size: 1.625rem;/*26px*/
    color: #7B7B7B;
  }
  .divLabelItem .divItemList .divFont{
    text-align: center;
    color: #939393;
    letter-spacing: 0.0625rem;/*1px*/
  }
  .divLabelItem .divItemList .divRightLine{
    position: absolute;
    top: 0.625rem;/*10px*/
    right: 0;
    bottom:  0.625rem;/*10px*/
    border-right: 0.0625rem solid #E9E9E9;/*1px*/
  }
  .divLabelItem .divItemList:last-child .divRightLine{
    display: none;
  }


  .divSubTitleCont{
    position: relative;
    padding-bottom: 2.5rem;/*40px*/
  }
  .divSubTitleCont .abtnExport{
    position: absolute;
    top: 0;
    right: 0;
    background-color: #10A0FE;
    color: #FFFFFF;
    width: 8.75rem;/*140px*/
    border-radius: 0.25rem;/*4px*/
    text-align: center;
    font-size: 1.125rem;/*18px*/
    letter-spacing: 0.125rem;/*2px*/
    height:2.8125rem;/*45px*/
    line-height:2.8125rem;/*45px*/
  }
  .divSubTitleCont .abtnExport i{
    font-size: 1.375rem;/*22px*/
  }
  .divTabCont .aTabList{
    border-right: 0.0625rem solid #BCBCBC;/*1px*/
    padding-right: 1.875rem;/*30px*/
    padding-left: 1.875rem;/*30px*/
    cursor: pointer;
  }
  .divTabCont .aTabList:last-child{
    border-right: none;
  }
  .divTabCont .aTabList:first-child{
    padding-left: 0;
  }
  .divTabCont .aTabList span{
    font-size: 1.125rem;/*18px*/
    letter-spacing: 0.0625rem;/*1px*/
    color: #939393;
  }
  .divTabCont .aTabAct span{
    padding-bottom: 0.1875rem;/*3px*/
    color: #3D7DFF;
    border-bottom: 0.1875rem solid #3D7DFF;/*3px*/
  }


  .divBgBox .boxCont .pieDivideBox{
    padding-top:2.5rem;/*40px*/
  }
  .divBgBox .boxCont .pieDivideBox .divTotalCont{
    top: 6.25rem;/*100px*/
  }

</style>

<style scoped="scoped">
  /*当屏幕尺寸小于1680px时，应用下面的CSS样式*/
  @media screen and (max-width: 1680px) {
    .divItemCont .divRight50 .divLabelList .divLable{
      padding-right: 0.9375rem;/*15px*/
    }
    .divDivideLabel .divLabelList{
      width: 100%;
    }
    .divDivideLabel .divLabelList .spPic{
      font-size: 0.875rem;/*14px*/
    }
    .divDivideLabel .divLabelList{
      font-size: 0.75rem;/*12px*/
    }
    .stuLeaderboardCont .divItemList .divInfo .divClass{
      font-size: 0.75rem !important;/*12px*/
    }
  }
  /*当屏幕尺寸小于1600px时，应用下面的CSS样式*/
  @media screen and (max-width: 1600px) {
    .divBgBox .boxTitle{
      font-size: 1.125rem !important;/*18px*/
    }
    .divBgBox .boxCont .picInfo .divItemList .spPct,
    .divBgBox .boxCont .picInfo .divItemList .spLabel,
    .divBgBox .boxCont .picInfo .divItemList .spNum,
    .awardsLabelCont .divItemList,
    .divDivideBox .divLabelCont .divItemList,
    .divItemCont .divRight50 .divLabelList .divLable .spLabel{
      font-size: 0.875rem;/*14px*/
    }
    .divItemCont .divRight50 .divLabelList .divInfo .divScores{
      font-size: 2.25rem;/*36px*/
    }
    .divItemCont .divRight50 .divLabelList .divPct{
      padding-left: 0.625rem;/*10px*/
    }
    .divBgBox .boxTitle .aTab{
      font-size: 1.125rem;/*18px*/
    }
  }
  /*当屏幕尺寸小于1440px时，应用下面的CSS样式*/
  @media screen and (max-width: 1440px) {
    .divBgBox .boxCont .picInfo .divInfoItem{
      padding-left: 1.5625rem;/*25px*/
    }
    .divItemCont .divRight50 .divLabelList .divPct{
      font-size: 1.125rem;/*18px*/
    }
    .divItemCont .divRight50 .divLabelList .divInfo .divNumber{
      font-size: 0.875rem;/*14px*/
    }
    .divSubTitleCont .abtnExport i,
    .divTabCont .aTabList span{
      font-size: 1rem;/*16px*/
    }
    .SubWebPage .divWebTitle{
      font-size: 1.5rem;/*24px*/
    }

    .divSubTitleCont .abtnExport{
      font-size: 0.875rem;/*14px*/
      height: 2.1875rem;/*35px*/
      line-height: 2.1875rem;/*35px*/
      width: 7.5rem;/*120*/
    }
    .divBgBox .boxTitle .spSmall{
      font-size: 0.875rem;/*14px*/
      padding-left: 0.9375rem;/*15px*/
    }
    .stuLeaderboardCont .divLeft:last-child{
      padding-left: 0;
    }
    .stuLeaderboardCont .divRightLine{
      display: none;
    }
  }
  /*当屏幕尺寸小于1400px时，应用下面的CSS样式*/
  @media screen and (max-width: 1400px) {
    .divBgBox .boxTitle .spSmall,
    .divBgBox .boxCont .picInfo .divInfoItem{
      padding-top: 0.625rem;/*10px*/
    }
    .divTrendSearchCont .abtnTrendSearch i,
    .divGenderTab .aTab,
    .divTeachLabelCont .divItemList{
      font-size: 0.875rem;/*14px*/
    }
    .stuLeaderboardCont{
      padding-left: 0rem;
      padding-right: 0rem;
    }
    .divTrendSearchCont{
      height: 1.875rem;/*30px*/
    }
    .divTrendSearchCont .txtTrendSearch{
      height: 1.875rem;/*30px*/
      font-size: 0.75rem;/*12px*/
    }
    .genderFontInfo{
      padding-left: 10%;
      padding-top: 2%;
    }
    .genderFontInfo .divGenderList .divPctNum{
      font-size: 2.625rem;/*42px*/
    }
    .genderFontInfo .divGenderList .divGenderInfo{
      font-size: 1rem;/*16px*/
    }
  }
  /*当屏幕尺寸小于1366px时，应用下面的CSS样式*/
  @media screen and (max-width: 1366px) {
    .divItemCont .divRight50 .divLabelList .divInfo .divScores{
      font-size: 1.875rem;/*30px*/
    }
    .divBgBox .boxTitle .spSmall{
      font-size: 0.75rem;/*12px*/
      padding-left: 0.3125rem;/*5px*/
    }
    .divBgBox .boxTitle .aTab{
      font-size: 1rem;/*16px*/
      padding-left: 0.9375rem;/*15px*/
    }
    .divLiteracyBox .boxCont .picInfo .divInfoItem{
      padding-top: 3.125rem;/*50px*/
    }
  }
  /*当屏幕尺寸小于1280px时，应用下面的CSS样式*/
  @media screen and (max-width: 1280px) {
   .awardsLabelCont .divItemList{
      padding-right: 0.3125rem;/*5px*/
      font-size:0.75rem;/*12px*/
    }
    .divGenderTab .aTab{
      margin-left: 0;
      margin-right: 0.9375rem;/*15px*/
    }
    .divDivideBox .divLabelCont .divItemList,
    .divItemCont .divRight50 .divLabelList .divLable .spLabel,
    .divTeachLabelCont .divItemList{
      font-size: 0.75rem;/*12px*/
    }
    .divItemCont .divRight50 .divLabelList .divInfo .divScores{
      font-size: 1.625rem;/*26px*/
    }
    .divItemCont .divRight50 .divLabelList .divInfo .spFont{
      font-size: 0.75rem;/*12px*/
      padding-left: 0.3125rem;/*5px*/
    }
  }
  /*
        1280*
        1360*
        1366*
        1400*
        1440*
        1600*
        1680*
        1920
        2048
  */
</style>
