<!--
  新增流程活动
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}{{currentName}}
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :sm="24">
              <el-form-item label="活动对象">
                <el-select style="width: 100% !important;" v-model="ruleForm.joinType" placeholder="请选择"
                  :disabled="ruleForm.activityId?true:false" class="formControl" @change="handelActivityJoinType">
                  <el-option v-for="(item, joinTypeIndex) in optJoinType" :key="joinTypeIndex" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :sm="24" v-if="isField.isName">
              <el-form-item label="活动名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :sm="24" v-if="isField.isPlace">
              <el-form-item label="活动地点" prop="place">
                <el-input v-model="ruleForm.place" placeholder="请输入" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :sm="24" v-if="isField.isTime">
              <el-form-item label="活动时间" prop="activityTime">
                <el-date-picker style="width: 100% !important;" v-model="ruleForm.activityTime" type="datetimerange"
                  :picker-options="pickerOptions2" format="yyyy-MM-dd HH:mm" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" align="right" class="formControl">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :sm="24" v-if="isField.isLevel">
              <el-form-item label="活动类型" prop="levelId">
                <el-select style="width: 100% !important;" v-model="ruleForm.levelId" placeholder="请选择"
                  class="formControl" @change="handelActivityLevel">
                  <el-option v-for="item in optLevels" :key="item.levelId" :label="item.name" :value="item.levelId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :sm="24" v-if="isShowClass">
              <el-form-item label="班级">
                <el-select v-model="ruleForm.classId" placeholder="请选择" class="width_100Pie" filterable
                @change="onChangeClass">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.name" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :sm="24" v-if="isField.isOrganization">
              <el-form-item label="组织部门" prop="">
                <el-select :clearable="regNull(ruleForm.activityId)" style="width: 100% !important;"
                  v-model="ruleForm.organizationId" placeholder="请选择" class="formControl">
                  <el-option v-for="item in organizations" :key="item.organizationId" :label="item.name"
                    :value="item.organizationId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :sm="24" v-if="isField.isReviewer">
              <el-form-item label="审核人员">
                <el-select multiple collapse-tags v-model="ruleForm.arrAuditors" placeholder="请选择" class="width_100Pie"
                  @remove-tag="onRemoveTag">
                  <el-option v-for="item in optReviewer" filterable multiple collapse-tags :key="item.reviewerId"
                    :label="item.name" :value="item.reviewerId" :disabled="item.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="6" :sm="12" v-if="isField.isActivityRegister == true && ruleForm.joinType == 1">
              <el-form-item label="是否需要学生报名" label-width="150px">
                <el-checkbox v-model="ruleForm.isApply" class="formControl" @change="onApply"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :lg="6" :sm="12" v-if="ruleForm.joinType == 1">
              <el-form-item label="是否需要学生签到" label-width="150px">
                <el-checkbox v-model="ruleForm.isAttendance" class="formControl"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :lg="6" :sm="12"
              v-if="isField.isActivityUpload == true && (ruleForm.isApply || ruleForm.isAttendance) && ruleForm.joinType == 1">
              <el-form-item label="是否需要学生上传文件" label-width="180px">
                <el-checkbox v-model="ruleForm.isUpload" class="formControl"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :lg="6" :sm="12" v-if="ruleForm.joinType == 1">
              <el-form-item label="学生默认报名角色" label-width="150px" prop="roleId">
                <el-select v-model="ruleForm.roleId" placeholder="请选择学生默认报名角色" class="width_100Pie">
                  <el-option v-for="item in optRole" :key="item.roleId" :label="item.name" :value="item.roleId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!--学生报名设置-->
      <el-card class="box-card" v-if="ruleForm.isApply == true && ruleForm.joinType == 1">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学生报名设置
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="报名时间" prop="applyTime">
                <el-date-picker style="width: 100% !important;" v-model="ruleForm.applyTime" type="datetimerange"
                  :picker-options="pickerOptions" format="yyyy-MM-dd HH:mm" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期" align="right" class="formControl">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="报名人数限制" v-if="TeacherInfo.isTeacher != true&&regNull(ruleForm.classId)">
                <el-input v-model="ruleForm.numberLimit" placeholder="不填写为不限制人数" class="formControl"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="是否需要报名审核" label-width="150px">
                <el-checkbox v-model="ruleForm.isApplyAudit" class="formControl"></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="班主任代报名" label-width="100px" v-if="TeacherInfo.isTeacher != true&&regNull(ruleForm.classId)">
                <el-checkbox v-model="ruleForm.isDelegate" class="formControl"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!--学生上传设置-->
      <el-card class="box-card" v-if="ruleForm.isUpload == true && ruleForm.joinType == 1">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            学生上传设置
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="上传最后截止时间" label-width="160px" prop="uploadDeadline">
                <el-date-picker style="width: 100% !important;" v-model="ruleForm.uploadDeadline" type="datetime"
                  placeholder="请选择时间" class="formControl">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否允许学生互看上传记录" label-width="240px">
                <el-checkbox v-model="ruleForm.isView" class="formControl"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <el-card class="box-card" v-if="ruleForm.isApply && isShowClass">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            报名范围
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-input placeholder="输入关键字进行过滤" v-model="filterTextSignUp">
              </el-input>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="EqualDiv pt-2">
            <el-col :span="24">
              <el-col :span="10" class="divTreeCont" style="height: 300px; overflow-y: auto;">
                <el-tree  :filter-node-method="filterNodeSignUp"
                  ref="treeSignUp" default-expand-all :data="signUpStudents" :props="defaultProps" show-checkbox
                  node-key="id" @check-change="handleCheckStudentSignUp" :default-checked-keys="defaultcheckedStudentSignUp" ></el-tree>
              </el-col>
              <el-col :span="4" class="VerticalMid" style="padding-top: 120px;">
                <svg class="icon iconBox" aria-hidden="true">
                  <use xlink:href="#fax-arrow-left-right-alt"></use>
                </svg>
              </el-col>
              <el-col :span="10" class="divTreeCont" style="height: 300px; overflow-y: auto;">
                <el-tree default-expand-all :data="optAllStudent" :props="defaultProps" node-key="id">
                </el-tree>
              </el-col>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <!--报名范围-->
      <el-card class="box-card" v-if="ruleForm.isApply && !isShowClass">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            报名范围
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-input placeholder="输入关键字进行过滤" v-model="filterTextSignUp">
              </el-input>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="EqualDiv pt-2">
            <el-col :span="24">
              <el-col :span="10" class="divTreeCont" style="height: 300px; overflow-y: auto;">
                <el-tree :default-checked-keys="defaultcheckedRangesSignUp" :filter-node-method="filterNodeSignUp"
                  ref="treeSignUp" default-expand-all :data="signUpColleges" :props="defaultProps" show-checkbox
                  node-key="id" @check-change="handleCheckChangeSignUp" @check="handleCurrentChange"></el-tree>
              </el-col>
              <el-col :span="4" class="VerticalMid" style="padding-top: 120px;">
                <svg class="icon iconBox" aria-hidden="true">
                  <use xlink:href="#fax-arrow-left-right-alt"></use>
                </svg>
              </el-col>
              <el-col :span="10" class="divTreeCont" style="height: 300px; overflow-y: auto;">
                <el-tree default-expand-all :data="selectTreeSigeUpRanges" :props="defaultProps" node-key="id">
                </el-tree>
              </el-col>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!--宣传图片-->
      <el-card class="box-card" v-if="isField.isActivityPhoto">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            宣传图片
          </h3>
        </div>
        <div class="card-body p-5">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>

      <!--活动图片-->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            活动图片
          </h3>
        </div>
        <div class="card-body p-5">
          <comUpload :key="random2" @onPictureHandleSuccess="onPhotoHandleSuccess" :files="uploadPhotoFiles"
            :pictureMaxCount="30"></comUpload>
        </div>
      </el-card>

      <!--活动内容详情-->
      <el-card class="box-card" v-if="isField.isActivityContent">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            活动内容
          </h3>
        </div>
        <div class="card-body">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item prop="code" label=" " label-width="0px">
                <!-- <el-input type="textarea" v-model="ruleForm.description" :autosize="{ minRows: 5}" maxlength="500"
                  show-word-limit></el-input> -->
                <quill-editor height="300px" v-model="ruleForm.description" ref="text" class="myQuillEditor"
                  :options="editorOption" />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
        </div>
      </div>

    </el-form>



  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import moment from 'moment' //moment.js
  import comUpload from '@/components/Upload.vue';
  import {
    quillEditor
  } from 'vue-quill-editor';
  import * as Quill from 'quill' //引入编辑器
  var fonts = ['SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial', 'Times-New-Roman', 'sans-serif'];
  var Font = Quill.import('formats/font');
  Font.whitelist = fonts; //将字体加入到白名单
  Quill.register(Font, true);

  export default {
    components: {
      comUpload,
      quillEditor
    },
    data() {

      return {
        // 默认角色
        optRole: [],
        // 报名人数限制提示语
        numberLimitTitle: '',
        isShowClass: false,
        optClass: [],
        pickerOptions: {
          // 报名结束时间要小于活动开始时间
          disabledDate: time => {
            if (this.ruleForm.activityTime && this.ruleForm.activityTime.length > 0) {
              return time.getTime() > moment(this.ruleForm.activityTime[0]).format("x");
            }
          },
        },
        pickerOptions2: {
          // 活动开始时间要大于报名结束时间
          disabledDate: time => {
            if (this.ruleForm.applyTime && this.ruleForm.applyTime.length > 0) {
              return time.getTime() < moment(this.ruleForm.applyTime[1]).format("x");
            }
          },
        },
        //富文本框
        editorOption: {
          placeholder: '请在此输入内容',
          modules: {
            toolbar: [
              [{
                'font': fonts
              }], //字体，把上面定义的字体数组放进来
              ['bold', 'italic', 'underline',
                'strike'
              ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
              ['blockquote', 'code-block'], // 引用  代码块-----['blockquote', 'code-block']
              [{
                header: 1
              }, {
                header: 2
              }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
              [{
                list: 'ordered'
              }, {
                list: 'bullet'
              }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
              [{
                script: 'sub'
              }, {
                script: 'super'
              }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
              [{
                indent: '-1'
              }, {
                indent: '+1'
              }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
              [{
                'direction': 'rtl'
              }], // 文本方向-----[{'direction': 'rtl'}]
              [{
                size: ['small', false, 'large', 'huge']
              }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
              [{
                header: [1, 2, 3, 4, 5, 6, false]
              }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
              [{
                color: []
              }, {
                background: []
              }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
              [{
                align: []
              }], // 对齐方式-----[{ align: [] }]
              ['clean'], // 清除文本格式-----['clean']
              ['image'], // 链接、图片、视频-----['link', 'image', 'video']
            ]
          }
        },
        // 加载动态
        fullscreenLoading: false,
        //报名范围树形默认勾选
        defaultcheckedRangesSignUp: [],
        //报名班级范围树形默认勾选
        defaultcheckedRangesSignUpClass: [],
        //参与范围树形默认勾选
        defaultcheckedRangesJoinIn: [],
        defaultcheckedStudentSignUp:[],
        random: 0, //刷新组件
        random2: 0,
        uploadFiles: [],
        uploadFilesReset: [],
        uploadPhotoFiles: [],
        uploadPhotoFilesReset: [],
        files: [],
        filterTextSignUp: '',
        filterTextSignUp2: '',
        filterTextJoinIn: '',
        ruleForm: {
          roleId: null, // 默认角色ID
          activityId: null, //活动主键
          name: null, //名称
          place: null, //地点
          typeId: null, //类型主键
          description: null, //描述
          levelId: null, //级别主键
          organizationId: null, //组织部门主键
          isApply: false, //是否需要报名
          isImport: true, //是否导入指定名单（报名范围）
          numberLimit: null, //人数限制
          isDelegate: false, //是否班主任代报名
          isAttendance: false, //是否签到
          isUpload: false, //学生是否可上传
          isApplyAudit: true, // 是否需要报名审核
          isView: false, //是否相互可见
          isPrize: true, //是否评奖
          applyStartDate: null, //报名开始时间
          applyEndDate: null, //报名结束时间
          startDate: null, //活动开始时间
          endDate: null, //活动结束时间
          attachment: null, //附件
          configs: [], //核心综合配置
          ranges: [], //报名范围
          activityTime: null, //活动时间
          applyTime: null, //报名时间
          uploadDeadline: null, //上传截止时间
          fileType: null, //学生上传文件类型
          fileTypes: [], //学生上传文件类型
          signUpRanges: [], //报名范围
          signUpClassRanges: [], //报名班级范围
          joinInRanges: [], //参与范围

          auditors: [], // 审核人员ID
          arrAuditors: [],
          classId: null,
          photo: null, //活动照片
          joinType: null,
          rosters: [],
        },
        ruleFormReset: {}, //重置
        Title: "新增",
        currentName: null, //当前活动类型名称
        currentConfig: [],
        optLevels: [], //活动级别集合


        organizations: [], //组织部门集合
        roles: [], //角色集合
        rolesReset: [], //角色集合重置
        activeName: null,
        colleges: [], //学生范围集合(树形结构)

        signUpColleges: [],
        signUpCollegesReset: [],

        signUpCollegesClass: [],
        signUpCollegesClassReset: [],

        joinInColleges: [],
        joinInCollegesReset: [],

        allColleges: [], //学生范围集合

        selectSigeUpRanges: [], //选中的学生范围集合
        selectTreeSigeUpRanges: [], //选中的学生范围集合（树形结构）
        selectTreeSigeUpRangesReset: [],

        selectSigeUpClassRanges: [], //选中的班级范围集合
        selectTreeSigeUpClassRanges: [], //选中的班级范围集合（树形结构）
        selectTreeSigeUpClassRangesReset: [],

        selectJoinInRanges: [], //选中的参与学生范围集合
        selectTreeJoinInRanges: [], //选中的参与学生范围集合（树形结构）
        selectTreeJoinInRangesReset: [],
        signUpStudents:[],
        selectTreeStudentUpRanges:[],
        defaultProps: {
          children: 'children',
          label: 'name'
        },

        optReviewer: [], // 审核人员
        isField: {
          //字段是否需要显示
          isName: false, //活动名称
          isPlace: false, //活动地点
          isLevel: false, //活动级别
          isOrganization: false, //组织部门
          isTime: false, //活动时间
          isActivityRegister: false, //活动报名
          isActivityUpload: false, //活动上传文件
          // isActivityGrowth: false, //活动综合
          isActivityPhoto: false, //活动宣传图片
          isActivityContent: false, //活动内容
          isReviewer: false, //审核人
        },
        rules: {
          numberLimit: [{
              required: true,
              message: '报名人数不能为空',
              trigger: 'blur'
            },
            {
              pattern: /^-?\d+\.?\d*$/g,
              message: '报名人数必须为数字值',
              trigger: 'blur'
            }
          ],
          name: [{
              required: true,
              message: '活动名称不能为空',
              trigger: 'change'
            },
            {
              max: 256,
              message: '活动名称不能超过256个字符',
              trigger: 'change'
            },
          ],
          levelId: [{
            required: true,
            message: '活动类型不能为空',
            trigger: 'change'
          }, ],
          roleId: [{
            required: true,
            message: '学生报名默认角色不能为空',
            trigger: 'change'
          }, ],
          place: [{
              required: true,
              message: '活动地点不能为空',
              trigger: 'change'
            },
            {
              max: 1024,
              message: '活动地点不能超过1024个字符',
              trigger: 'change'
            },
          ],
          applyTime: [{
            required: true,
            message: '报名时间不能为空',
            trigger: 'change'
          }, ],
          uploadDeadline: [{
            required: true,
            message: '上传最后截止时间不能为空',
            trigger: 'change'
          }, ],
          activityTime: [{
            required: true,
            message: '活动时间不能为空',
            trigger: 'change'
          }, ],
          organizationId: [{
            required: true,
            message: '组织部门不能为空',
            trigger: 'change'
          }, ]
        },

        //当前用户信息
        currentUserInfo: '',
        optJoinType: [{
          value: 1,
          label: "学生"
        }, {
          value: 2,
          label: "班级"
        }],

        TeacherInfo: {}, // 获取教师信息
        optAllClass: [],
        optAllStudent:[],//选择的学生
      };
    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that); //获取教师信息
      that.optAllClass = [];

      // 类型ID
      that.ruleForm.typeId = that.$route.query.type;
      // 活动对象默认值
      that.ruleForm.joinType = 1;





      // 获取活动类型配置
      ajaxGet(that, "/api/admin/activitytype/" + that.ruleForm.typeId, null, function(types) {
        // 获取学生默认报名角色
        ajaxGet(that, "/api/admin/activityrole", null, function(resRoles) {
          // 获取活动上传语文件类型枚举
          ajaxGet(that, "/api/admin/activity/filetype", null, function(files) {
            // 获取班级活动时，可选班级
            that.optClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that
              .TeacherInfo.allClass;
            if (!regNullArray(resRoles)) {
              that.optRole = resRoles.filter(item => item.deleteMark == false);
              that.ruleForm.roleId = resRoles[0].roleId;
            }
            that.files = files;



            // 设置报名范围
            if (!regNullArray(that.optClass)) {
              // 原班级范围
              that.signUpColleges = [{
                id: "00000000-0000-0000-0000-000000000000",
                name: "全选",
                collegeId: '00000000-0000-0000-0000-000000000000',
                collegeName: null,
                children: onGetJsonToTree([...that.onArrUnique(that.optClass.map(item => ({
                  id: item.collegeId,
                  name: item.college,
                  collegeId: item.collegeId,
                  collegeName: item.college,
                  parentId: "00000000-0000-0000-0000-000000000000",
                }))), ...that.optClass.map(item => ({
                  id: item.classId,
                  name: item.name,
                  collegeId: item.collegeId,
                  collegeName: item.college,
                  parentId: item.collegeId,
                }))], 'id', 'parentId', 'children'),
                parentId: null,
              }];


              // 判断是否为编辑
              if (!regNull(that.$route.params.id)) {
                that.Title = "编辑";
                that.ruleForm.activityId = that.$route.params.id;
                that.onGetData(that);
              } else {
                that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
              }
            }


        // 活动综合配置
        that.currentConfig = types.configs;
        // 类型名称
        that.currentName = types.name;
        // 获取配置字段
        for (var field of types.fieldConfigs) {
          switch (field.name) {
            case 1: // 活动名称
              that.isField.isName = true;
              break;
            case 2: // 活动地点
              that.isField.isPlace = true;
              break;
            case 3: // 活动类型
              that.isField.isLevel = true;
              that.onGetActivityLevel(that);
              break;
            case 4: // 组织部门
              that.isField.isOrganization = true;
              ajaxGet(that, "/api/admin/organization", null, function(organizations) {
                that.organizations = organizations;
              })
              break;
            case 5: // 活动时间
              that.isField.isTime = true;
              break;
            case 6: //是否需要学生报名
              that.isField.isActivityRegister = true;
              break;
            case 7: // 是否需要学生上传文件
              that.isField.isActivityUpload = true;
              break;
              // case 8:
              //   that.isField.isActivityGrowth = true;
              //   break;
            case 9: // 宣传图片
              that.isField.isActivityPhoto = true;
              break;
            case 10: // 活动内容
              that.isField.isActivityContent = true;
              break;
            case 11: // 审核人员
              that.isField.isReviewer = true;
              that.onGetUser(that);
              break;
          }
        }







        // 设置随机数
        that.random += Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random2 += Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        });
        });
      });
    },


    methods: {
      /**
       * @description 根据ID，获取活动详情数据
       * @param {Object} that
       */
      onGetData(that) {
        ajaxGet(that, "/api/admin/activity/" + that.ruleForm.activityId, null, function(resData) {
          if (!regNull(resData)) {
            // 是否签到
            that.ruleForm.isAttendance = resData.isAttendance;

            for (let key in that.ruleForm) {
              for (var it in resData) {
                if (key.toLowerCase() == it.toLowerCase()) {
                  that.ruleForm[key] = resData[it];
                }
              }
            }
            // 组织部门ID
            if (that.ruleForm.organizationId == "00000000-0000-0000-0000-000000000000") {
              that.ruleForm.organizationId = null;
            }

            // 上传截止时间
            if (that.ruleForm.uploadDeadline != null) {
              that.ruleForm.uploadDeadline = new Date(that.ruleForm.uploadDeadline);
            }

            // 活动审核人
            that.ruleForm.arrAuditors = [];
            for (let item of resData.auditors) {
              that.ruleForm.arrAuditors.push(item.teacherId);
            }

            // that.ruleForm.joinInRanges = [];
            // that.ruleForm.signUpRanges = [];
            // that.ruleForm.signUpClassRanges = [];

            // 报名时间
            that.ruleForm.applyTime = [];
            if (that.ruleForm.applyStartDate != null) {
              //报名开始时间
              that.ruleForm.applyTime.push(that.ruleForm.applyStartDate);
            }
            if (that.ruleForm.applyEndDate != null) {
              // 报名结束时间
              that.ruleForm.applyTime.push(that.ruleForm.applyEndDate);
            }

            // 活动时间
            that.ruleForm.activityTime = [];
            if (that.ruleForm.startDate != null) {
              // 活动开始时间
              that.ruleForm.activityTime.push(that.ruleForm.startDate);
            }
            if (that.ruleForm.endDate != null) {
              // 活动结束时间
              that.ruleForm.activityTime.push(that.ruleForm.endDate);
            }


            // 学生上传文件类型
            that.ruleForm.fileTypes = [];
            if (!regNullArray(resData.fileType)) {
              that.ruleForm.fileTypes = that.ruleForm.fileType.split(',');
            }

            // 宣传图片
            that.uploadFiles = [];
            if (!regNull(resData.attachment)) {
              for (var i in resData.attachment.split('|')) {
                var it = i + 1;
                that.uploadFiles.push({
                  uid: it,
                  path: files[i],
                });
              }
            }
            // that.uploadFilesReset = JSON.parse(JSON.stringify(that.uploadFiles));

            // 活动图片
            that.uploadPhotoFiles = [];
            if (!regNull(resData.photo)) {
              for (var i in resData.photo.split('|')) {
                var it = i + 1;
                that.uploadPhotoFiles.push({
                  uid: it,
                  path: files[i],
                });
              }
            }
            if(resData.level =='班级'){
              that.isShowClass = true;
              that.ruleForm.isImport = false;
              onGetStudentRange(that,that.ruleForm.classId);
              if (!regNullArray(that.ruleForm.ranges)) {
                that.defaultcheckedStudentSignUp = that.ruleForm.ranges.map(item => {
                  return item.studentId
                });
                that.optAllStudent = that.ruleForm.ranges.map(item => ({
                  id: item.studentId,
                  name: item.studentName+'-'+item.studentNo,
                }));
              }
            }
            // 默认选中的班级
            if (!regNullArray(that.ruleForm.ranges)) {
              that.defaultcheckedRangesSignUp = that.ruleForm.ranges.filter(item => regNull(item
                .classId) != true).map(item => {
                return item.classId
              });
              that.optAllClass = that.ruleForm.ranges.filter(item => regNull(item.classId) !=
                true).map(item => ({
                id: item.classId,
                name: item.className,
                collegeId: item.collegeId,
                collegeName: item.college,
              }));
              that.onGenerateCheckData(that.optAllClass);
            }
          }
        });
      },
      /**
       * @description 获取审核人员
       * @param {Object} that
       */
      onGetUser(that) {
        that.fullscreenLoading = true;
        // 获取所有用户表
        ajaxGet(that, '/api/admin/sysuser', null, function(resData) {
          that.fullscreenLoading = false;
          if (!regNullArray(resData)) {
            // 过滤未开启的账号
            let tpData = resData.filter(item => item.enable);
            that.optReviewer = [];
            tpData.forEach(item => {
              that.optReviewer.push({
                reviewerId: item.userId,
                name: item.name,
                userName: item.userName,
                disabled: that.TeacherInfo.userId == item.userId ? true : false
              });
            });
          }

          if (!regNullArray(that.ruleForm.arrAuditors)) {
            if (that.ruleForm.reversed != null) {
              if (regNull(that.ruleForm.reversed.find(val => val == that.TeacherInfo.userId))) {
                that.ruleForm.arrAuditors.push(that.TeacherInfo.userId);
              }
            }
          } else {
            that.ruleForm.arrAuditors.push(that.TeacherInfo.userId);
          }
        });

      },
      /**
       * @description 获取活动类型
       * @param {Object} that
       */
      onGetActivityLevel(that) {
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/activityLevel", null, function(resLevels) {
          that.optLevels = resLevels;
          // 判断是否为班主任
          if (that.TeacherInfo.isTeacher) {
            // 如果是班主任，默认为选择为班级
            let temp = [];
            temp = that.optLevels.filter(item => item.name == '班级');
            if (!regNullArray(temp)) {
              that.ruleForm.isImport = false;
              that.ruleForm.levelId = temp[0].levelId;
              that.handelActivityLevel(temp[0].levelId);
            }
          }
          that.fullscreenLoading = false;
        });
      },
      /**
       * @description 切换活动对象事件
       */
      handelActivityJoinType(val) {
        let that = this;
        that.ruleForm.isApply = false;
        that.ruleForm.isAttendance = false;
        that.ruleForm.isUpload = false;
        that.ruleForm.roleId = null;
        that.ruleForm.applyTime = null;
        that.ruleForm.numberLimit = null;
        that.ruleForm.isApplyAudit = false;
        that.ruleForm.isDelegate = false;
        if (that.ruleForm.joinType == 1) {
          that.signUpCollegesClass = JSON.parse(JSON.stringify(that.signUpCollegesClassReset));
          that.selectTreeSigeUpClassRanges = JSON.parse(JSON.stringify(that.selectTreeSigeUpClassRangesReset));
          that.ruleForm.isImport = true;
          if (!regNullArray(that.optRole)) {
            that.ruleForm.roleId = that.optRole[0].roleId
          }
        } else {
          that.ruleForm.isImport = false;
          let temp = that.optRole.filter(item => item.name.includes('参与'));
          that.ruleForm.roleId = temp[0].roleId;
          that.signUpColleges = JSON.parse(JSON.stringify(that.signUpCollegesReset));
          that.selectTreeSigeUpRanges = JSON.parse(JSON.stringify(that.selectTreeSigeUpRangesReset));
        }
      },

      /**
       * @description 判断是否需要学生报名
       */
      onApply(objVal) {
        this.ruleForm.isApplyAudit = objVal;
      },

      /**
       * @description 删除
       * @param {Object} res
       */
      onRemoveTag(res) {

        let that = this;
        if (that.currentUserInfo.userId == res) {
          that.ruleForm.arrAuditors.push(res);
        }
      },


      /**
       * @description 选择活动类型事件
       */
      handelActivityLevel(objItem) {
        let that = this;
        that.ruleForm.classId = null;
        that.optAllStudent = [];
        let tpLevel = that.optLevels.find(item => item.levelId == objItem);
        if (!regNull(tpLevel)) {
          if (tpLevel.name.indexOf('班') != -1) {
            that.isShowClass = true;
            that.ruleForm.isImport = false;
            if (!regNullArray(that.optClass)) {
              that.ruleForm.classId = that.optClass[0].classId;
              onGetStudentRange(that,that.ruleForm.classId)
            } else {
              that.ruleForm.classId = null;
            }
          } else {
            that.isShowClass = false;
            that.ruleForm.isImport = true;
          }
        } else {
          that.isShowClass = false;
          that.ruleForm.isImport = true;
        }
      },

      /**
       * @description 去除数组内的重复
       * @param {Object} arr
       */
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
      },

      /**
       * @description 宣传图片上传成功后事件
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      /**
       * @description 活动图片上传成功后事件
       * @param {Object} param
       */
      onPhotoHandleSuccess(param) {
        let that = this;
        that.ruleForm.photo = "";
        for (var i in param) {
          if (that.ruleForm.photo == "") {
            that.ruleForm.photo = param[i].path;
          } else {
            that.ruleForm.photo += "|" + param[i].path;
          }
        }
      },
      filterNodeSignUp(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      /**
       * @description 报名范围左侧选择事件，节点选中状态发生变化时的回调
       * @param {Object} objItem
       * @param {Object} objChecked
       * @param {Object} indeterminate
       */
      handleCheckStudentSignUp(objItem, objChecked) {
        let that = this;
        // 过滤非最底层节点
        if (regNullArray(objItem.children)) {
          if (objChecked) {
            // 选中
            if (regNullArray(that.optAllStudent)) {
              that.optAllStudent.push(objItem);
            } else if (regNull(that.optAllStudent.find(item => item.id == objItem.id))) {
              that.optAllStudent.push(objItem);
            }
          } else {
            // 取消
            let tpData = JSON.parse(JSON.stringify(that.optAllStudent));
            that.optAllStudent = tpData.filter(item => item.id != objItem.id);
          }
          // that.onGenerateCheckStudent(that.optAllStudent);
        }
      },
      onChangeClass(){
        let that = this;
        onGetStudentRange(that,that.ruleForm.classId)
      },
      /**
       * @description 报名范围左侧选择事件，节点选中状态发生变化时的回调
       * @param {Object} objItem
       * @param {Object} objChecked
       * @param {Object} indeterminate
       */
      handleCheckChangeSignUp(objItem, objChecked) {
        let that = this;
        // 过滤非最底层节点
        if (regNullArray(objItem.children)) {
          if (objChecked) {
            // 选中
            if (regNullArray(that.optAllClass)) {
              that.optAllClass.push(objItem);
            } else if (regNull(that.optAllClass.find(item => item.id == objItem.id))) {
              that.optAllClass.push(objItem);
            }
          } else {
            // 取消
            let tpData = JSON.parse(JSON.stringify(that.optAllClass));
            that.optAllClass = tpData.filter(item => item.id != objItem.id);
          }
          that.onGenerateCheckData(that.optAllClass);
        }
      },
      /**
       * @description 生成右侧已选的班级
       * @param {Object} objData
       */
      onGenerateCheckData(objData) {
        let that = this;
        // 原班级范围
        that.selectTreeSigeUpRanges = onGetJsonToTree([...that.onArrUnique(objData.map(item => ({
          id: item.collegeId,
          name: item.collegeName,
          collegeId: item.collegeId,
          collegeName: item.collegeName,
          parentId: "00000000-0000-0000-0000-000000000000",
        }))), ...objData.map(item => ({
          id: item.id,
          name: item.name,
          collegeId: item.collegeId,
          collegeName: item.collegeName,
          parentId: item.collegeId,
        }))], 'id', 'parentId', 'children');


      },
      /**
       * @description 报名范围，左侧选中事件
       * @param {Object} objItem
       */
      handleCurrentChange(objItem) {
        let that = this;
      },

      /**
       * @description 重置
       */
      onReset() {
        let that = this;
        if (!regNull(that.ruleForm.activityId)) {
          that.$refs.treeSignUp.setCheckedKeys([]);
          that.onGetData(that);
        }else{
          that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        }
        that.random += Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random2 += Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },


      /**
       * @description 返回
       */
      onBack() {
        let that = this;
        back(that);
      },


      // 保存
      onSave() {
        let that = this;


        if (!that.isShowClass && that.ruleFormisApply) {
          if (regNullArray(that.optAllClass)) {
            warning(that, '请选择报名范围');
            return false;
          }
        }
        // ruleForm.isApply && isShowClass

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            let tpParams = JSON.parse(JSON.stringify(that.ruleForm));

            // 上传截止时间
            tpParams.uploadDeadline = regNull(that.ruleForm.uploadDeadline) ? null : timeTransform(that.ruleForm
              .uploadDeadline);
            // 活动时间
            if (!regNullArray(that.ruleForm.activityTime)) {
              tpParams.startDate = timeTransform(that.ruleForm.activityTime[0]);
              tpParams.endDate = timeTransform(that.ruleForm.activityTime[1]);
            }
            // 需要报名
            if (that.ruleForm.isApply) {
              //报名开始时间
              if (!regNullArray(that.ruleForm.applyTime)) {
                tpParams.applyStartDate = timeTransform(that.ruleForm.applyTime[0]);
                tpParams.applyEndDate = timeTransform(that.ruleForm.applyTime[1]);
              }

              //人数限制
              tpParams.numberLimit = regNull(that.ruleForm.numberLimit) ? null : setInt(that.ruleForm.numberLimit);
              // 报名范围
              tpParams.ranges = that.optAllClass.map(item => ({
                classId: item.id
              }));
              if(!regNull(tpParams.classId)){
                // 报名学生
                tpParams.ranges = that.optAllStudent.map(item => ({
                  studentId: item.id
                }));
              }
            }

            // 活动审核人
            tpParams.auditors = that.ruleForm.arrAuditors.map(item => ({
              teacherId: item
            }));


            if (regNull(that.ruleForm.activityId)) {
              // 新增
              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/activity", tpParams, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            } else {
              // 编辑
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/activity/" + that.ruleForm.activityId, tpParams, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },

    },
    watch: {
      filterTextSignUp(val) {
        this.$refs.treeSignUp.filter(val);
      },
      filterTextSignUp2(val) {
        this.$refs.treeSignUp2.filter(val);
      },
      filterTextJoinIn(val) {
        this.$refs.treeJoinIn.filter(val);
      }
    }
  };
  function onGetStudentRange(that,ClassId){
    that.signUpStudents = [];
    that.selectTreeStudentUpRanges = [];
    ajaxGet(that,'/api/admin/student/class/'+ClassId,null,function(res){
        if(!regNullArray(res)){
          that.signUpStudents = [{
            id: ClassId,
            name: '全部',
            children: res.map(it => ({
              id: it.studentId,
              name: it.name+'-'+it.studentNo,
              parentId: ClassId,
              })),
          }];
        }
    })
  }
</script>

<style scoped="scoped">
  .elDefaultRole {
    padding-top: 30px;
  }

  .transferBox {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

  .divItemTitle {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #606266;
  }

  .divAlertCont {
    padding-left: 30px;
    padding-top: 10px;
    color: #9595A0;
  }


  .divTreeCont {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    padding: 15px;
    min-height: 150px;
  }

  .iconBox {
    font-size: 52px;
  }
</style>

<style>
  .divTreeCont .el-checkbox {
    margin-bottom: 0 !important;
  }

  .transferCont .el-transfer__buttons {
    width: 20%;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:first-child {
    float: left;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:last-child {
    float: right;
  }

  .transferCont .el-transfer-panel {
    width: 40%;
  }

  .formGroupCont .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  .checkListTitle .el-form-item__label {
    display: block;
    width: 100%;
  }
</style>
