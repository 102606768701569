<!--
  网站抬头
-->
<template>
  <div>
    <!-- Navbar -->
    <nav class="main-header navbar navbar-expand-md navbar-light navbar-white">
      <div class="container" style="max-width: 1380px">
        <a href="#" class="navbar-brand">
          <img src="@/assets/image/logo.png" alt="核心综合" class="imgLogo" />
        </a>
        <el-menu
          :default-active="defaultActive"
          active-text-color="#409eff"
          class="el-menu-demo"
          mode="horizontal"
          v-if="!regNullArray(optMenus)"
        >
          <template v-for="item in optMenus">
            <el-submenu
              v-if="item.children != null && item.children.length > 0"
              :index="item.actionId"
              :key="item.actionId"
            >
              <span slot="title">{{ item.name }}</span>
              <div v-for="(p1, key1) in item.children" :key="key1">
                <el-menu-item
                  :index="p1.actionId"
                  @click="onPage(p1.url)"
                  v-if="p1.children == null || p1.children.length <= 0"
                >
                  <span style="padding-left: 15px">{{ p1.name }}</span>
                </el-menu-item>
                <el-submenu :index="p1.actionId" v-else>
                  <span slot="title" style="font-size: 16px">{{
                    p1.name
                  }}</span>
                  <el-menu-item
                    @click="onPage(p2.url)"
                    v-for="(p2, key2) in p1.children"
                    :key="key2"
                    :index="p2.actionId"
                    >{{ p2.name }}</el-menu-item
                  >
                </el-submenu>
              </div>
            </el-submenu>
            <el-menu-item
              :index="item.actionId"
              v-else
              @click="onPage(item.url)"
              :key="item.actionId"
            >
              <span slot="title" style="font-size: 16px">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </el-menu>

        <el-dropdown
          @command="handleCompanyChange"
          trigger="hover"
          style="cursor: pointer"
        >
          <span class="el-dropdown-link" style="color: #9093a6">
            {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-lock" :command="'修改密码'"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item icon="el-icon-user-solid" :command="'个人信息'"
              >个人信息</el-dropdown-item
            >
            <el-dropdown-item
              divided
              icon="el-icon-switch-button"
              :command="'退出登录'"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </nav>
    <!-- /.navbar -->

    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="passwordDialogVisible"
      width="40%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :fullscreen="false"
    >
      <el-form
        :model="passwordRuleForm"
        :rules="passwordRules"
        ref="passwordRuleForm"
        label-width="90px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="原密码" prop="originalPassword">
              <el-input
                v-model="passwordRuleForm.originalPassword"
                placeholder="请输入原密码"
                auto-complete="new-password"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="输入密码" prop="newPassword">
              <el-input
                v-model="passwordRuleForm.newPassword"
                placeholder="请输入新密码"
                show-password
                auto-complete="new-password"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="确认密码" prop="againPassword">
              <el-input
                v-model="passwordRuleForm.againPassword"
                placeholder="请确认密码"
                show-password
                auto-complete="new-password"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onUpdatePassword()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    userName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      optMenus: [],
      defaultActive: null,

      // 密码修改
      passwordDialogVisible: false,
      passwordRuleForm: {
        originalPassword: null,
        newPassword: null,
        againPassword: null,
      },
      passwordRules: {
        originalPassword: [
          {
            required: true,
            message: "原密码不能为空",
            trigger: "change",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "新密码不能为空",
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              if (
                that.passwordRuleForm.newPassword == null ||
                that.passwordRuleForm.newPassword.length <= 0
              ) {
                callback(new Error("请输入密码！"));
              } else {
                var reg = /^.{8,16}$/;
                if (value != 0 && !reg.exec(value)) {
                  callback(new Error("密码为8-16位"));
                }
              }
              callback();
            },
            trigger: "change",
          },
        ],
        againPassword: [
          {
            required: true,
            message: "确认密码不能为空",
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              let that = this;
              if (
                that.passwordRuleForm.againPassword == null ||
                that.passwordRuleForm.againPassword.length <= 0
              ) {
                callback(new Error("请确认密码！"));
              } else {
                if (that.passwordRuleForm.newPassword != value) {
                  callback(new Error("两次密码不一致！"));
                }
              }
              // if(that.passwordRuleForm.userId == null)
              // {

              // }
              callback();
            },
            trigger: "change",
          },
        ],
      },

      isGeneral: false,
    };
  },
  methods: {
    /**
     * @description 菜单
     * @param {Object} url
     */
    onPage(url) {
      let that = this;
      if (url != null && typeof url != "undefined" && url != "") {
        that.$router.push({
          path: url,
        });
      }
    },
    /**
     * @description 注销
     */
    onLogout() {
      let that = this;
      WebLogout(that);
    },
    /**
     * @description 修改密码
     */
    onUpdatePassword() {
      let that = this;
      that.$refs["passwordRuleForm"].validate((valid) => {
        if (valid) {
          that.fullscreenLoading = true;
          var data = {
            oldPassword: that.$md5(that.passwordRuleForm.originalPassword),
            password: that.$md5(that.passwordRuleForm.newPassword),
          };
          ajaxPut(
            that,
            "/api/student/student/changepassword",
            data,
            function (r) {
              that.fullscreenLoading = false;
              that.passwordDialogVisible = false;
            }
          );
          that.passwordDialogVisible = false;
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName("is-error");
            isError[0].querySelector("input").focus();
          }, 1);
          return false;
        }
      });
    },
    /**
     * @description 关闭弹出框事件
     * @param {Object} command
     */
    handleCompanyChange(command) {
      let that = this;
      switch (command) {
        case "退出登录":
          {
            that.onLogout();
          }
          break;
        case "修改密码":
          {
            that.passwordRuleForm.originalPassword = "";
            that.passwordRuleForm.newPassword = "";
            that.passwordRuleForm.againPassword = "";
            that.passwordDialogVisible = true;
            try {
              that.$refs["passwordRuleForm"].resetFields();
            } catch {}
          }
          break;
        case "个人信息":
          {
            that.$router.push({
              path: "/Student/Myself/BaseInfo/Details",
              query: {
                url: that.$router.history.current.fullPath,
              },
            });
          }
          break;
      }
    },
  },
  created() {
    let that = this;
    onStudentMenu(that);
  },
  computed: {
    routers() {
      return this.$route.path;
    },
  },
  watch: {
    $route() {
      let that = this;
    },
  },
};

/**
 * @description 获取
 * @param {Object} that
 */
function onStudentMenu(that) {
  let tempStudent = JSON.parse(that.$GetKey(that.$cacheStudent));

  // 获取菜单数据
  ajaxGet(that, "/api/student/student/studentaction", null, function (resData) {
    // 是否五项常规可见
    if (tempStudent.isClassCadreMorality == false) {
      resData = resData.filter(
        (item) => item.code != "student_classcadremorality"
      );
    }
    // 是否可以添加班级综合积分
    if (tempStudent.isCredit == false) {
      resData = resData.filter(
        (item) => item.code != "student_moraleducation_index"
      );
    }
    // 是否可以添加班级行为规范
    if (
      tempStudent.isMoralityClass == false &&
      tempStudent.isMorality == false &&
      tempStudent.isMoralityDormitory == false &&
      tempStudent.isMoralityDp == false
    ) {
      resData = resData.filter((item) => item.code != "student_morality");
    }

    setTimeout(function () {
      that.optMenus = onGetJsonToTree(
        resData,
        "actionId",
        "parentId",
        "children"
      )
        .filter((item) => !regNullArray(item.children))
        .sort((a, b) => a.priority - b.priority);
      if (that.optMenus.length == 1) {
        that.optMenus = that.optMenus[0].children;
        that.optMenus = that.optMenus.sort((a, b) => a.priority - b.priority);
      } else {
        that.optMenus.forEach((item) => {
          if (item.code == "student_my") {
            item.children = onGetTreeConvertList(item.children)
              .filter((list) => list.code != "student_mypersonal")
              .sort((a, b) => a.priority - b.priority);
          }
        });
      }

      return false;
    }, 500);
  });
}
</script>


<style scoped="scoped">
.imgLogo {
  height: 30px;
}

.navUser {
  display: none;
  position: relative;
}

.navUser .imgGender {
  position: absolute;
  top: 4px;
  left: 25px;
  height: 30px;
}

.navUser .spName {
  padding-left: 50px;
}

.studentMenu {
  padding-left: 50px;
}
</style>
<style>
.el-dropdown-menu {
  min-width: 150px;
}

.el-menu--horizontal > .el-menu-item,
.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal > .el-menu-item {
  height: 40px;
  line-height: 40px;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
  border-bottom: none;
  font-size: 16px;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title,
.el-menu--horizontal > .el-menu-item.is-active {
  color: #409eff;
  border-bottom: none;
}

.el-menu--horizontal > .el-menu-item a,
.el-menu--horizontal > .el-menu-item a:hover {
  font-size: 16px;
}

.el-menu--horizontal .el-menu .is-active a.alinkMenu {
  color: #409eff !important;
}

.el-menu--horizontal .el-menu .el-menu-item a.alinkMenu:hover {
  color: #303133 !important;
}

.el-menu--horizontal .el-menu .el-menu-item a.alinkMenu {
  color: #909399;
  font-size: 14px;
  padding-left: 10px;
}
</style>
