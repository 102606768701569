<!-- 综合类型 -->
<template>
  <el-card class="box-card">
    <div slot="header" class="card-header">
      <h3 class="card-title titleCont">
        <span class="spIcon"></span>
        {{title}}
      </h3>
    </div>
    <div class="card-body" v-if="ruleForm.treeList">
      <el-alert title="综合分计算公式：综合分 = 分数 * 综合系数，综合系数最多保留小数点后一位。" type="warning" :closable="false">
      </el-alert>
      <div class="divTreeBox">
        <el-tree :data="ruleForm.treeList" :props="defaultProps" ref="tree" style="margin-top:10px;" node-key="id"
          @node-click="handleNodeClick" :default-expand-all="true" :show-checkbox="comType"
          :filter-node-method="filterNode" @check-change="handleCheckChange"
          :default-checked-keys="defCheckedKeys">
          <div class="divTreeItemBox" slot-scope="{ node, data }">
            <span v-if="regNull(data.parentId)" class="spLabelBox">{{ node.label }}</span>
            <span v-else class="spChildName">{{ node.label }}</span>
            <span v-if="regNullArray(data.children)">
              <el-input size="mini" type="number" v-model="data.score" class="w-50 txtScore"
                v-if="node.checked && comType" @change="onChangeScore(data)" placeholder="请输入系数">
              </el-input>
              <span class="spScoreBox" :class="data.score < 0?'spScore_Small':'spScore_Big'"
                v-else-if="data.score != null && data.score != '' && data.score != 0">系数：{{data.score}}</span>
            </span>
          </div>
        </el-tree>
      </div>
    </div>
    <div class="card-body" v-else>
      <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
    </div>
  </el-card>
</template>

<script>
  export default {
    props: {
      //默认选中的配置
      defaultConfig: {
        type: Array,
        default: () => []
      },
      //渲染列表list
      configList: {
        type: Array,
        default: () => [],
        required: true
      },
      // true:可编辑 false:只能查看
      comType: {
        type: Boolean,
        default: true
      },
      // 标题
      title: {
        type: String,
        default: '综合类型'
      }
    },
    data() {
      return {
        fullscreenLoading: false,
        // 树型
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        // 默认值
        defCheckedKeys: [],
        defCheckedKeysReset: null,

        ruleForm: {
          treeList: null,
        },
        ruleFormReset: null,
      };
    },
    created() {
      let that = this;

      if(!regNullArray(that.configList)){
        that.onGetTreeList(that.configList);
      }
    },
    methods: {
      /**
       * @description 生成数据
       * @param {*} objData
       */
      onGetTreeList(objData){
        let that = this;

        setDefaultConfig(that,objData); //更新配置列表并赋予初值

        that.ruleForm.treeList = onGetJsonToTree(objData.map(item => ({
          id: item.configId,
          name: item.name,
          priority: item.priority,
          parentId: item.parentId,
          description: item.description,
          ceiling: item.ceiling,
          baseScore: item.baseScore,
          applys: item.applys,
          score: item.score,
          checked: regNull(item.checked) ? false : item.checked,
        })), 'id', 'parentId', 'children'); //将列表变成tree

        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        that.defCheckedKeysReset = JSON.parse(JSON.stringify(that.defCheckedKeys));

        that.fullscreenLoading = true;

        console.log('=>',that.ruleForm.treeList);
      },
      /**
       * @description 输入分值后传值至父页
       */
      onChangeScore(objData) {
        let that = this;
        if (!regNull(objData.score)) {
          let tpParams = {
            configId: objData.id,
            score: objData.score
          }
          that.$emit('change', tpParams);
          console.log(tpParams)
        }
      },
      /**
       * @description 启用禁用事件
       * @param {Object} data
       * @param {Object} checked
       */
      handleCheckChange(data, checked) {
        let that = this;
        data.score = null;
        if (!checked) {
          that.$emit('change', null, data.id);
        }
      },
      /**
       * @description 树型点击事件
       * @param {Object} objItem
       */
      handleNodeClick(objItem) {
        let that = this;
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.defCheckedKeys = JSON.parse(JSON.stringify(that.defCheckedKeysReset));
      },
    }
  };
  function setDefaultConfig(that,objData) {
    if (!regNullArray(that.defaultConfig)) {
      that.defaultConfig.forEach(element => {
        that.defCheckedKeys.push(element.configId);

        var item = objData.find(x => x.configId == element.configId);
        if (!regNull(item)) {
          item.checked = true;
          item.score = element.score;
        }
      });
    }
  }
</script>

<style scoped="scoped" lang="less">
  .divTreeBox {
    overflow: auto;
    min-width: 100px
  }

  .divTreeItemBox {
    .spLabelBox {
      background-color: #ecf5ff;
      color: #409eff;
      border: 1px solid #d9ecff;
      border-radius: 4px;
      height: 40px;
      display: inline-block;
      line-height: 40px;
      padding-left: 10px;
      padding-right: 10px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .spChildName {
      height: 35px;
      display: inline-block;
      line-height: 35px;
      padding-left: 10px;
      padding-right: 10px;
      min-width: 120px;
    }

    .spScoreBox {

      border-radius: 4px;
      height: 30px;
      display: inline-block;
      line-height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }

    .spScore_0 {
      background-color: #909399;
      color: #FFFFFF;
      border: 1px solid #909399;
    }

    .spScore_Big {
      background-color: #F56C69;
      color: #FFFFFF;
      border: 1px solid f56c6c;
    }

    .spScore_Small {
      background-color: #67c23a;
      color: #FFFFFF;
      border: 1px solid #67c23a;
    }


  }

  .tabsHeader {
    margin: -18px -20px;
    background-color: #f5f7fa;
    position: relative;

    .divSemesterBox {
      position: absolute;
      right: 6px;
      top: 4px;

      .spTitle {
        font-size: 14px;
      }
    }


  }

  .divNullDataSemester {
    float: right;
  }
</style>

<style lang="less">
  .divTreeBox {
    .el-tree-node__content {
      height: inherit;
    }

    label {
      margin-bottom: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .tabsHeader {

    .el-tabs__header {
      margin: 0;
      margin-bottom: -1px;
    }

    .el-tabs--card>.el-tabs__header .el-tabs__nav {
      border: none;
    }

    .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
      background-color: #FFFFFF;
    }
  }
</style>
