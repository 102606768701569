<!--
  学生管理-学生处分
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <search :ColNumber="3" :SearchList="SearchList" :key="random" ref="SearchList" @onSearch="onSearch"
      v-if="SearchList!=null"></search>
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          处分信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <comImport v-if="isPower.punish_import == true" @onImportSuccess="onImportSuccess" :title="'处分信息导入'"
                :template="'/Templates/处分导入模版.xlsx'" :path="'/api/admin/punish/import'" ref="conImportFile">
              </comImport>
            </li>
            <li class="nav-item">
              <el-button v-if="isPower.punish_create == true" type="success" icon="el-icon-circle-plus-outline"
                @click="onAdd()">新增</el-button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="item!=null && item.length!=0">
        <el-table stripe :data="item" class="elTableData">
          <el-table-column type="index" label="序号" :index="indexMethod" width="80"></el-table-column>
          <el-table-column prop="punishNo" label="处分号" :show-overflow-tooltip="true" width="120">
            <template slot-scope="scope">
              <span>{{scope.row.punishNo}}</span>
            </template>
          </el-table-column>
          <el-table-column label="处分原因" :show-overflow-tooltip="true" min-width="200">
            <template slot-scope="scope">
              <span>{{scope.row.reason}}</span>
            </template>
          </el-table-column>
          <el-table-column label="学生" :show-overflow-tooltip="true" min-width="160">
            <template slot-scope="scope">
              {{onGetStudent(scope.row.members)}}
            </template>
          </el-table-column>
          <el-table-column label="处分时间" width="130">
            <template slot-scope="scope">
              {{scope.row.punishDate | dateformatDay}}
            </template>
          </el-table-column>
          <el-table-column label="操作时间" width="190">
            <template slot-scope="scope">
              {{scope.row.createDate | dateformatMinute}}
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <operationBtn v-if="isPower.punish_detail" :btnName="'查看'" @click.native="onDetail(scope.row.punishId)">
              </operationBtn>
              &nbsp;
              <el-dropdown v-if="isPower.punish_edit || isPower.punish_delete" trigger="click"
                @command="handleCommand($event,scope.row.punishId,scope.row)">
                <el-button size="mini">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="isPower.punish_edit" class="color-warning"
                    command="onEdit">编辑</el-dropdown-item>
                  <el-dropdown-item divided v-if="isPower.punish_delete" class="color-danger"
                    command="onDelete">删除</el-dropdown-item>
                    <el-dropdown-item divided v-if="isPower.punishrevocation_create && scope.row.isAllRevoke != true" class="color-danger"
                      command="onRevocation">处分撤销</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>

        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据"></el-empty>
      </div>
    </el-card>
    <el-dialog title="处分撤销" :visible.sync="dialogPunishRevocationVisible" width="30%">
      <el-form class="ruleForm" :model="studentForm" :rules="studentRules" ref="studentForm">
        <el-form-item label="选择学生" prop="studentId">
          <el-select v-model="studentForm.studentId" placeholder="请选择" style="width: 100%;">
            <el-option v-for="item in optStudent" :key="item.studentId" :label="item.name"
              :value="item.studentId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="撤销申请日期" prop="applyDate">
          <el-date-picker :key="random" value-format="yyyy-MM-dd" v-model="studentForm.applyDate" type="date"
            placeholder="请选择学期范围内的日期" class="width_100Pie">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="撤销日期" prop="revokeDate">
          <el-date-picker :key="random" value-format="yyyy-MM-dd" v-model="studentForm.revokeDate" type="date"
            placeholder="请选择学期范围内的日期" class="wMax formControl">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="撤销原因" prop="reason">
          <el-input class="formControl" type="textarea" :rows="4" maxlength="500" show-word-limit placeholder="请输入内容"
            v-model="studentForm.reason">
          </el-input>
        </el-form-item>
        <el-form-item label="附件">
          <div class="card-body pt-5">
            <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="AdduploadFiles">
            </comUpload>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCalce">取 消</el-button>
        <el-button type="primary" @click="onAddRevocation">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comImport from '@/components/Import.vue';
  import comPage from '@/components/Page.vue';
  import operationBtn from '@/components/operationBtn.vue';
  import search from '@/components/search.vue';
  import comUpload from '@/components/Upload.vue';
  export default {
    components: {
      comImport,
      comPage,
      operationBtn,
      search,
      comUpload
    },
    data() {
      return {
        fullscreenLoading: false,
        itemData: [],
        dialogTitle: '',
        dialogVisible: false,
        random: 0, //刷新组件
        page: null, //分页查询接口返回的结果
        item: [], //列表数据
        pageBtnList: null, //分页左侧按钮集合
        paging: { //分页数据
          pageLength: 0, // 总共页数
          Size: 10, // 当前请求数量
          Index: 1 //当前在第几页
        },
        SearchList: null, //搜索集合
        isPower: {
          //增删改查 权限管理
          punish_detail: false,
          punish_create: false,
          punish_edit: false,
          punish_delete: false,
          punish_import: false,
          punishrevocation_create:false,
        },
        url: null, //当前页面路径
        TeacherInfo: {},
        studentForm: {
          applyDate:'',
          revokeDate:'',
          reason:'',
          studentId:'',
        },
        optStudent:[],
        dialogPunishRevocationVisible:false,
        studentFormReast:null,
        studentRules: {
          student: [{
            type: 'array',
            required: true,
            message: '请选择学生',
            trigger: 'change',
          }],
          applyDate: [{
            required: true    ,
            message: '请选择撤销申请日期',
            trigger: 'change',
          }],
          revokeDate: [{
            required: true,
            message: '请选择撤销日期',
            trigger: 'change',
          }],
          reason: [{
            max: 500,
            required: true,
            message: '撤销原因不能超过500个字符',
            trigger: 'change'
          }, ],
        },
        Id:'',
        AdduploadFiles: [],
      };
    },
    created() {
      let that = this;
      isPower(that, "punish_detail", "punish_detail");
      isPower(that, "punish_create", "punish_create");
      isPower(that, "punish_edit", "punish_edit");
      isPower(that, "punish_delete", "punish_delete");
      isPower(that, "punish_import", "punish_import");
      isPower(that, "punishrevocation_create", "punishrevocation_create");
      that.TeacherInfo = onGetNowUser(that);
      pagingPage(that);



    },
    methods: {
      /**
       * @description 附件上传成功后事件
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.studentForm.attachment = "";
        for (var i in param) {
          if (that.studentForm.attachment == "") {
            that.studentForm.attachment = param[i].path;
          } else {
            that.studentForm.attachment += "|" + param[i].path;
          }
        }
      },
      onRevocation(objVal){
          let that = this;
          // that.studentForm = objVal;
          for(var item of objVal.members){
            if(!item.isRevoke){
              that.optStudent.push({
                name:item.name+'-'+item.class,
                studentId:item.studentId
              })
            }
          }
          that.studentForm.studentId = '';
          if(that.optStudent.length == 1){
            that.studentForm.studentId = that.optStudent[0].studentId;
          }
          that.dialogPunishRevocationVisible = true;
          that.studentForm.applyDate = new Date();
          that.studentForm.revokeDate = objVal.punishDate;
          var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
          that.random += random;
          try {
            that.$refs["studentForm"].resetFields();
          } catch {}
      },
      onAddRevocation() {
        let that = this;
        that.$refs["studentForm"].validate(valid => {
          if (valid) {
            that.fullscreenLoading = true;
            ajaxPost(that, "/api/admin/punishrevoke", that.studentForm, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      handleCalce() {
        let that = this;
        that.dialogPunishRevocationVisible = false;
      },
      /**
       * @description 更多操作
       * @param {Object} command
       * @param {Object} objValID
       */
      handleCommand(command, objValID , objVal) {
        let that = this;
        switch (command) {
          case 'onEdit':
            that.onEdit(objValID);
            break;
          case 'onDelete':
            that.onDelete(objValID);
            break;
          case 'onRevocation':
            that.onRevocation(objVal);
            break;
        }
      },
      /**
       * @description 展示学生
       */
      onGetStudent(objItem) {
        let that = this;
        let temp = '';
        if (!regNullArray(objItem)) {
          objItem.forEach((item) => {
            temp += item.name + '【' + item.level + '】、';
          });
          return temp.slice(0, temp.length - 1);
        } else {
          return temp;
        }
      },
      /**
       * @description 列表序号索引
       * @param {Object} index
       */
      indexMethod(index) {
        return (this.paging.Index - 1) * this.paging.Size + index + 1;
      },
      onImportSuccess(param) {

        let that = this;
        pagingPage(that);
      },
      onAdd() {
        let that = this;
        that.$router.push({
          path: '/Admin/StudentPunish/Create',
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onEdit(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/StudentPunish/Edit/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDetail(Id) {
        let that = this;
        that.$router.push({
          path: "/Admin/StudentPunish/Detail/" + Id,
          query: {
            url: that.$router.history.current.fullPath
          }
        });
      },
      onDelete(Id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/punish/" + Id, null, function(r) {
              that.fullscreenLoading = false;
              pagingPage(that);
            });
          }
        })
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      onSearch(params) {
        let that = this;
        let data = {};
        let searchData = params;
        searchData.forEach(element => {
          if (element.data) {
            data[element.zhi] = Join(element.data);
          }
        });
        data.PageNumer = 1;
       routerPath(that, "/Admin/StudentPunish/Index", data, null, "PageSize");

      },
      pagingClick(type, val) {
        let that = this;
        let url = "/Admin/StudentPunish/Index";
        if (type == 1) { //更改每页条数触发的事件
          routerPath(
            that,
            url,
            "PageSize",
            val,
            "PageNumer",
            "SemesterId",
            "ClassId",
            "Student",
            "PunishDate",
            "PunishNo",
            "Reason",
          );
        } else { //更改当前页时触发的事件
          routerPath(
            that,
            url,
            "PageNumer",
            val,
            "PageSize",
            "SemesterId",
            "ClassId",
            "Student",
            "PunishDate",
            "PunishNo",
            "Reason",
          );
        }
      },
    },
    watch: {
      $route() {
        let that = this;
        pagingPage(that);
      }
    }
  };

  function pagingPage(that) {
    paging(
      that,
      function(r) {
        let Myurl = "/api/admin/punish/search";

        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/Semester", null, function(semester) {

          let resultClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;

          var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
          that.random += random;

          var ClassId = r.ClassId;
          var classList = [];



          for (var i in resultClass) {
            var it = {
              Title: resultClass[i].alias,
              Value: resultClass[i].classId,
            };
            classList.push(it);
          }

          if (that.TeacherInfo.isTeacher) {
            if (ClassId == null || typeof(ClassId) == "undefined" || ClassId == "") {
              ClassId = resultClass[0].classId;
            }
          }



          that.SearchList = [];

          that.SearchList.push({
            type: "select",
            Name: "班级",
            data: ClassId,
            select: classList,
            zhi: "ClassId",
            isClear: !that.TeacherInfo.isTeacher, //select是否可以清除
          }, {
            type: "input",
            Name: "学生",
            data: r.Student,
            holder: "请输入学号/姓名",
            zhi: "Student"
          }, {
            type: "time",
            Name: "处分时间",
            data: r.PunishDate,
            zhi: "PunishDate"
          }, {
            type: "input",
            Name: "处分号",
            data: r.PunishNo,
            holder: "请输入处分号",
            zhi: "PunishNo"
          }, {
            type: "input",
            Name: "处分原因",
            data: r.Reason,
            holder: "请输入处分原因",
            zhi: "Reason"
          }, );

          let data = {
            PageNumer: r.PageNumer,
            PageSize: r.PageSize,
            PunishNo: r.PunishNo,
            ClassId: ClassId,
            Student: r.Student,
            PunishDate: r.PunishDate,
            Reason: r.Reason,
          };
          that.paging.Index = r.PageNumer;
          that.paging.Size = r.PageSize;

          ajaxGet(that, Myurl, data, function(r1) {
            that.page = r1;
            that.item = r1.items;
            for(var it of that.item){
              if(it.members.find(item =>item.isRevoke != true)){
                it.isAllRevoke  = false;
              }else{
                it.isAllRevoke  = true;
              }
            }
            that.fullscreenLoading = false;
          });


        });
      },
      "ClassId",
      "Student",
      "PunishDate",
      "PunishNo",
      "Reason"
    );
  }

</script>

<style scoped="scoped">
</style>

