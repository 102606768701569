<!--
  日常事务 - 班级奖励级别
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont" style="padding-left: 0px;">
          班级奖励级别
        </h3>
      </div>
      <div class="card-body">
        <el-row :gutter="24">
          <el-col :span="6">
            <div class="divTreeCont">
              <el-input placeholder="输入关键字进行过滤" v-model="filterText">
                <el-button slot="append" icon="el-icon-refresh-right" @click="onFilterText()"></el-button>
              </el-input>

              <el-tree :data="data" :props="defaultProps" ref="tree" style="margin-top:10px;" node-key="id"
                default-expand-all @node-click="handleNodeClick" :expand-on-click-node="false"
                :render-content="renderContent" :filter-node-method="filterNode">
              </el-tree>
            </div>
          </el-col>
          <el-col :span="18">
            <el-row :gutter="24">
              <el-col :span="20">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item v-for="(p,index) in currentNav" :key="index">{{p}}</el-breadcrumb-item>
                </el-breadcrumb>
              </el-col>
              <el-col :span="4" style="text-align: right;">
                <el-button v-if="isPower.prizelevel_create == true" @click="onAdd()" type="success"
                  icon="el-icon-circle-plus-outline">新增</el-button>
              </el-col>
            </el-row>

            <div v-if="item!=null && item.length!=0">
              <el-table stripe :data="item" class="tableTwo table-hover text-nowrap "
                style="width: 100%; margin-top: 0px; ">
                <el-table-column type="index" width="120px;" label="序号"></el-table-column>
                <el-table-column prop="name" label="奖励级别">

                </el-table-column>
                <el-table-column prop="coefficient" label="系数"></el-table-column>
                <el-table-column label="操作" width="180px">
                  <template slot-scope="scope">
                    <operationBtn v-if="isPower.prizelevel_edit == true" :btnName="'编辑'"
                      @click.native="onEdit(scope.row.id)"></operationBtn>
                    <operationBtn v-if="isPower.prizelevel_delete == true" :btnName="'删除'"
                      @click.native="onDelete(scope.row.id)"></operationBtn>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-else>
              <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
            </div>


          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%" :before-close="onClose"
      :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true" :fullscreen="false">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-row v-if="isDetail!=true">
          <el-col :span="24">
            <el-form-item label="奖励级别" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入奖励级别"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="系数" prop="coefficient">
              <el-input @change="onCoefficientChange()" v-model="ruleForm.coefficient" placeholder="请输入系数"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="所属类型" prop="parentId">
              <treeSelect :key="random" @getValue="getValue" :options="data" :value="ruleForm.parentId"></treeSelect>

              <!--	 <el-cascader style="width: 100% !important;"
								    :options="data"
								    :props="{ checkStrictly: true,expandTrigger: 'hover'  }"
								    clearable v-model="ruleForm.parentId"></el-cascader> !-->
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="优先级" prop="priority">
              <el-input @change="onPriorityChange()" v-model="ruleForm.priority" placeholder="请输入优先级"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="description">
              <el-input class="formControl" type="textarea" :rows="4" show-word-limit placeholder="请输入备注"
                maxlength="500" v-model="ruleForm.description">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div v-if="isDetail!=true">
          <el-button @click="onClose()">取 消</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">确 定</el-button>
        </div>
        <div v-else>
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import operationBtn from '@/components/operationBtn.vue';
  import treeSelect from '@/components/TreeSelect.vue';
  export default {
    name: '',
    components: {
      operationBtn,
      treeSelect
    },
    data() {
      return {
        filterText: '', //查找树形菜单
        currentNav: [],
        random: 0, //刷新组件
        currentId: null,
        isDetail: false,
        dialogTitle: "新增班级奖励级别",
        dialogVisible: false,
        currentTitle: "请在左侧选择奖励级别",
        item: null,
        fullscreenLoading: false,
        data: [],
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        ruleForm: {
          id: null,
          name: null,
          priority: null,
          description: null,
          parentId: null,
          coefficient: null,
        },
        ruleFormInit: null,
        ruleFormReset: null,
        isPower: {
          //增删改查 权限管理
          prizelevel_details: false,
          prizelevel_create: false,
          prizelevel_edit: false,
          prizelevel_delete: false,
        },
        rules: {
          name: [{
              required: true,
              message: '奖励级别不能为空',
              trigger: 'change'
            },
            {
              max: 128,
              message: '奖励级别不能超过128个字符',
              trigger: 'change'
            },
          ],
          coefficient: [{
            required: true,
            message: '系数不能为空',
            trigger: 'change'
          }, ],
          priority: [{
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^-?[1-9]\d*$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("优先级只能输入整数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
        },
      };
    },
    created() {
      let that = this;
      isPower(that, "prizelevel_details", "prizelevel_details");
      isPower(that, "prizelevel_create", "prizelevel_create");
      isPower(that, "prizelevel_edit", "prizelevel_edit");
      isPower(that, "prizelevel_delete", "prizelevel_delete");
      that.ruleFormInit = JSON.parse(JSON.stringify(that.ruleForm));
      Init(that);
    },
    methods: {
      onFilterText() {
        this.filterText = "";
      },
      filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
      onCoefficientChange() {
        let that = this;
        var value = setTwoFloat(that.ruleForm.coefficient);
        that.ruleForm.coefficient = value;
      },
      onPriorityChange() {
        let that = this;
        var value = setInt(that.ruleForm.priority);
        that.ruleForm.priority = value;
      },
      onClose() {
        let that = this;
        that.onReset();
        that.dialogVisible = false;
      },
      onSave() {
        let that = this;
        if (that.ruleForm.priority == null || that.ruleForm.priority == "") {
          that.ruleForm.priority = "0";
        }

        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            if (that.ruleForm.levelId == null) {

              that.fullscreenLoading = true;
              ajaxPost(that, "/api/admin/classprizelevel", that.ruleForm, function(r) {
                success(that, r.message);
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                that.currentId = that.ruleForm.parentId;
                Init(that);
              });
            } else {
              // that.ruleForm.levelId = that.ruleForm.id;
              if (that.ruleForm.levelId == that.ruleForm.parentId && that.ruleForm.levelId != null) {
                warning(that, "不能把奖励级别设置在自身级别下");
                return false;
              }
              that.fullscreenLoading = true;
              ajaxPut(that, "/api/admin/classprizelevel/" + that.ruleForm.levelId, that.ruleForm, function(r) {
                success(that, r.message);
                that.fullscreenLoading = false;
                that.dialogVisible = false;
                that.currentId = that.ruleForm.parentId;
                Init(that);
              });
            }
          } else {

            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      onReset() {
        let that = this;
        this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormReset));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      getValue(value) {
        let that = this;
        that.ruleForm.parentId = value;
      },
      onAdd() {
        let that = this;
        that.dialogVisible = true;
        that.dialogTitle = "新增班级奖励级别";
        that.isDetail = false;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormInit));
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleFormInit));
        that.ruleForm.parentId = that.currentId;
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onEdit(id) {
        let that = this;
        that.fullscreenLoading = true;
        ajaxGet(that,'/api/admin/classprizelevel/'+id,null,function(resData){
        that.ruleForm = resData;
        if(that.ruleForm.parentId=='00000000-0000-0000-0000-000000000000'){
          that.ruleForm.parentId=null;
        }
        that.dialogVisible = true;
        that.dialogTitle = "编辑班级奖励级别";
        that.isDetail = false;
        that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        that.fullscreenLoading = false;
        })
      },
      onDelete(id) {
        let that = this;
        confirmDelete(that, null, function(res) {
          if (res == true) {
            that.fullscreenLoading = true;
            ajaxDelete(that, "/api/admin/classprizelevel/" + id, null, function(r) {
              that.fullscreenLoading = false;
              Init(that);
            });
          }
        })
      },
      handleNodeClick(data) {
        let that = this;
        that.item = data.children;
        that.currentTitle = data.title;
        that.currentId = data.id;
      },
      renderContent(h, {
        node,
        data,
        store
      }) {
        return ( <
          span class = "custom-tree-node" >
          <
          span style = "font-size:14px;" > {
            node.label
          } < /span> < /
          span > );
      },
    },
    mounted() {

    },
    watch: {
      currentTitle(val) {
        let that = this;
        var titles = val.split('/');
        that.currentNav = ["班级奖励级别"];
        for (var i in titles) {
          if (titles[i] != "班级奖励级别") {
            that.currentNav.push(titles[i]);
          }
        }
      },
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    }
  };

  function GetItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        if (item.id == that.currentId) {
          that.item = item.children;
          that.currentTitle = item.title;
        } else if (item.children.length >= 1) {
          GetItem(that, item.children);
        }
      });

    }
  }

  function Init(that) {
    that.fullscreenLoading = true;
    that.data = [];
    ajaxGet(that, "/api/admin/classprizelevel/treelist", null, function(r) {

      that.fullscreenLoading = false;
      //that.data = r;
      var tree = {
        id: null,
        value: null,
        name: "班级奖励级别",
        title: "班级奖励级别",
        //label:"核心综合",
        children: r,
      };
      that.data.push(tree);
      GetItem(that, that.data);
    });
  }
</script>

<style scoped="scoped">
  .divTreeCont {
    border: 1px solid #DDDDDD;
    padding: 15px;
    border-radius: 4px;
  }
</style>
