<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="请稍等，正在生成综合报告"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <!--头部-->
    <div class="divPageHeaderBox">
      <!-- 学年 -->
      <ul class="ulTabsBox">
        <li class="liTab" :class="{liAction:currsorIndex==index}" v-for="(item,index) in optYear" :key="index"
          @click="onChangeYear(item,index)">{{item.name}}</li>
      </ul>
      <!-- 学期 -->
      <div class="divSemesterBox">
        <div class="divTitle">学期</div>
        <el-select v-model="nowSemester.semesterId" placeholder="请选择" class="selForm width_300"
          @change="onChangeSemester">
          <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name" :value="item.semesterId">
          </el-option>
        </el-select>
      </div>
    </div>

    <!-- 基本信息/个人总评 -->
    <div class="A4CardBox pageCont pageBoxOne PrintPageTwo" ref="targetDom">
      <div class="InfoTitleCont twoTitle">
        <i class="fax icon">&#xe791;</i>
        班级{{ onGetTitle() }}
      </div>
      <div class="literacyFractionCont">
        <div class="fractionItem divEvaluation">
          <span class="spTopLabel">总评分</span>
          <div>{{infoData.score}}</div>
        </div>
      </div>
      <div class="divFileRecords" style="height: 400px;">
        <comTotalRadar v-if="reportData.length>0 && searchData" :comID="'echartProportion'" :comHeight="400"
          :comColor="optColor" :searchData="searchData" ref="echartProportion"></comTotalRadar>
      </div>
    </div>



    <!-- 综合子项详情 -->

    <div v-for="(item,index) in reportData" :key="index">
      <div class="A4CardBox pageCont pageBoxHeader PrintPage">
        <div class="InfoTitleCont oneTitle titleBox">
          核心综合 &middot; <span :style="{'color':optColor[index]}">{{item.name}}</span>
        </div>
        <div class="chartBox" style="height: 500px;">
          <comChartRadar v-if="item.children.length>0" :comHeight="500" :comID="'chart_'+index" :indexColor="index"
            :ConfigId="item.id" :searchData="searchData" :ref="'chart_'+index">
          </comChartRadar>
          <el-empty description="暂无数据" v-else></el-empty>
        </div>
      </div>

      <div class="A4CardBox pageCont pageBoxSub PrintPage" v-for="(list,indexTwo) in item.record" :key="indexTwo">
        <div class="InfoTitleCont oneTitle titleBox">
          核心综合 &middot; <span :style="{'color':optColor[index]}">{{item.name}}</span>
        </div>
        <div class="divDataListCont">
          <div class="divContTitle">
            <span class="spTitleCont">
              {{list.semester}}
            </span>
          </div>
          <div class="divRecordBox" v-for="(child,indexThr) in list.children" :key="indexThr"
            :class='onGetClass(child.attachment)'>
            <div class="divContBox">
              <div class="divScore">
                {{child.score}}
                <span class="spFont">分</span>
              </div>
              <div class="divHeader">
                <div class="divSub">
                  <span class="spTime">{{child.logDate | dateformatDay}}</span>
                  <span class="spType">{{child.type}}</span>
                  <span class="spType">{{child.configName}}</span>
                </div>
                <div class="divName">{{child.name}}</div>
              </div>
            </div>
            <div class="divImgContBox" v-if="child.attachment!=null && child.attachment.length!=0">
              <el-row :gutter="8">
                <el-col :span="8" v-for="(img,indexThs) in child.attachment" :key="indexThs">
                  <div class="">
                    <el-image :src="img" class="imgList" :preview-src-list="child.attachment"></el-image>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>




    <!--页尾-->
    <div class="pt-10" v-if="!TeacherInfo.isTeacher && TeacherInfo.manageClass.length>1">
      <el-button icon="el-icon-back" @click="onReturn()">返回</el-button>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comChartRadar from '@/components/Report/class_radar.vue';
  import comTotalRadar from '@/components/Report/class_total_radar.vue';

  export default {
    components: {
      comChartRadar,
      comTotalRadar
    },
    data() {
      return {
        urlPhoto: null,
        // 加载动画
        fullscreenLoading: false,
        // 个人信息
        infoData: {
          studentId: '',
          // 总分
          score: 0,
          // 总评
          rating: 0,
          // 综合记录数
          record: null,
        },
        // 综合报告标题
        reportTitle: '',
        // 报告数据
        reportData: [],
        // 展示类型 0：毕业所有学年 1：学年
        isType: 0,
        // 当前学年选项
        valYear: [],
        // 学年下拉选择数据
        optYear: [],
        currsorIndex: 0,


        // 当前学期选项
        nowSemester: {
          semesterId: null,
          name: null,
        },
        // 学期下拉选择数据
        optSemester: [],



        // 颜色数组
        optColor: ['#7E66F6', '#3191D9', '#4FBD76', '#F9BB4A', '#F50737',
          '#8CB93D', '#3AAF9D', '#4261E4', '#A75CE7', '#6610f2',
          '#e83e8c', '#fd7e14', '#28a745', '#17a2b8'
        ],
        // 页面显示颜色的数组
        titlePoint: [],
        // 与全班对比统计图
        arrContrast: [],
        // 评语
        arrComments: [],
        // 成绩数组
        arrResults: [],
        // 荣誉
        arrHonor: [],
        // 权限管理
        isPower: {
          //增删改查
          student_report_download: false,
        },
        arrAllSemester: [],
        // 时间
        nowDate: timeTransform(new Date(), 'YYYY年MM月'),
        CommentsNum: 0,
        RecordNum: 0,
        allRecord: [],
        allSemester: [],
        searchData: null,

        TeacherInfo: {}, // 教师信息
      };
    },
    // watch: {
    //   'reportData': {
    //     handler: function(newVar, oldVar) {
    //       let that = this;
    //       that.reportData = newVar;
    //     },
    //     deep: true
    //   },
    // },
    methods: {
      /**
       * @description 总评标题，根据学期，学年显示总评名称
       */
      onGetTitle() {
        let that = this;
        if (!regNull(that.nowSemester.semesterId)) {
          return '学期总评';
        } else if (!regNull(that.currsorIndex != 0)) {
          return '学年总评';
        } else {
          return '总评';
        }
      },

      /**
       * @description 选择学年
       * @param {Object} objItem
       */
      onChangeYear(objItem, objIndex) {
        let that = this;
        that.currsorIndex = objIndex;
        that.optSemester = objItem.children;
        that.nowSemester.semesterId = null;
        that.nowSemester.name = null;
        onGetData(that);
      },
      /**
       * @description 选择学期
       * @param {Object} objVal
       */
      onChangeSemester(objVal) {
        let that = this;
        let temp = that.optSemester.find(item => item.semesterId == objVal);
        if (!regNull(temp)) {
          that.nowSemester.name = temp.name;
          that.nowSemester.nowYear = temp.yearId;
          that.currsorIndex = [that.optYear.findIndex(item => item.yearId == temp.yearId)];
        }
        onGetData(that);
      },

      /**
       * @description 返回
       */
      onReturn() {
        let that = this;
        back(that);
      },
      /**
       * @description 对没有图片的记录进行添加样式名
       * @param {Object} objItem
       */
      onGetClass(objItem) {
        if (regNullArray(objItem)) {
          return 'noImage';
        }
        return '';
      },
      // 图片处理
      onGetImg(that, _Value) {
        if (regNull(_Value)) {
          return [];
        } else {
          let arrImg = _Value.split('|');
          return arrImg.map(item => that.$ServerUrl() + item);
        }
      },
      //去除数组内的重复
      onArrUnique(arr) {
        const res = new Map();
        return arr.filter((arr) => !res.has(arr.semesterId) && res.set(arr.semesterId, 1));
      },

    },
    created() {
      let that = this;
      that.TeacherInfo = onGetNowUser(that);
      // 判断传值
      that.optYear = [{
        yearId: null,
        name: '全部学年',
        children: [],
      }];
      that.fullscreenLoading = true;
      if (!regNull(that.$route.params.id)) {
        // 获取学生的学期信息
        ajaxGet(that, '/api/admin/semester/class/' + that.$route.params.id, null, function(resSemester) {
          that.fullscreenLoading = false;
          if (!regNullArray(resSemester)) {
            // 全部学年 对应所有学期
            that.optYear[0].children = resSemester;
            // 默认所有学期
            that.optSemester = resSemester;
            // 生成各学年数据

            resSemester.forEach((item) => {
              if (regNull(that.optYear.find(list => list.yearId == item.yearId))) {
                that.optYear.push({
                  yearId: item.yearId,
                  name: item.year,
                  children: resSemester.filter(fl => fl.yearId == item.yearId)
                });
              }

              if (item.isCurrent == true) {
                that.nowSemester.semesterId = item.semesterId;
                that.nowSemester.name = item.name;
                that.nowSemester.nowYear = item.yearId;
              }

            });
            if (!regNull(that.nowSemester.semesterId)) {
              that.currsorIndex = [that.optYear.findIndex(item => item.yearId == that.nowSemester.nowYear)];
            }

            for (let i = resSemester.length - 1; i >= 0; i--) {
              that.allSemester.push(resSemester[i])
            }
          }
          onGetData(that);
        });
      }
    }
  };

  function onGetData(that) {
    that.fullscreenLoading = true;
    that.searchData = {
      SemesterId: that.nowSemester.semesterId,
      YearId: that.optYear[that.currsorIndex].yearId,
      ClassId: that.$route.params.id,
      ConfigId: null,
    }
    that.reportData = [];
    that.infoData.score = 0;
    ajaxGet(that, '/api/admin/growthclass/generalcomment', that.searchData, function(res) {
      that.infoData.score = res.score;
    })
    ajaxGet(that, '/api/admin/growthconfig/classtreelist', null, function(resData) {
      that.reportData = resData;

      that.reportData.forEach(element => {
        let searchData = {
          SemesterId: that.nowSemester.semesterId,
          YearId: that.optYear[that.currsorIndex].yearId,
          ClassId: that.$route.params.id,
          ConfigId: element.id,
        }
        element.record = [];
        ajaxGet(that, "/api/admin/growthclass/searchnopage", searchData, function(resRecod) {
          let semesterNameList = [];
          resRecod.forEach(element1 => {
            semesterNameList.push(element1.semester);
          })
          semesterNameList = Array.from(new Set(semesterNameList));
          semesterNameList.forEach(name => {
            var it = {
              semester: name,
              children: resRecod.filter(val => { return val.semester == name })
            }
            element.record.push(it);
          })
          that.$forceUpdate();
        })
      })
    });

    setTimeout(() => {
      that.fullscreenLoading = false;
    }, 1000)
  }

</script>

<style scoped="scoped" lang="less">
  .A4CardBox {
    background-color: #FFFFFF;
    width: 210mm;
    height: 297mm;
    margin: 50px auto;
    box-shadow: 0px 0px 20px 0px rgba(157, 157, 157, 0.75);
    -webkit-box-shadow: 0px 0px 20px 0px rgba(157, 157, 157, 0.75);
    -moz-box-shadow: 0px 0px 20px 0px rgba(157, 157, 157, 0.75);
    position: relative;
  }

  // 页头
  .pageHeader {
    .divLogo {
      padding-left: 60px;
      padding-top: 50px;

    }

    .divImgBox {
      position: absolute;
      top: 0;
      right: 0;

      .num1 {
        position: absolute;
        top: 90px;
        left: 90px;
        width: 100px;
        height: 100px;

        .bgColor {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #eda317;
          border-radius: 50%;
          opacity: 0.3;
        }

        .fontBox {
          position: relative;
          z-index: 10;
          background-color: #eda317;
          border-radius: 50%;
          width: 85px;
          height: 85px;
          color: #FFFFFF;
          font-size: 24px;
          line-height: 26px;
        }
      }

      .num2 {
        position: absolute;
        top: 240px;
        left: 90px;
        width: 100px;
        height: 100px;

        .bgColor {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #37a0ee;
          border-radius: 50%;
          opacity: 0.3;
        }

        .fontBox {
          position: relative;
          z-index: 10;
          background-color: #37a0ee;
          border-radius: 50%;
          width: 85px;
          height: 85px;
          color: #FFFFFF;
          font-size: 24px;
          line-height: 26px;
        }
      }

      .num3 {
        position: absolute;
        top: 290px;
        left: 290px;
        width: 100px;
        height: 100px;

        .bgColor {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #da524d;
          border-radius: 50%;
          opacity: 0.3;
        }

        .fontBox {
          position: relative;
          z-index: 10;
          background-color: #da524d;
          border-radius: 50%;
          width: 85px;
          height: 85px;
          color: #FFFFFF;
          font-size: 24px;
          line-height: 26px;
        }
      }
    }

    .divTitleBox {
      position: absolute;
      bottom: 140px;
      left: 0;
      right: 0;
      background-color: #37a1ed;
      width: 100%;

      .divTitle {
        margin-top: 5px;
        border-top: 2px solid #FFFFFF;
      }

      .divNameBox {
        color: #FFFFFF;
        width: 100%;
        text-align: right;
        font-size: 52px;
        padding-right: 40px;
        padding-top: 20px;
        font-weight: bold;
      }

      .divSubName {
        color: #FFFFFF;
        width: 100%;
        text-align: right;
        padding-right: 40px;
        font-size: 32px;
        padding-bottom: 20px;
      }
    }

    .divSchoolName {
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 18px;
    }

  }

  // 页尾
  .pageFooter {
    background-color: #00a1e9;
    position: relative;

    .divFontBox {
      position: relative;
      color: #fffFFF;
      z-index: 10;

      width: 100%;
      height: 100%;

      .leftFont {
        width: 150px;
        text-align: center;
        font-family: 'Mianfeiziti';
        font-size: 152px;
        margin: 0 auto;
        padding-top: 50%;
        padding-left: 25%;
      }

      .rightFont {
        padding-top: 100%;
        padding-right: 25%;
        margin: 0 auto;
        width: 150px;
        text-align: center;
        font-family: 'Mianfeiziti';
        font-size: 152px;
      }
    }

    .divFontBoxTwo {
      color: #fffFFF;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 30px;
      z-index: 99;
      text-align: center;
      font-size: 12px;
    }


    .bgImgBox {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .footerBox {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }

  // 通用页面
  .pageCont {
    padding: 40px 30px;

    .InfoTitleCont {
      letter-spacing: 1px;
      color: #212529;
      position: relative;
      font-size: 24px;

      .icon {
        font-size: 28px;
      }

      .enBox {
        text-transform: uppercase;

        .spFont {
          font-size: 18px;
          color: #212529;

          &:first-child {
            padding-right: 10px;
            color: #212529;
          }
        }
      }
    }
  }

  // 第1页
  .pageBoxOne {
    .baseInfoCont {
      position: relative;
      padding-top: 50px;
      padding-left: 30px;
      padding-bottom: 50px;

      .stuName {
        font-size: 36px;
        letter-spacing: 1px;
        color: #3698FC;
        margin-bottom: 15px;

      }

      .stuLabelCont {
        margin-bottom: 15px;

        .spItem {
          border-right: 2px solid #E3E3E3;
          color: #323E56;
          font-size: 18px;
          padding: 0px 20px;
          letter-spacing: 1px;
          color: #606266;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            border-right: none;
          }
        }
      }

      .stuAvatarCont {
        position: absolute;
        top: 20px;
        bottom: 0;
        right: 30px;

        .imgAvatar {
          width: 120px;
          border-radius: 4px;
          border: 1px solid #eeeeee;
        }
      }

      .spGender {
        position: absolute;
        top: -15px;
        right: -15px;
        color: #FFFFFF;
        font-size: 18px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;

        .icon {
          font-size: 22px;
        }
      }
    }

    .literacyFractionCont {
      text-align: center;
      margin-top: 40px;

      .fractionItem {
        display: inline-block;
        color: #256fbc;
        position: relative;
      }

      .divTotalScore {
        font-family: 'OswaldLight';
        font-size: 82px;
        letter-spacing: 2px;

        .spUnit {
          font-size: 32px;
          color: #8D8F91;
          margin-left: -15px;
        }
      }

      .divEvaluation {
        font-size: 62px;
        font-weight: bold;
        letter-spacing: 2px;
        margin-left: 50px;
        margin-right: 50px;

        .spTopLabel {
          color: #959595;
          letter-spacing: 1px;
          font-weight: normal;
          // position: absolute;
          // top: -20px;
          // left: 0;
          font-size: 22px;
        }
      }

      .divTotal {
        font-family: 'OswaldLight';
        font-size: 62px;
        letter-spacing: 2px;
        min-width: 100px;
        text-align: center;

        .spTopLabel {
          color: #959595;
          letter-spacing: 1px;
          font-weight: normal;
          position: absolute;
          top: -15px;
          left: 0;
          font-size: 22px;
        }

        .spUnit {
          font-size: 26px;
          color: #8D8F91;
          margin-left: 5px;
        }
      }
    }

    .oneTitle {
      margin-top: 50px;
    }

    .twoTitle {
      margin-top: 50px;
    }

    .divFileRecords {
      padding-top: 35px;

      .fileList {
        width: 140px;
        height: 140px;
        margin: 25px auto;
        border-radius: 50%;
        background-image: linear-gradient(to right bottom, #256fbc, #39afd6);

        .fileCont {
          background-color: #FFFFFF;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);
          -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.5);

          .topCont {
            margin-top: 10px;
            height: 40px;
            font-family: 'OswaldLight';
            color: #256fbc;
            font-size: 18px;
            font-weight: bold;
          }

          .bottomCont {
            height: 40px;
            margin-top: 10px;
            width: 80px;
            padding-left: 5px;
            padding-right: 5px;
            border-top: 1px solid #eeeeee;
            margin: 0 auto;
            font-size: 12px;
            color: #606166;
          }
        }
      }
    }


  }

  // 第2页
  .pageBoxTwo {

    .InfoSubTitle {
      text-align: center;
      font-size: 32px;
      letter-spacing: 1px;
      color: #323E56;

      .enBox {
        text-transform: uppercase;
      }

      .divIconCont {
        text-align: center;
      }
    }

    .InfoSubTitle span {
      font-size: 26px;
      padding: 0px 2px;
    }
  }

  .pageBoxHeader {
    padding-top: 100px;

    .titleBox {
      padding-bottom: 50px;
      text-align: center;
    }

    .chartBox {
      padding-top: 50px;
    }

    .literacyFractionCont {
      text-align: center;
      margin-top: 60px;
      display: flex;

      .fractionItem {
        color: #3698FC;
        position: relative;
        width: 100%;
      }

      .divTotal {
        font-family: 'OswaldLight';
        font-size: 82px;
        font-weight: bold;
        letter-spacing: 2px;
        min-width: 100px;
        text-align: center;

        .spTopLabel {
          color: #959595;
          letter-spacing: 1px;
          font-weight: normal;
          font-size: 22px;
        }

        .spUnit {
          font-size: 22px;
          color: #8D8F91;
          margin-left: 5px;
          font-weight: normal;
        }
      }
    }

  }

  // 综合之项
  .pageBoxSub {
    padding-top: 50px;

    .titleBox {
      padding-bottom: 0px;
      text-align: center;
    }

    .divDataListCont {
      position: relative;
      padding-top: 20px;

      .divContTitle {
        display: flex;
        margin-bottom: 30px;

        .spTitleCont {
          background-color: #0065b3;
          color: #FFFFFF;
          padding: 10px 20px;
          letter-spacing: 1px;
          border-radius: 5px;

        }
      }

      .divRecordBox {
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 1px dotted #eeeeee;

        &:last-child {
          border-bottom: none;
        }

        .divContBox {
          width: 100%;
          display: flex;

          .divScore {
            height: 65px;
            width: 120px;
            border-radius: 5px;
            font-family: 'OswaldLight';
            overflow: hidden;
            font-style: italic;
            font-size: 76px;
            font-weight: bolder;
            line-height: 60px;
            color: #909199;
            opacity: 0.8;
            position: relative;

            .spFont {

              font-size: 14px;
              bottom: 0;
              line-height: 18px;
              right: 10px;
            }
          }

          .divHeader {
            position: relative;

            .divSub {
              display: flex;

              .spTime {
                color: #606266;
                border: 1px dotted #606266;
                font-size: 12px;
                height: 28px;
                line-height: 28px;
                padding-left: 8px;
                padding-right: 8px;
                border-radius: 5px;
              }

              .spType {
                margin-left: 15px;
                background-color: #37a0ee;
                color: #FFFFFF;
                font-size: 12px;
                height: 28px;
                line-height: 28px;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 3px;
                opacity: 0.8;
              }

            }

            .divName {
              width: 100%;
              padding-top: 5px;
              color: #303133;
            }



          }
        }

        .divImgContBox {
          padding-top: 5px;
          padding-left: 120px;

          .imgList {
            height: 110px;
            width: 100%;
            border-radius: 5px;
            border: 1px solid #DDDDDD;
            padding: 5px;
          }
        }
      }

      .noImage {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

  }

  // 成绩单
  .pageBoxScore {
    padding-top: 130px;

    .titleBox {
      padding-bottom: 20px;
    }

    .divDataListCont {
      position: relative;
      padding-bottom: 50px;
      padding-top: 50px;

      .divContTitle {
        display: flex;

        .spTitleCont {
          background-color: #0065b3;
          color: #FFFFFF;
          padding: 10px 20px;
          letter-spacing: 1px;
          border-radius: 5px;

        }
      }
    }

    .divScoreCont {
      padding-top: 15px;
      position: relative;

    }

    .divScoreContTwo {
      display: flex;

      .divItem {
        width: 50%;
        padding-right: 10px;
      }
    }



  }

  // 班主任评语
  .pageBoxComments {
    padding-top: 140px;

    .titleBox {
      padding-bottom: 70px;
    }

    .divDataListCont {
      position: relative;
      padding-bottom: 50px;
      padding-top: 50px;

      .divContTitle {
        display: flex;

        .spTitleCont {
          background-color: #0065b3;
          color: #FFFFFF;
          padding: 10px 20px;
          letter-spacing: 1px;
          border-radius: 5px;

        }

        .spTitleContTwo {
          border: 2px solid #0065b3;
          color: #0065b3;
          font-weight: bold;
          padding: 10px 20px;
          letter-spacing: 1px;
          border-radius: 5px;

        }

        .spCommentsRank {
          padding: 5px 20px;
          letter-spacing: 1px;
          font-size: 22px;
          font-weight: bold;
        }
      }
    }


    .divCommentContent {
      padding-top: 15px;
      padding-left: 30px;
      padding-right: 30px;
      position: relative;

      .divRank {
        position: absolute;
        right: 100px;
        color: #2490ec;
        bottom: 40px;
        top: 0px;
        font-size: 82px;
        font-weight: bolder;
        transform: rotate(9deg);
        -ms-transform: rotate(9deg);
        /* Internet Explorer */
        -moz-transform: rotate(9deg);
        /* Firefox */
        -webkit-transform: rotate(9deg);
        /* Safari 和 Chrome */
        -o-transform: rotate(9deg);
        /* Opera */
        opacity: 0.3;
      }

      .divCommFont {
        position: relative;
        z-index: 10;
        text-indent: 2em;
        letter-spacing: 1px;
        color: #606266;
      }

      .divOhter {
        position: relative;
        z-index: 10;
        text-align: right;
        font-size: 14px;
        color: #6D7482;

        &:first-child {
          padding-top: 30px;
        }
      }
    }


  }



  .position-relative {
    position: relative;
  }

  .divPageHeaderBox {
    display: flex;
    padding-bottom: 5px;

    .ulTabsBox {
      flex: 1;
      display: flex;
      padding-top: 10px;
    }

    .liTab {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 18px;
      border-right: 2px solid #CECECE;
      color: #767676;
      cursor: pointer;

      &:hover {
        color: #3E3E3C;
      }

      &:last-child {
        border-right: none;
      }
    }

    .divSemesterBox {
      display: flex;

      .divTitle {
        height: 40px;
        line-height: 40px;
        letter-spacing: 2px;
        color: #323E56;
        padding-right: 10px;
      }
    }
  }



  .divPageHeaderBox .liAction:hover,
  .divPageHeaderBox .liAction {
    color: #3364FF;
  }






  .elImageBox {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    width: 33px;
    height: 33px;
    margin: 0;
    position: relative;
  }

  .divNoImage {
    font-size: 12px;
    color: #767676;
  }



  .divYearTitle {
    font-size: 18px;
    margin-bottom: 30px;
  }

  .divYearTitle span {
    background-color: #C2E5FE;
    padding: 10px 30px;
  }

  .divSearchBox {
    position: absolute;
    top: -70px;
    left: 50px;
    z-index: 10;
    display: flex;
  }

  .divSearchBox .spSearch {
    padding-left: 10px;
    padding-right: 10px;
  }




  .DetailsCont {
    padding-right: 30px;
    padding-left: 30px;
    max-width: 1280px;
    margin: 5px auto;
  }

  .ReportName {
    padding-top: 15px;
    text-align: center;
    color: #323E56;
  }

  .ReportName .spMiddot {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
  }

  .ReportName .spMiddot .spIcon {
    font-size: 5px;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .ReportName .spNameCont {
    width: auto;
    font-size: 36px;
    letter-spacing: 1px;
    position: relative;
  }

  .ReportName .spNameCont .spTitleBg {
    position: absolute;
    height: 10px;
    left: 0;
    right: 0;
    bottom: -15px;
    /* background-image: url(../../../assets/image/line_bg.png); */
    background-repeat: repeat-x;
  }

  .ReportName .titleSubCont {
    padding-top: 20px;
  }

  .ReportName .titleSubCont span {
    font-size: 18px;
    padding: 0px 2px;
  }



  .LiteracyDistribution {
    margin: 30px auto;
    position: relative;
  }

  .LiteracyDistribution .labelCont {
    margin: 0px auto;
    color: #939393;
  }

  .LiteracyDistribution .labelCont .spIcon {}

  .LiteracyDistribution .divLabelCont {
    position: absolute;
    top: 197px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .LiteracyDistribution .divLabelCont .spTotal {
    font-size: 52px;
    font-family: 'OswaldLight';
    display: block;
    letter-spacing: 1px;
    color: #323E56;
  }

  .LiteracyDistribution .divLabelCont .spUnit {
    font-size: 16px;
    color: #4B586F;
  }

  .LiteracyDistribution .divLabelCont .spFont {
    font-size: 18px;
    color: #4B586F;
  }

  .chartItemCont {
    position: relative;

  }

  .chartItemCont .divLabelCont {
    position: absolute;
    top: -68px;
    right: 0;
    color: #939393;
    font-size: 18px;
  }

  .chartItemCont .divItemInfo {
    position: absolute;
    left: 0;
    top: 50px;
  }

  .chartItemCont .divItemInfo .divInfoType {
    height: 80px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #EFEFEF;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 42px;
  }

  .chartItemCont .divItemInfo .divItemNum {
    font-family: 'OswaldLight';
    font-size: 52px;
    letter-spacing: 2px;
    position: relative;
    margin-top: 50px;
  }

  .chartItemCont .divItemInfo .spUnit {
    color: #323E56;
    font-size: 26px;
    padding-left: 5px;
  }


  .divDataListCont .tbTitleCont {
    position: absolute;
    background-color: #C2E5FE;
    color: #323E56;
    padding: 10px 20px;
    letter-spacing: 1px;
    top: -44px;
    left: 0;
  }

  .divDataListCont .tbData {}

  .divDataListCont .tbData th {
    color: #797F8C;
  }

  .divDataListCont .tbData td {
    vertical-align: middle;
    color: #585858;
  }

  .divDataListCont .tbData td .tdImg {
    width: 80px;
    height: 80px;
    border-radius: 4px;
  }




  .divGradesCompared {
    width: 100%;
    margin: 30px 0px;
  }



  .divQRCodeCont {
    text-align: center;
  }

  .divQRCodeCont img {
    width: 150px;
  }

  .divQRCodeCont .spFont {
    display: block;
    color: #323E56;
    font-size: 18px;
    letter-spacing: 2px;
  }


  .pt-6 {
    padding-top: 6rem;
  }

  .mt-6 {
    margin-top: 6rem;
  }

  .mt-7 {
    margin-top: 7rem;
  }

  .mt-8 {
    margin-top: 8rem;
  }

  .mt-9 {
    margin-top: 9rem;
  }

  .mt-10 {
    margin-top: 10rem;
  }

  .mt-13 {
    margin-top: 13rem;
  }

  .cascaderCont {
    width: 250px;
  }

</style>

<style>
  .pageBoxScore .tdCont {
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'OswaldLight';
  }

  .pageBoxScore .tdHeader_0 th {
    background-color: #f4f7fb !important;
  }

  .pageBoxScore .tdHeader_1 th {
    background-color: #fdf6f6 !important;
  }

  .elImageBox .image-slot {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

</style>

