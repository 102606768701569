<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-card class="box-card">
      <div class="card-body p-2">
        <ul class="nav nav-pills navTabsCont">
          <li class="nav-item" v-for="(p,index) in titles" :key="index">
            <span  @click="onChangeTab(p.type)" :class="p.type== ruleForm.category?'nav-link active':'nav-link'">
              {{p.name}}
            </span>
          </li>
        </ul>
      </div>
    </el-card>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{ Title }}
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24">
            <el-col :span="8">
              <el-form-item label="班级" prop="classId">
                <el-select v-model="ruleForm.classId" placeholder="请选择" class="width_100Pie" @change="onChangeClass">
                  <el-option v-for="item in optClass" :key="item.classId" :label="item.alias" :value="item.classId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="学生" prop="studentId">
                <el-select v-model="ruleForm.studentId" placeholder="请选择" filterable class="width_100Pie" multiple collapse-tags>
                  <el-option v-for="item in optStudent" :key="item.studentId"
                    :label="'[' + item.studentNo + '] ' + item.name" :value="item.studentId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
<!--            <el-col :span="8">
              <el-form-item label="请假分类" prop="category">
                <el-select v-model="ruleForm.category" placeholder="请选择" class="width_100Pie" @change='onClickCategory'>
                  <el-option v-for="item in optCategory" :key="item.Value" :label="item.Text" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="请假类型" prop="type" v-if="ruleForm.category == 1">
                <el-select v-model="ruleForm.type" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in optType" :key="item.Value" :label="item.Text" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="ruleForm.category == 5">
              <el-form-item label="通校规则" prop="syncGroupId">
                <el-select v-model="ruleForm.syncGroupId" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in optGroup" :key="item.groupId"
                    :label="item.groupName" :value="item.groupId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="ruleForm.category == 2 || ruleForm.category == 3 || ruleForm.category == 5">
              <el-form-item label="请假开始日期" prop="startDate">
                <el-date-picker v-model="ruleForm.startDate" type="date" placeholder="开始日期" class="width_100Pie"
                  @change="onChangeDateDay" @clear="onClearDate">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="ruleForm.category == 2 || ruleForm.category == 3 || ruleForm.category == 5">
              <el-form-item label="请假结束日期" prop="endDate">
                <el-date-picker v-model="ruleForm.endDate" type="date" placeholder="结束日期" class="width_100Pie"
                  @change="onChangeDateDay" @clear="onClearDate">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="ruleForm.category == 4">
              <el-form-item label="请假开始时间" prop="startDate">
                <el-date-picker v-model="ruleForm.startDate" type="datetime" placeholder="开始时间" class="width_100Pie"
                  format="yyyy-MM-dd HH:mm" @change="onChangeDateTime" @clear="onClearDate">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="ruleForm.category == 4">
              <el-form-item label="请假结束时间" prop="endDate">
                <el-date-picker v-model="ruleForm.endDate" type="datetime" placeholder="结束时间" class="width_100Pie"
                  format="yyyy-MM-dd HH:mm" @change="onChangeDateTime" @clear="onClearDate"
                  :picker-options="endPickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="ruleForm.category == 1">
              <el-form-item label="请假开始时间" prop="startDate">
                <el-date-picker v-model="ruleForm.startDate" type="datetime" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" class="width_100Pie" format="yyyy-MM-dd HH:mm"
                  @change="onChangeDate" @clear="onClearDate">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="ruleForm.category == 1">
              <el-form-item label="请假结束时间" prop="endDate">
                <el-date-picker v-model="ruleForm.endDate" type="datetime" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" class="width_100Pie" format="yyyy-MM-dd HH:mm"
                  @change="onChangeDate" @clear="onClearDate">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="时长（天）">
                <el-input v-model="ruleForm.days" class="width_100Pie" disabled> </el-input>
                <!-- <div class="divDays">{{ ruleForm.days }}</div> -->
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if='ruleForm.type == 1 '>
              <el-form-item label="疾病名称" prop="type">
                <el-select v-model="ruleForm.sickness" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in diseaseList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if='ruleForm.type == 1 '>
              <el-form-item label="症状" prop="symptom">
                <el-select v-model="ruleForm.symptom" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in symptomList" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="当前位置" prop="location" v-if="ruleForm.category == 1">
                <el-select v-model="ruleForm.location" placeholder="请选择" class="width_100Pie">
                  <el-option v-for="item in positions" :key="item.Value" :label="item.Text" :value="item.Value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="请假事由" prop="reason">
                <el-input v-model="ruleForm.reason" class="width_100Pie"> </el-input>
              </el-form-item>
            </el-col>

          </el-row>
        </div>
      </el-card>

      <el-card style="margin-top: 10px;">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            附件
          </h3>
        </div>
        <div class="card-body">
          <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles"></comUpload>
        </div>
      </el-card>

      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack()">返 回</el-button>
          <el-button @click="onReset()">重 置</el-button>
          <el-button type="primary" @click="onSave()">提 交</el-button>
        </div>
      </div>
    </el-form>

  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';

  export default {
    components: {
      comUpload,
    },
    data() {
      return {
        //富文本框
        fullscreenLoading: false,
        Title: "班主任代学生请假",
        diseaseList: ["原因不明", "感冒", "气管炎", "肺炎", "水痘", "风疹", "麻疹", "腮腺炎", "肠胃道疾病", "心脏病", "眼病", "牙痛", "耳鼻喉疾病", "泌尿系疾病",
          "神经衰弱", "意外伤害", "结核肝炎", "其他传染病",
        ],
        symptomList: ["发热、咳嗽、头痛、咽痛",  "腹痛、腹污", "呕吐", "红眼", "皮疹", "受伤", "其他"],
        //列表数据
        itemData: [],
        ruleForm: {
          classId: null,
          studentId: [],
          leaveId: null,
          startDate: null,
          endDate: null,
          days: 0,
          type: null,
          reason: null, //描述
          attachment: null, //附件
          recordDate: null,
          category: null,
          sickness: null,
          symptom: null,
          syncGroupId: null,
          location: true,
          symptom: '',
        },
        titles:[],//tabs
        positions: [{
            Value: true,
            Text: '在校',
          },
          {
            Value: false,
            Text: '在家',
          }
        ],
        optClass: [],
        optStudent: [],

        //待重置时的表单数据
        ruleFormReset: null,
        rules: {
          classId: [{
            required: true,
            message: '班级不能为空',
            trigger: 'change'
          }, ],
          studentId: [{
            required: true,
            message: '学生不能为空',
            trigger: 'change'
          }, ],
          type: [{
            required: true,
            message: '请假类型不能为空',
            trigger: 'change'
          }, ],
          recordDate: [{
            required: true,
            message: '请输入请假时间',
            trigger: 'change'
          }],
          startDate: [{
            required: true,
            message: '请输入请假开始时间',
            trigger: 'change'
          }],
          endDate: [{
            required: true,
            message: '请输入请假结束时间',
            trigger: 'change'
          }],
          startDatetime: [{
            required: true,
            message: '请输入请假开始时间',
            trigger: 'change'
          }],
          endDatetime: [{
            required: true,
            message: '请输入请假结束时间',
            trigger: 'change'
          }],
          reason: [{
              required: true,
              message: '请假事由不能为空',
              trigger: 'change'
            },
            {
              max: 1000,
              message: '请假事由不能超过1000个字符',
              trigger: 'change'
            },
          ],
          category: [{
            required: true,
            message: '请假分类不能为空',
            trigger: 'change'
          }],
          symptom: [{
            required: true,
            message: '症状不能为空',
            trigger: 'change'
          }],
          syncGroupId: [{
            required: true,
            message: '通校规则不能为空',
            trigger: 'change'
          }],
        },
        uploadFiles: [],
        random: null,

        optType: [],
        optCategory: [

        ],
        minDate: null, //进寝室请假时需要选择的
        maxDate: null, //进寝室请假时需要选择的
        optGroup: [],
        // 教师信息
        TeacherInfo: {},
      };
    },
    computed: {
      endPickerOptions() {
        return {
          disabledDate: (time) => {
            if (this.ruleForm.startDate) {
              // 复制this.ruleForm.startDate的值到新的变量
              let startDateCopy = new Date(this.ruleForm.startDate);

              // 设置end时间为当天的0点0分0秒
              let end = new Date(startDateCopy).setHours(0, 0, 0, 0);

              // 设置start时间为当天的23点59分59秒999毫秒
              let start = new Date(startDateCopy).setHours(23, 59, 59, 999);

              // 检查时间是否在范围内
              return time.getTime() < end || time.getTime() > start;
            }
          }
        };
      }
    },
    methods: {
      onChangeTab(e){
        let that = this;
        let today = new Date();
        that.ruleForm.category = e;
        that.ruleForm.startDate = null;
        that.ruleForm.endDate = null;
        that.ruleForm.type = '';
        that.ruleForm.days = '';
        if (that.ruleForm.category == 2 || that.ruleForm.category == 3 || that.ruleForm.category == 5) {
          that.ruleForm.startDate = dateFormat(today);
        }
        if(that.ruleForm.category == 1){
          that.ruleForm.startDate = dateFormat(today,'-','YYYY-MM-DD HH:MM');
        }
      },
      //切换请假分类
      onClickCategory() {
        let that = this;
        let today = new Date();
        that.ruleForm.startDate = null;
        that.ruleForm.endDate = null;
        that.ruleForm.type = '';
        that.ruleForm.days = '';
        if (that.ruleForm.category == 2 || that.ruleForm.category == 3 || that.ruleForm.category == 5) {
          that.ruleForm.startDate = dateFormat(today);
        }
      },
      //选择症状
      onClickAddr(val) {
        let that = this;
        if (that.ruleForm.symptom == null) {
          that.ruleForm.symptom = val;
        } else {
          that.ruleForm.symptom = that.ruleForm.symptom + val;
        }
      },
      /**
       * @description 选择班级事件
       * @param {Object} objVal
       */
      onChangeClass(objVal) {
        let that = this;
        if (!regNull(objVal)) {
          that.ruleForm.studentId = null;
          ajaxGet(that, '/api/admin/student/class/' + objVal, null, function(resStudent) {
            that.optStudent = resStudent;
          });
        }
      },
      /**
       * @description 清除时间事件
       */
      onClearDate() {
        let that = this;
        that.ruleForm.days = 0;
        that.ruleForm.startDate = null;
        that.ruleForm.endDate = null;
      },
      onChangeDateDay() {
        let that = this;
        if ((!regNull(that.ruleForm.startDate)) && (!regNull(that.ruleForm.endDate))) {
          // 时间正常，就开始计算时长
          let dateStart = new Date(that.ruleForm.startDate);
          dateStart.setHours(0, 0, 0, 0)
          let dateEnd = new Date(that.ruleForm.endDate);
          let dateVal = dateEnd.getTime() - dateStart.getTime();
          let tpDays = Math.floor(dateVal / (24 * 3600 * 1000));
          let tpHours = Math.floor((dateVal % (24 * 3600 * 1000)) / (3600 * 1000));
          that.ruleForm.days = tpDays + 1;
        } else {
          that.ruleForm.days = null;
        }
      },
      onChangeDateTime() {
        let that = this;
        if (!regNull(that.ruleForm.startDate) && !regNull(that.ruleForm.endDate)) {
          // 时间正常，就开始计算时长
          let dateStart = new Date(that.ruleForm.startDate);
          let dateEnd = new Date(that.ruleForm.startDate);
          let dateVal = dateEnd.getTime() - dateStart.getTime();
          let tpDays = Math.floor(dateVal / (24 * 3600 * 1000));
          let tpHours = Math.floor((dateVal % (24 * 3600 * 1000)) / (3600 * 1000));
          // 进行时间判断，小于4小时的算半天，超过算1天，以此类推
          if (tpDays < 1) {

            if (0 < tpHours <= 4) {
              that.ruleForm.days = 0.5;
            } else {
              that.ruleForm.days = 1;
            }
          }
        }
      },
      /**
       * @description 时间事件，计算时长
       * @param {Object} objItem
       */
      onChangeDate(objItem) {
        let that = this;
        if (!regNull(that.ruleForm.startDate) && !regNull(that.ruleForm.endDate)) {

          // 时间正常，就开始计算时长
          let dateStart = new Date(that.ruleForm.startDate);
          // that.ruleForm.startDate = timeTransform(objItem[0]);
          // that.ruleForm.endDate = timeTransform(objItem[1]);
          let dateEnd = new Date(that.ruleForm.endDate);
          let dateVal = dateEnd.getTime() - dateStart.getTime();
          let tpDays = Math.floor(dateVal / (24 * 3600 * 1000));
          let tpHours = Math.floor((dateVal % (24 * 3600 * 1000)) / (3600 * 1000));
          // 进行时间判断，小于4小时的算半天，超过算1天，以此类推
          if (tpDays < 1) {

            if (0 < tpHours <= 4) {
              that.ruleForm.days = 0.5;
            } else {
              that.ruleForm.days = 1;
            }
          } else {
            if ((tpHours <= 4) && (tpHours > 0)) {
              that.ruleForm.days = tpDays + 0.5;
            } else if (tpHours > 4) {
              that.ruleForm.days = tpDays + 1;
            } else {
              that.ruleForm.days = tpDays;
            }
          }
        } else {
          that.ruleForm.days = null;
        }
      },
      /**
       * @description 图片上传
       * @param {Object} param
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },
      /**
       * @description 提交事件
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.ruleForm.startDate = timeTransform(that.ruleForm.startDate);
            that.ruleForm.endDate = timeTransform(that.ruleForm.endDate);
            that.ruleForm.type = regNull(that.ruleForm.type) ? null : setInt(that.ruleForm.type);
            that.ruleForm.category = setInt(that.ruleForm.category);
            that.ruleForm.syncGroupId = regNull(that.ruleForm.syncGroupId) ? null : that.ruleForm.syncGroupId;
            if(that.ruleForm.category != 1){
            	that.ruleForm.type = null;
            	that.ruleForm.location = null;
            }
            if(that.ruleForm.category != 5){
            	that.ruleForm.syncGroupId = null;
            }
            if (regNull(that.ruleForm.leaveId)) {
              // that.fullscreenLoading = true;
              let data = [];
              for (var it of that.ruleForm.studentId) {
                data.push({
                  startDate: that.ruleForm.startDate,
                  endDate: that.ruleForm.endDate,
                  studentId: it,
                  days: that.ruleForm.days,
                  reason: that.ruleForm.reason,
                  attachment: that.ruleForm.attachment,
                  category: that.ruleForm.category,
                  sickness: that.ruleForm.sickness,
                  symptom: that.ruleForm.symptom,
                  type : that.ruleForm.type,
                  location : that.ruleForm.location,
                  syncGroupId : that.ruleForm.syncGroupId,
                });
              }
              // console.log(data)
              ajaxPost(that, "/api/admin/askforleave/addrang", data, function(r) {
                that.fullscreenLoading = false;
                that.onBack();
              });
            }
          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        })

      },
      /**
       * @description 重置
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      onBack() {
        let that = this;
        back(that);
      },
    },
    created() {
      let that = this;
      // that.fullscreenLoading = true;
      that.titles = [{
            name:'普通请假',
            type:1,
          },{
            name:'课间操请假',
            type:2,
          },{
            name:'午休请假',
            type:3,
          },{
            name:'进寝室请假',
            type:4,
          },{
            name:'临时通校请假',
            type:5,
          }];
      that.TeacherInfo = onGetNowUser(that);
      that.ruleForm.category= that.$route.query.category;
      if(regNull(that.ruleForm.category)){
        that.ruleForm.category = 1;
      }
      let today = new Date();
      if (that.ruleForm.category == 2 || that.ruleForm.category == 3 || that.ruleForm.category == 5) {
        that.ruleForm.startDate = dateFormat(today);
      }
      if(that.ruleForm.category == 1){
        that.ruleForm.startDate = dateFormat(today,'-','YYYY-MM-DD HH:MM');
      }
      that.fullscreenLoading = true;
      ajaxGet(that, '/api/dict/leave/category', null, function(res) {
        that.optCategory = res;
        let temp = that.optCategory.find(item =>item.Value == that.ruleForm.category);
        that.Title = '班主任代学生请假 -' + temp.Text;
      })
      ajaxGet(that, '/api/dict/leave/group', null, function(res) {
        that.optGroup = res;
      })
      // 获取请假类型
      ajaxGet(that, '/api/admin/askforleave/type', null, function(resData) {
        // 获取班级
        let resClass = that.TeacherInfo.isTeacher ? that.TeacherInfo.manageClass : that.TeacherInfo.allClass;


        that.optType = resData;
        if (!regNullArray(resClass)) {
          // 根据班级获取学生信息
          that.optClass = resClass;
          ajaxGet(that, '/api/admin/student/class/' + resClass[0].classId, null, function(resStudent) {
            that.optStudent = resStudent;
            that.ruleForm.classId = resClass[0].classId;

            if (!regNull(that.$route.params.Id)) {
              that.Title = "编辑代理请假";
              ajaxGet(that, '/api/student/askforleave/' + that.$route.params.Id, null, function(resData) {
                that.ruleForm = resData;
                that.fullscreenLoading = false;
                that.ruleForm.recordDate = [that.ruleForm.startDate, that.ruleForm.endDate];
                that.uploadFiles = [];
                if (!regNull(resData.attachment)) {
                  var files = resData.attachment.split('|');
                  for (var i in files) {
                    var it = i + 1;
                    that.uploadFiles.push({
                      uid: it,
                      path: files[i],
                    });
                  }
                  var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
                  that.random += random;
                }
                that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
              });
            } else {
              that.fullscreenLoading = false;
              that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
            }
          })

        }

      });


    }
  };
</script>

<style scoped="scoped" lang="less">
  .divDays {
    padding-top: 40px;
    color: #606166;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }
  .navTabsCont .nav-link {
    font-size: 12px;
  }
</style>
