<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            {{Title}}
          </h3>
        </div>
        <div class="card-body ">
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="批次名称" prop="arrTimeRange">
                <el-input v-model="input" placeholder="请输入批次名称" class="width_100Pie"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="批次时间" prop="arrTimeRange">
                <el-date-picker   v-model="ruleForm.arrTimeRange" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" class="width_100Pie">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>





      <div class="row" style="margin-top: 10px;">
        <div class="col-12">
          <el-button icon="el-icon-back" @click="onBack">返回</el-button>
          <el-button icon="el-icon-refresh" @click="onReset">重 置</el-button>
          <el-button type="primary" icon="el-icon-circle-check" @click="onSubmit">提交</el-button>
        </div>
      </div>
    </el-form>




  </div>

</template>

<script>
  import '@/assets/css/Admin.css';
  export default {
    components: {
    },
    data() {
      return {
        // 加载动画
        fullscreenLoading: false,

        // 页面标题
        Title: '添加/编辑奖学金批次',
        // 表单对象
        ruleForm: {
          starId: null,
          name: '', // 综合之星名称
          startDate: '', // 开始时间
          endDate: '', // 结束时间
          range: null, // 评比范围
          icon: '', // 图标
          configs: [
            {configName:'一等奖'}
          ], //综合配置
          isCalculation: false,
          // 时间范围
          arrTimeRange: [],
          // 校历选择的值
          cycleVal: null,
          // 学期
          semesterVal: '',
          monthVal: '',
        },
        // 验证
        rules: {
          name: [{
              required: true,
              message: '综合之星名称不能为空',
              trigger: 'change'
            },
            {
              max: 20,
              message: '名称不能超过20个字符',
              trigger: 'change'
            },
          ],

          arrTimeRange: [{
            required: true,
            message: '评比时间范围',
            trigger: 'change'
          }, ],
          cycleVal: [{
            required: true,
            message: '请选择时间周期',
            trigger: 'change'
          }, ],
          semesterVal: [{
            required: true,
            message: '请选择学期',
            trigger: 'change'
          }, ],
          monthVal: [{
            required: true,
            message: '请选择月份',
            trigger: 'change'
          }, ],
          weekVal: [{
            required: true,
            message: '请选择周',
            trigger: 'change'
          }, ],
          range: [{
            required: true,
            message: '请选择评比范围',
            trigger: 'change'
          }, ],
        },
        // 是否调用校历
        isSchoolCalendar: false,
        // 是否使用默认图标
        isDefaultIco: false,
        // 调用控件随机ID
        random: '',
        // 新增配置单弹出框
        dialogVisible: false,



        // 弹出框
        frameFrom: {



          growthVal: '',//核心综合选择项值

          ckRankPec: false, //  名次百分比
          txtRanking:null, // 名次

          ckConduct:false,//操行评定
          txtConduct:null,

          ckAbsenteeism:false,// 旷课
          txtAbsenteeism:null,

          ckSujectScore:false,// 各科成绩
          txtSujectScore:null,

          ckAverage:false,// 单科平均成绩
          txtAverage:null,

          ckExam:false,// 考查课成绩
          txtExam:null,

          ckSports:false,// 体育成绩
          txtSports:null,
        },
        isCheckBoxTip: false,
        rulesFrameFrom: {
          growthVal: [{
            required: true,
            message: '请选择综合纬度',
            trigger: 'change'
          }, ],
          txtRanking: [{
            required: true,
            message: '名次不能为空',
            trigger: 'change'
          }],
        },
        frameFromReset: null,


      };
    },
    created() {
      let that = this;
      that.frameFromReset = JSON.parse(JSON.stringify(that.frameFrom));



      let dataID = that.$route.params.id;
      if (dataID != null && typeof(dataID) != "undefined" && dataID != "") {
        that.ruleForm.starId = dataID;
      }



    },
    methods: {
      /**
       * @description 关闭弹出框
       */
      onHandleClose() {
        let that = this;
        // 重置
        that.frameFrom = JSON.parse(JSON.stringify(that.frameFromReset));
        that.dialogVisible = false;
      },

      /**
       * @description 删除
       * @param {Object} _valID ID
       */
      onDelete(_valID) {
      },

      /**
       * @description 提交事件
       */
      onSubmit() {


      },


      /**
       * @description 返回
       */
      onBack() {
        back(this);
      },




      /**
       * @description 弹出框配置提交事件
       */
      onClickConfig() {

      },


    }
  };


</script>

<style scoped="scoped">
  .icoImageBox {
    width: 100px;
    height: 100px;
    margin: 5px auto;
    border: 1px dotted #DDDDDD;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
  }

  .actIcon {
    border: 1px solid #007bff;
  }


  .divCheckedBox {
    width: 100%;
  }

  .divCheckedBox .spChecked {
    padding-right: 30px;
  }

  .divErrCont {
    font-size: 0.75rem;
    margin-top: -20px;
    display: block;
    width: 100%;
    color: #F56C6C;
  }
</style>
