<!-- 成长档案 - 详情 -->

<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <el-card class="box-card">
      <div slot="header" class="card-header">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          基础信息
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-select v-model="strSemester" placeholder="请选择学期" @change="handSemesterChange">
                <el-option v-for="item in optSemester" :key="item.semesterId" :label="item.name"
                  :value="item.semesterId">
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-2 text-center">
            <img :src="imageUrl" alt="" class="imgAvatar">
          </div>
          <div class="col-lg-10 position-relative">
            <div class="infoList clearfix">
              <span class="spName">{{itemData.name}}</span>
              <span class="spGender">
                <svg class="icon maleColor" aria-hidden="true" v-if="onGender(itemData.gender)">
                  <use xlink:href="#fax-mars-circle-alt"></use>
                </svg>
                <svg class="icon femaleColor" aria-hidden="true" v-else>
                  <use xlink:href="#fax-mars-circle-alt"></use>
                </svg>
              </span>
              <span class="spLabel">
                <label v-if="itemData.isPoor==true">困难生</label>
              </span>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                <span v-if="itemData.nation!=null&&itemData.nation!=''">{{itemData.nation}}</span>
                <span v-else>暂无民族</span>
              </span>
              <span class="spFonts">
                <span v-if="itemData.political!=null&&itemData.political!=''">{{itemData.political}}</span>
                <span v-else>暂无政治面貌</span>
              </span>
            </div>
            <div class="infoList clearfix">
              <span class="spFonts">
                <svg class="icon faxSize" aria-hidden="true">
                  <use xlink:href="#fax-font-number-o"></use>
                </svg>
                {{itemData.studentNo}}
              </span>
              <span class="spFonts">
                <svg class="icon faxSize" aria-hidden="true">
                  <use xlink:href="#fax-font-ban-o"></use>
                </svg>
                {{itemData.class}}&nbsp;/&nbsp;{{itemData.major}}
              </span>
              <span class="spFonts">
                <i class="el-icon-school" style="width: 20px;"></i>
                <span v-if="itemData.dormitory!=null&&itemData.dormitory!=''">{{itemData.dormitory}}</span>
                <span v-else>暂无宿舍</span>
              </span>
            </div>
            <div class="divReportBox" @click="onGetReport()" v-if="isPower.student_report">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#fax-basis-o"></use>
              </svg>
              <div class="divIconName">
                生成综合报告
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <!--分数计算-->
    <div class="row">
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6 webHeigh">
                <div class="spBoxTitle">个人总评</div>
                <div class="spValueFont color-danger">{{onNullGeneralComment(itemGrowth.generalComment)}}</div>
              </div>
              <div class="col-6 webHeigh">
                <div class="spBoxTitle">综合记录</div>
                <div class="spValueFont">
                  <span class="spNum">{{itemGrowth.logQuantity}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6 webHeigh position-relative">
                <div class="divTitleCont VerticalMid bg-danger">加</div>
                <div class="divValueCont">{{itemGrowth.bonusPoints}}<span class="spUnit">分</span></div>
              </div>
              <div class="col-6 webHeigh position-relative">
                <div class="divTitleCont VerticalMid bg-primary">减</div>
                <div class="divValueCont">{{itemGrowth.deductPoints}}<span class="spUnit">分</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6 webHeigh">
                <div class="spBoxTitle">惩罚记录</div>
                <div class="spValueFont">
                  <span class="spNum">{{itemGrowth.punishQuantity}}</span>
                </div>
              </div>
              <div class="col-6 webHeigh">
                <div class="spBoxTitle">获奖记录</div>
                <div class="spValueFont">
                  <span class="spNum">{{itemGrowth.prizeQuantity}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--综合评价-->
    <el-card class="box-card mt-1">
      <div slot="header" class="card-header ">
        <h3 class="card-title tabsContBox  clearfix">
          <span class="spLabel" v-for="(item,index) in itemGrowthConfigs" :key="index">
            <span class="abtnTabs" :class="{abtnTabAct:tabRadarIndex==index}"
              @click="onChangeTabs(item.configId,index)">
              {{item.name}}
            </span>
            <span class="spDivide" v-if="itemGrowthConfigs.length-1!=index">/</span>
          </span>
        </h3>
      </div>
      <div class="card-body" v-if="arrRadar.length!=0">
        <comChart :key="random" :comID="'myChart'" :comHeight="600" :comData="arrRadar" :ref="'myChart'"></comChart>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="card-header ">
        <h3 class="card-title titleCont">
          <span class="spIcon"></span>
          综合评分
        </h3>
        <div class="card-tools">
          <ul class="nav">
            <li class="nav-item">
              <el-cascader v-model="casValue" clearable :show-all-levels="false" :options="options" ref="casSemester"
                :props="{ expandTrigger: 'hover' ,checkStrictly: true }" @change="handleChange"></el-cascader>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0" v-if="itemGrowthLog!=null && itemGrowthLog.length!=0">
        <el-table :data="itemGrowthLog" stripe class="elTableData">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="name" label="名称" class="elColumn"></el-table-column>
          <el-table-column prop="type" label="类型" class="elColumn"></el-table-column>
          <el-table-column prop="configName" label="纬度类别" class="elColumn"></el-table-column>
          <el-table-column prop="score" label="分值" class="elColumn"></el-table-column>
        </el-table>
        <comPage :paging="page" :pageBtnList="pageBtnList" @pagingClick="pagingClick" @pageBtnClick="pageBtnClick">
        </comPage>
      </div>
      <div class="card-body " v-else>
        <el-empty :image-size="200" description="暂无数据" name="3"></el-empty>
      </div>
    </el-card>

    <div class="row pageBox" style="margin-top: 16px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comPage from '@/components/Page.vue';
  import comChart from '@/components/Admin/Echarts/GrowthRadar.vue';
  export default {
    components: {
      comChart,
      comPage
    },
    data() {
      return {
        random: 0, //刷新组件
        studentId: null,
        strConfigId: '',
        strSemester: '',
        optSemester: [], //学生学期
        fullscreenLoading: false,
        itemData: {}, //获取基础学生数据
        itemGrowth: {}, //核心综合记录详情
        itemGrowthConfigs: [],
        data: {
          PageSize: 10, //每页的条数
          PageNumer: null, //页码
          StudentId: null, //学生id
          SemesterId: null, //学期
          ConfigId: null,
        },
        totalCount: null, //综合记录总数
        itemGrowthLog: [],
        options: [],
        casValue: null,
        imageUrl: null, //学生头像

        // 雷达图数据
        arrRadar: [],
        tabRadarIndex: 0,

        // 权限管理
        isPower: {
          //增删改查
          student_report: false,
        },
        isCurrent: null, //是否为当前学期

        //分页查询接口返回的结果
        page: null,
        //分页左侧按钮集合
        pageBtnList: null,
        //分页数据
        paging: {
          // 总共页数
          pageLength: 0,
          // 每页请求的数据量
          Size: 10,
          //当前在第几页
          Index: 1
        },
      };
    },
    methods: {
      onNullGeneralComment(val) {
        return regNull(val) ? '无' : val;
      },
      // 查看
      onGetReport() {
        let _this = this;
        _this.$router.push({
          path: "/Admin/LiteracyReport/Details/" + _this.studentId,
          query: {
            url: _this.$router.history.current.fullPath
          }
        });
      },
      pageBtnClick(index) {
        //分页组件左侧的按钮事件，按顺序进行判断
      },
      /**
       * @description 分页面按钮
       * @param {Object} type 1:每页显示条数 2:当前页码
       * @param {Object} val type的值
       */
      pagingClick(type, val) {
        let that = this;
        if (type == 1) { //更改每页条数触发的事件
          that.paging.Size = val;
          that.paging.Index = 1;
        } else { //更改当前页时触发的事件
          that.paging.Index = val;
        }
        onGetGeneralComment(that);
      },
      handleChange(configId) {
        let that = this;
        that.$refs.casSemester.dropDownVisible = false; //监听值发生变化就关闭它
        that.strConfigId = configId[configId.length - 1];
        onGetGeneralComment(that);
      },
      // 生成综合评价雷达图
      onChangeTabs(_dataID, _index) {
        let that = this;
        // Tabs切换动画
        that.tabRadarIndex = _index;
        that.$refs.myChart.onGetRadar(that.itemGrowthConfigs[_index].configData);
      },
      // 学期选择操作
      handSemesterChange(semesterId) {
        let that = this;
        that.fullscreenLoading = true;

        that.strSemester = semesterId;
        that.strConfigId = '';
        that.casValue = null;
        onGetData(that);

      },
      //返回
      onBack() {
        let that = this;
        back(that);
      },
      //头像
      onAvatorUrl(_data) {
        let that = this;
        let tempUrl = '';
        if (_data.photo == null || typeof(_data.photo) == "undefined" || _data.photo == '') {
          if (that.onGender(_data.gender)) {
            // 男
            tempUrl = require('@/assets/image/defaultAvatar_Male.jpg');
          } else {
            tempUrl = require('@/assets/image/defaultAvatar_Female.jpg');
            // 女
          }
        } else {
          tempUrl = that.$ServerUrl() + _data.photo;
        }
        return tempUrl;
      },
      //性别判断，男生:true  女生:false
      onGender(obj) {
        return obj == '男' ? true : false;
      },

      // 数据处理
      onGetOptions(_this, _data) {
        let arrTemp = [];
        if (_data.length != 0) {
          let temp = [];
          for (let item of _data) {
            if (item.children.length != 0) {
              temp.push({
                value: item.id,
                label: item.name,
                children: _this.onGetOptions(_this, item.children)
              });
            } else {
              temp.push({
                value: item.id,
                label: item.name,
              });
            }
          }
          arrTemp = temp;
        }
        return arrTemp;
      },
    },
    created() {

      let that = this;
      that.fullscreenLoading = true;
      let id = that.$route.params.id;
      that.studentId = id;
      that.data.StudentId = id;

      // 学生加载
      onGetStudent(that);
      // 学期加载
      onGetSemester(that);
      // 综合类型树型
      onGetTreelist(that);

      isPower(that, "student_report", "student_report");
    },
  };





  // 加载数据
  function onGetData(that) {

    // 条件
    let _data = {
      SemesterId: that.strSemester,
      StudentId: that.studentId,
      ConfigId: that.strConfigId,
    }

    that.itemGrowthConfigs = [];

    //
    ajaxGet(that, '/api/admin/growth/generalcomment', _data, function(resGrowth) {
      // 获取学生核心综合总评数据
      ajaxGet(that, '/api/admin/growth/chartdata', _data, function(resChartData) {
        that.itemGrowth = resGrowth;

        that.itemGrowthConfigs.unshift({
          name: '总评',
          configId: Math.floor(Math.random() * (100000 - 1 + 1)) + 10000,
          configData: resChartData
        });
        for (let item of resChartData) {
          that.fullscreenLoading = true;
          _data.ConfigId = item.configId;
          ajaxGet(that, '/api/admin/growth/chartdata', _data, function(resData) {
            that.fullscreenLoading = false;
            if (resData.length >= 3) {
              that.itemGrowthConfigs.push({
                name: item.name,
                configId: item.configId,
                configData: resData,
              });
            }

          });
        }
        that.tabRadarIndex = 0;
        // 首次加载数据
        if (that.arrRadar != null && that.arrRadar.length != 0) {
          that.$refs.myChart.onGetRadar(that.itemGrowthConfigs[0].configData);
        } else {
          that.arrRadar = that.itemGrowthConfigs[0].configData;
        }

        onGetGeneralComment(that);
      });
    });
  }

  function onGetGeneralComment(that) {
    // 条件
    let _data = {
      SemesterId: that.strSemester,
      StudentId: that.studentId,
      ConfigId: that.strConfigId,
      PageNumer: that.paging.Index,
      PageSize: that.paging.Size,
    }
    ajaxGet(that, '/api/admin/growth/search', _data, function(resGrowthLog) {
      that.fullscreenLoading = false;
      that.itemGrowthLog = resGrowthLog.items;
      that.page = resGrowthLog;
    });
  }


  // 综合类型树型
  function onGetTreelist(that) {
    ajaxGet(that, '/api/admin/growthconfig/treelist', null, function(resData) {
      that.options = that.onGetOptions(that, resData);
    });
  }

  //获取学生基础数据
  function onGetStudent(that) {
    ajaxGet(that, "/api/admin/student/" + that.studentId, null, function(res) {
      // 学生基础数据
      that.itemData = res;
      //头像图片
      that.imageUrl = that.onAvatorUrl(res);
    });
  }

  /**
   * @description 获取学期数据
   * @param {Object} that
   */
  function onGetSemester(that) {
    ajaxGet(that, "/api/admin/semester", null, function(resSemeter) { //根据学生获取学期
      // 学期
      that.optSemester = resSemeter;
      for (let item in resSemeter) {
        if (resSemeter[item].isCurrent == true) {
          that.strSemester = resSemeter[item].semesterId;
          that.data.SemesterId = resSemeter[item].semesterId;
        }
      }
      onGetData(that);
    });
  }
</script>

<style scoped="scoped">

  .divReportBox {
    position: absolute;
    top: 20px;
    right: 30px;
    bottom: 0;
    text-align: center;
    cursor: pointer;
  }

  .divReportBox .icon {
    font-size: 60px;
    margin-bottom: 10px;
    color: #409EFF;
  }

  .divReportBox .divIconName {
    background-color: #409EFF;
    color: #FFFFFF;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 3px;
  }


  .tabsContBox {
    display: flex;
  }

  .tabsContBox .spLabel {}

  .tabsContBox .spDivide {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    line-height: 1.3125rem;
    color: #999999;
  }

  .tabsContBox .abtnTabs {
    color: #9E9E9E;
    font-size: 0.875rem;
    height: 1.5625rem;
    line-height: 1.5625rem;
  }

  .tabsContBox .abtnTabs:hover {
    color: #323E56;
    cursor: pointer;
  }

  .tabsContBox .abtnTabAct {
    font-size: 1.1rem;
    color: #323E56;
    line-height: 1.3125rem;
  }



  .pageFooter {
    padding: 10px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.03);
    height: 50px;
  }

  .abtnViowFile:hover {
    color: #307BFF;
  }

  .card-primary.card-outline-tabs>.card-header a.active {
    color: #007bff;
  }

  .card.card-outline-tabs .card-header a {
    color: #B1B1B1;
  }

  .divSemesterSel {
    position: absolute;
    top: -20px;
    right: 30px;
  }

  .divSemesterSel .spTitle {
    padding-right: 15px;
    letter-spacing: 1px;
  }

  .imgAvatar {
    width: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .infoList {
    padding-bottom: 17px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spGender {
    font-size: 32px;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spLabel {
    height: 40px;
  }

  .infoList .spLabel label {
    position: absolute;
    top: 10px;
    background-color: #DD4B39;
    border: 1px solid #CF3421;
    border-radius: 5px;
    font-weight: normal;
    color: #FFFFFF;
    padding: 2px 10px;
    margin-bottom: 0;
  }

  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #B1B1B1;
  }





  .divTitleCont {
    position: absolute;
    left: 10%;
    top: 0;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    font-weight: bold;
  }

  .divValueCont {
    text-align: center;
    font-family: 'OswaldLight';
    font-size: 52px;
  }

  .divValueCont .spUnit {
    padding-left: 10px;
    margin-right: -10px;
    color: #B1B1B1;
    font-size: 14px;
  }



  .webHeigh {
    height: 72px;
  }

  .spBoxTitle {
    color: #808080;
    text-align: center;
    font-size: 14px;
  }

  .spValueFont {
    text-align: center;
    color: #000000;
    font-size: 32px;
    letter-spacing: 1px;
  }

  .spValueFont .spNum {
    font-family: 'OswaldLight';
    font-size: 32px;
  }


  .divSemesterSel {
    position: absolute;
    top: -20px;
    right: 30px;
  }

  .divSemesterSel .spTitle {
    padding-right: 15px;
    letter-spacing: 1px;
  }

  .imgAvatar {
    width: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

  .infoList {
    padding-bottom: 17px;
  }

  .infoList .spName {
    font-size: 32px;
    color: #212E48;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spGender {
    font-size: 32px;
    padding-right: 30px;
    height: 40px;
  }

  .infoList .spLabel {
    height: 40px;
  }

  .infoList .spLabel label {
    position: absolute;
    top: 10px;
    background-color: #DD4B39;
    border: 1px solid #CF3421;
    border-radius: 5px;
    font-weight: normal;
    color: #FFFFFF;
    padding: 2px 10px;
    margin-bottom: 0;
  }

  .infoList .spFonts {
    color: #3B475D;
    padding-right: 30px;
    padding-left: 30px;
    border-right: 1px solid #D6D6D6;
  }

  .infoList .spFonts:first-child {
    padding-left: 0;
  }

  .infoList .spFonts:last-child {
    border-right: none;
  }

  .infoList .spMedal {
    padding-right: 15px;
  }

  .infoList .spFonts .faxSize {
    font-size: 18px;
  }

  .infoList .spFonts .icon {
    color: #B1B1B1;
  }
</style>

<style type="text/css">
  .el-cascader-node>.el-radio {
    margin-top: 0.625rem !important;
    /*10*/
  }
</style>
