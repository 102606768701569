<!--
  班主任手册-行为规范-班级落实
-->
<template>
  <div class="SubWebPage" v-loading.fullscreen.lock="fullscreenLoading">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            落实
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="管理原因" prop="reasonIds">
                <el-cascader @change="onChangeReason" class="width_100Pie" v-model="ruleForm.reasonIds"
                  :options="reasons" :show-all-levels="false" filterable
                  :props="{ expandTrigger: 'hover',label:'name',value:'id',children:'children' }"></el-cascader>
              </el-form-item>
            </el-col>
            <!--按选择模式录入-->
            <el-col :span="8">
              <el-form-item label="分数" prop="scoreId">
                <el-select v-model="ruleForm.scoreId" placeholder="请选择分数" class="width_100Pie">
                  <el-option v-for="item in itemReason.score1List" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <!-- 附件 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            行为图片
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24" style="margin-top: 20px;">
            <el-col :span="24">
              <comUpload :key="random" @onPictureHandleSuccess="onPictureHandleSuccess" :files="uploadFiles">
              </comUpload>
            </el-col>
          </el-row>
        </div>
      </el-card>
      <!-- 学生名单 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont" style="float: left;">
            <span class="spIcon"></span>
            学生名单
          </h3>
          <div class="card-tools">
            <ul class="nav">
              <li class="nav-item">
                <el-button type="success" icon="el-icon-circle-plus-outline" size="mini" @click="onOpenDialogVisible()">
                  新增学生名单
                </el-button>
              </li>
              <li class="nav-item">
                <el-button type="danger" icon="el-icon-close" size="mini" @click="onEmptyList()">清空名单</el-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="20" v-if="ruleForm.members!=null && ruleForm.members.length>0">
            <el-col :lg="8" :md="8" :sm="12" v-for="(p,index) in ruleForm.members" :key="index">
              <div class="stuInfo">
                <div class="stuInfoBox">
                  <div class="stuImgCont VerticalMid">
                    <el-image :src="p.photo">
                      <div slot="error" class="image-slot">
                        <svg class="icon" aria-hidden="true">
                          <use xlink:href="#fax-use-circle-alt"></use>
                        </svg>
                      </div>
                    </el-image>
                  </div>
                  <div class="divInfoBox">
                    <div class="stuName">{{p.name}}</div>
                    <div class="stuOther">{{p.studentNo}}</div>
                    <div class="stuRole">{{p.className}}</div>
                  </div>
                </div>

                <div class="stuRemove color-danger">
                  <i class="el-icon-error" @click="onDeleteMember(p.studentId)"></i>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="24" v-else>
            <el-col :span="24" style="text-align: center;line-height: 40px;font-size: 14px;color: #909399;">
              请选择学生
            </el-col>
          </el-row>
        </div>
      </el-card>

      <!-- 备注 -->
      <el-card class="box-card">
        <div slot="header" class="card-header">
          <h3 class="card-title titleCont">
            <span class="spIcon"></span>
            备注
          </h3>
        </div>
        <div class="card-body tabListCont">
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="" prop="description">
                <el-input class="formControl" v-model="ruleForm.remark" type="textarea" :rows="10" show-word-limit
                  placeholder="请输入备注" maxlength="500">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-form>

    <div class="row" style="margin-top: 10px;">
      <div class="col-12">
        <el-button icon="el-icon-back" @click="onBack()">返回</el-button>
        <el-button icon="el-icon-refresh" @click="onReset()">重 置</el-button>
        <el-button type="primary" icon="el-icon-circle-check" @click="onSave()">提交</el-button>
      </div>
    </div>

    <!-- 学生名单 班级个人 -->
    <el-dialog title="添加学生名单" :visible.sync="dialogVisible" width="580px" :before-close="onCloseStudent">
      <el-card class="box-card" v-if="regNull(ruleForm.roomId)">
        <div class="card-body tabListCont">
          <div v-if="regNullArray(optStudent)">
            <el-empty :image-size="50" description="暂无学生，请先选择班级" name="2"></el-empty>
          </div>
          <div v-else style="display: inline-flex;flex-wrap: wrap;">
            <div v-for="(stu, index) in optStudent" :key="index" style="width: 100px;">
              <div class="studentBox">
                <el-checkbox-group v-model="currentStudentId">
                  <el-checkbox :label="stu.studentId" @change="handleCheckedClassChange($event, stu.studentId)">{{stu.name}}</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <div class="divDialogBox" v-else>
        <el-form class="ruleForm">
          <el-form-item label="学生">
            <el-select v-model="currentStudentId" class="width_100Pie" multiple filterable placeholder="请选择">
              <el-option v-for="item in optStudent" :key="item.studentId" :label="(item.studentName +' - '+ item.name+'床')" :value="item.studentId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onChangeStudent">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import '@/assets/css/Admin.css';
  import comUpload from '@/components/Upload.vue';

  export default {
    components: {
      comUpload
    },
    data() {
      return {
        // 页面标题
        currentTitle: "",
        // 加载动画
        dialogVisible: false,
        fullscreenLoading: false,
        //刷新组件
        random: 0,
        // 上传图片数组
        uploadFiles: [],
        // 表单对象
        ruleForm: {
          classLogId: null,
          reasonId: null,
          reasonIds: [],
          // 记录时间
          logDate: null,
          // 备注
          remark: null,
          // 分数
          score: 0,
          scoreId: null,
          // 附件
          attachment: null,
          // 人员
          members: [],
          // 班级ID
          classId: null,
          // 计量
          number: null,
        },
        ruleFormReset: null,
        // 扣分原因选择的数组
        allReasons: [],
        // 扣分原因数组
        reasons: null,
        // 学生数组
        optStudent:[],
        currentClassId: null,
        currentClassName: null,
        optAllStudent:[],
        // 选择学生的ID
        currentStudentId: [],
        // 验证
        rules: {
          reasonIds: [{
            required: true,
            message: '请选择管理原因',
            trigger: 'change'
          }],
          scoreId: [{
            required: true,
            message: '请选择分数',
            trigger: 'change'
          }],
          score: [{
            required: true,
            message: '请输入分数',
            trigger: 'change'
          }, {
            validator: (rule, value, callback) => {
              let that = this;
              var reg = /^(\-|\+)?\d+(\.\d{1,2})?$/;
              if (value != 0 && !reg.exec(value)) {
                callback(new Error("分数只能输入带1-2位小数的正数或负数！"));
              } else {
                callback();
              }
            },
            trigger: 'change'
          }],
          logDate: [{
            required: true,
            message: '请选择行为时间',
            trigger: 'change'
          }],
        },

        // 录入分类类型
        itemReason: {
          inputScoreMode: 0,
          reasonDeploys: [],
        },
        itemReasonReserveContinue: null,

        typeScoreMode: {
          labelName: '',
          valTotalScore: 0,
          tipFont: '',
        },
        typeScoreModeReset: null,
        optMembers: [],

        optRelatedData:[],// 关联
      };
    },
    created() {
      let that = this;
      that.ruleForm.logDate = new Date();
      that.typeScoreModeReset = JSON.parse(JSON.stringify(that.typeScoreMode));
      that.currentId = that.$route.params.id;

      if (!regNull(that.currentId)) {
        that.ruleForm.classLogId = that.$route.params.id;



        // 获取原因
        that.fullscreenLoading = true;
        ajaxGet(that, "/api/admin/moralclass/" + that.currentId, null, function(resLog) {

          if(!regNull(resLog)){
            that.ruleForm.roomId = resLog.roomId;
            that.ruleForm.attachment = resLog.attachment;
            that.ruleFormReset = JSON.parse(JSON.stringify(that.ruleForm));
            that.currentClassId = resLog.classId;
            that.currentClassName = resLog.class;
            that.uploadFiles = [];
            if (!regNull(resLog.attachment)) {
              var files = resLog.attachment.split('|');
              for (var i in files) {
                var it = i + 1;
                that.uploadFiles.push({
                  uid: it,
                  path: files[i],
                });
              }
              var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
              that.random += random;
            }
            if(!regNull(resLog.roomId)){
              ajaxGet(that, '/api/admin/building/checkinbeds?roomId=' + resLog.roomId, null, function (resRoom) {
                that.fullscreenLoading = false;
                that.optStudent = resRoom;
                that.optAllStudent = JSON.parse(JSON.stringify(that.optStudent));
              })
            }else{
              // 根据班级id获取学生信息
              ajaxGet(that, '/api/admin/student/class/' + resLog.classId, null, function(resClass) {
                //处理学生数据
                that.optStudent = resClass;
                that.fullscreenLoading = false;
              });
            }
            that.reasons = [];
                that.fullscreenLoading = true;
                ajaxGet(that, '/api/admin/moralperson/workablereason', null, function(resData) {
                  let reasonList = resData;
                  let temp = resData.find(item => item.reasonId == resLog.reasonId);
                  that.allReasons = resData;
                  if(!regNull(temp)){
                    that.ruleForm.reasonId = resLog.reasonId;
                    that.ruleForm.reasonIds = [temp.categoryId,resLog.reasonId];
                    that.itemReason = temp;
                    that.ruleForm.scoreId = that.itemReason.score1List[0];
                  }
                  for (var it of reasonList) {
                    if (it.category == null) {
                      it.category = '其他';
                    }
                  }
                  reasonList = Array.from(new Map(reasonList.map(item => [item.categoryId, item])).values());
                  for (var it2 of reasonList) {
                    that.reasons.push({
                      id: it2.categoryId,
                      name: it2.category,
                      status:false,
                      children: [],
                    })
                  }
                  for (var it3 of resData) {
                    for (var it4 of that.reasons) {
                      if (it3.categoryId == it4.id) {
                        it4.children.push({
                          id: it3.reasonId,
                          name: it3.name,
                          parentId:it3.categoryId,
                          score1List:it3.score1List,
                          score2List:it3.score2List,
                          status: false,
                          remark:null,
                        })
                      }
                    }
                  }
                  that.reasons.forEach(item => {
                    if (item.id == null) {
                      item.id = '00000000000'
                    }
                  })
                  that.fullscreenLoading = false;
                });
          }





        });
      }

    },
    methods: {
      /**
       * @description 班级个人名单复选框选中
       * @param {Object} e
       * @param {Object} studentId
       */
      handleCheckedClassChange(e, studentId) {
        let that = this;
        if (e) {
          let tempStudent = that.optStudent.find(fd => fd.studentId == studentId);
          if(!regNull(tempStudent)) {
            that.optMembers.push({
              studentId: tempStudent.studentId,
              classId: that.currentClassId,
              bedId:tempStudent.bedId,
              score: 0,
              photo: that.onImgSpliceUrlNoV(tempStudent.photo),
              name: tempStudent.name,
              studentNo: tempStudent.studentNo,
              className: that.currentClassName,
            });
          }
        } else {
          that.optMembers = that.optMembers.filter(x => x.studentId != studentId);
        }
      },
      /**
       * @description 清空名单
       * @return {void}
       */
      onEmptyList() {
        let that = this;
        that.ruleForm.members = [];
        that.currentStudentId = [];
        that.currentBedId = [];
      },
      /**
       * @description 打开名单(班级个人)
       * @return {void}
       */
      onOpenDialogVisible() {
        let that = this;
        that.dialogVisible = true;
        if (!regNullArray(that.ruleForm.members)) {
          that.optMembers = JSON.parse(JSON.stringify(that.ruleForm.members));
          that.ruleForm.members.forEach(element => {
            that.currentStudentId.push(element.studentId);
          })
        }

      },
      /**
       * @description 扣分原因选择事件
       * @return {void}
       */
      onChangeReason(_val) {
        let that = this;
        that.typeScoreMode = JSON.parse(JSON.stringify(that.typeScoreModeReset));
        that.ruleForm.scoreId = null;
        that.ruleForm.score = null;
        that.ruleForm.number = null;
        if (!regNullArray(_val)) {
          // 根据最后一ID获取数据
          let tempItem = that.allReasons.find(item => item.id == _val[_val.length - 1]);
          // 获取当前原因的对象数据
          if (!regNull(tempItem)) {
            that.itemReason = tempItem;
          }
        }
      },
      /**
       * @description 输入分数，将格式化
       * @return {void}
       */
      onScoreChange() {
        let that = this;
        var value = setTwoDecimal(setTwoFloat(that.ruleForm.score));
        that.ruleForm.score = value;
      },
      /**
       * @description 返回
       * @return {void}
       */
      onBack() {
        let that = this;
        back(that);
      },
      /**
       * @description 提交事件
       * @return {void}
       */
      onSave() {
        let that = this;
        that.$refs["ruleForm"].validate(valid => {
          if (valid) {
            that.fullscreenLoading = true;
            if (that.ruleForm.members == null || that.ruleForm.members.length <= 0) {
              confirmDelete(that, '请确认是否无法落实,确认后无法进行更改！', function(res) {
                if (res == true) {
                  ajaxPut(that,'/api/admin/moralclass/unavailable?id='+that.currentId,null,function(res){
                    that.fullscreenLoading = false;
                    back(that);
                  })
                }else{
                  that.fullscreenLoading = false;
                  return false;
                }
              })


            }else{
              let data = {
                fromLogId:that.currentId,
                items: [{
                    students:that.ruleForm.members.map(item=>({
                      studentId:item.studentId,
                      classId:regNull(item.classId)?null:item.classId,
                      bedId:regNull(item.bedId)?null:item.bedId,
                      score:that.ruleForm.scoreId,
                      remark:that.ruleForm.remark,
                      attachment:that.ruleForm.attachment,
                    })),
                    score:that.ruleForm.scoreId,
                    reasonId:that.ruleForm.reasonId,
                    remark:that.ruleForm.remark,
                    attachment:that.ruleForm.attachment,
                }]

              }
              ajaxPost(that, '/api/admin/moralperson', data, function(r) {
                that.fullscreenLoading = false;
                back(that);
              });
            }

          } else {
            setTimeout(() => {
              var isError = document.getElementsByClassName("is-error");
              isError[0].querySelector('input').focus();
            }, 1)
            return false;
          }
        });
      },
      /**
       * @description 重置事件
       * @return {void}
       */
      onReset() {
        let that = this;
        that.ruleForm = JSON.parse(JSON.stringify(that.ruleFormReset));
        that.typeScoreMode = JSON.parse(JSON.stringify(that.typeScoreModeReset));
        that.uploadFiles = [];
        var random = Math.floor(Math.random() * (100000 - 1 + 1)) + 10000;
        that.random += random;
        try {
          that.$refs["ruleForm"].resetFields();
        } catch {}
      },
      /**
       * @description 删除学生名单
       * @param {Object} objValID 学生ID
       * @return {void}
       */
      onDeleteMember(objValID) {
        let that = this;
        that.ruleForm.members = that.ruleForm.members.filter(item => item.studentId != objValID);
      },
      /**
       * @description 关闭弹窗
       */
      onCloseStudent() {
        let that = this;
        that.currentStudentId = [];
        that.dialogVisible = false;
      },
      /**
       * @description [班级个人]勾选学生，确认事件
       * @return {void}
       */
      onChangeStudent() {
        let that = this;
        if(!regNull(that.ruleForm.roomId)){
          if(!regNullArray(that.currentStudentId)){
            if(!regNullArray(that.optAllStudent)){
              // 判断是否已经有学
              if(regNullArray(that.ruleForm.members)){
                // 无, 直接添加
                that.currentStudentId.forEach(item=>{
                  let tempStudent=that.optAllStudent.find(fd => fd.studentId == item);
                  if(!regNull(tempStudent)){
                    that.ruleForm.members.push({
                      studentId: tempStudent.studentId,
                      classId: tempStudent.classId,
                      className: tempStudent.className,
                      roomId: that.currentRoomId,
                      bedId:tempStudent.bedId,
                      roomName: tempStudent.name,
                      score: 0,
                      photo: tempStudent.photo,
                      name: tempStudent.studentName,
                      studentNo: tempStudent.studentNo,
                    });
                  }
                });
              }else{
                // 有，进行判断，只添加没有的学生
                that.currentStudentId.forEach(item=>{
                  if(regNull(that.ruleForm.members.find(fd=> fd.studentId==item))){
                    let tempStudent=that.optAllStudent.find(fd=> fd.studentId==item);
                    if(!regNull(tempStudent)){
                      that.ruleForm.members.push({
                        studentId: tempStudent.studentId,
                        classId: tempStudent.classId,
                        className: tempStudent.className,
                        roomId: that.currentRoomId,
                        roomName: tempStudent.name,
                        score: 0,
                        photo: tempStudent.photo,
                        name: tempStudent.studentName,
                        studentNo: tempStudent.studentNo,
                      });
                    }
                  }
                });
              }
            }
          }
        }else{
          that.ruleForm.members = JSON.parse(JSON.stringify(that.optMembers));
        }
        that.optMembers = [];
        that.currentStudentId = [];
        that.dialogVisible = false;
      },
      /**
       * @description 数据递归
       * @param {Array} data 需要递归的原数据
       * @return {Array} 返回递归后的数据
       */
      getTreeData(data) {
        // 循环遍历json数据
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined;
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            this.getTreeData(data[i].children);
          }
        }
        return data;
      },
      /**
       * @description 上传图片成功后事件
       * @param {Object} param 上传成功后的地址
       * @return {void}
       */
      onPictureHandleSuccess(param) {
        let that = this;
        that.ruleForm.attachment = "";
        for (var i in param) {
          if (that.ruleForm.attachment == "") {
            that.ruleForm.attachment = param[i].path;
          } else {
            that.ruleForm.attachment += "|" + param[i].path;
          }
        }
      },

    }
  };
  /**
   * @description 数据递归
   * @param {Object} that this
   * @param {Object} val 需要递归数据
   */
  function InitItem(that, val) {
    if (val.length !== 0) {
      val.forEach((item) => {
        that.allReasons.push(item);
        if (item.children.length != null && typeof(item.children.length) != "undefined" && item.children.length >=
          1) {
          InitItem(that, item.children);
        }
      });

    }
  }
</script>


<style scoped="scoped">
  .studentBox {
    background: #e2e2e2;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }
  .nav-item {
    padding: 0 10px;
  }

  .divDialogBox {
    margin: 0 auto;
    width: 80%;
  }

  .scoreBox .spLabel {
    padding-left: 2px;
    padding-right: 2px;
    font-weight: bolder;
  }

  .scoreBox .itemList {
    padding-top: 5px;
    padding-bottom: 5px;
  }


  .stuInfo {
    position: relative;
    height: 100%;
    min-height: 80px;
    margin: 15px;
  }

  .stuInfo .stuInfoBox {
    display: flex;
  }

  .stuInfo .divInfoBox {
    padding-left: 10px;
  }


  .stuInfo .stuImgCont {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #DDDDDD;
  }



  .stuInfo .stuName {
    color: #212E48;
    font-size: 18px;
    font-weight: bold;
  }

  .stuInfo .stuRole {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .stuInfo .stuRoom {
    font-size: 12px;
    color: #767676;
  }

  .stuInfo .stuOther {
    color: #767676;
    font-size: 12px;
  }

  .stuInfo .stuRemove {
    display: none;
  }

  .stuInfo:hover .stuRemove {
    display: block;
    position: absolute;
    top: -0.9375rem;
    left: -0.625rem;
    font-size: 22px;
    cursor: pointer;
  }


  .transferBox {
    margin: 0 auto;
  }

  label {
    margin-bottom: 0;
  }

  .inputGroupCont {
    padding-top: 15px;
  }

  .inputGroupCont .inputList {
    padding-bottom: 10px;
  }

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle {}

  .tabListCont .divNavTitle .divTitleCont {
    padding: 10px 0px 10px 10px;
    color: #DEE2E6;
    cursor: pointer;
    font-size: 18px;
  }

  .tabListCont .divNavTitle .divTitleCont .spNum {
    background-color: #DEE2E6;
    font-family: 'OswaldLight';
    padding: 2px 5px;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 14px;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .divTitleAct {
    color: #3e3e3e;
  }

  .tabListCont .divNavTitle .divTitleAct .spNum {
    background-color: #17A2B8;
    color: #FFFFFF;
  }

  .tabListCont .divNavTitle .nav-link {
    font-size: 14px;
    color: #B1B1B1;
  }

  .tabListCont .divNavTitle .nav-link .spNum {
    font-family: 'OswaldLight';
    font-weight: normal;
    font-size: 14px;
    padding-left: 10px;
  }

  .tabListCont .divNavTitle .active {
    color: #007bff;
  }

  .divAlertCont {
    padding-left: 30px;
    padding-top: 10px;
    color: #9595A0;
  }
</style>

<style>
  .transferCont .el-transfer__buttons {
    width: 20%;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:first-child {
    float: left;
  }

  .transferCont .el-transfer__buttons .el-transfer__button:last-child {
    float: right;
  }

  .transferCont .el-transfer-panel {
    width: 40%;
  }

  .formGroupCont .el-date-editor--datetimerange.el-input__inner {
    width: 100%;
  }

  .stuInfo .stuImgCont .image-slot {
    font-size: 28px;
    color: #DDDDDD;
  }
</style>
